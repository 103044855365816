import React, { FC } from "react";

import Button from "react-bootstrap/Button";

import * as Icon from "react-bootstrap-icons";
import { ICellRendererParams } from "ag-grid-community";

import { MigrationRow } from "../../API/migrationService";

interface IMigrationCellRendererParams
  extends Omit<ICellRendererParams, "data"> {
  data: MigrationRow;
  snapshotClicked: any;
}
// const SnapshotClickHandler = () => {};

const ActionRenderer: FC<IMigrationCellRendererParams> = ({
  value,
  colDef,
  data,
  snapshotClicked,
}) => (
  <>
    <Button
      onClick={() => snapshotClicked(data.id, data.companyName)}
      size="sm"
      disabled={data.generateSnapshotOnStable}
      variant={data.generateSnapshotOnStable ? "success" : "warning"}
    >
      <Icon.CameraFill height="18" width="18" />
    </Button>
  </>
);

export default ActionRenderer;
