import { gql } from "@apollo/client";
import { DateTime } from "luxon";

import { SnapshotTemplate } from "./remappingService";

export type MigrationQuery = {
  migrations_findMigrationById: Migration;
  migrations_listMigrations: Migration[];
  migrations_ingressTopLevelEntityCounts: Migration[];
};

export type MigrationMutation = {
  migrations_setSnapshotGeneration: Migration;
  migrations_deleteMigrationById: Migration; 
};

export type Migration = {
  id: string;
  installId: string;
  companyName: string;
  started: string;
  lastUpdated: string;
  assignedUser: User;
  software: MigrationSoftware;
  status: MigrationStatus;
  entityStats: MigrationEntityStats;
  template: SnapshotTemplate;
  generateSnapshotOnStable: boolean;
};

export type MigrationRow = {
  id: string;
  companyName: string;
  assignedUserName: string;
  started: DateTime;
  lastUpdated: DateTime;
  softwareName: string;
  ingressStatus: ingressStatus;
  mediaStatus: mediaStatus;
  pushStatus: pushStatus;
  snapshotStatus: snapshotStatus;
  completedPercentage: number;
  ingressCompletedPercentage: number;
  mediaCompletedPercentage: number;
  pushCompletedPercentage: number;
  snapshotCompletedPercentage: number;
  generateSnapshotOnStable: boolean;
};

export type User = {
  id: string;
  name: string;
};

export type MigrationEntityStats = {
  ingress: MigrationStageCount;
  media: MigrationStageCount;
  push: MigrationStageCount;
  snapshot: MigrationStageCount;
};

export type MigrationStageCount = {
  entityCounts: EntityCount[];
  topLevelCompleted: number;
  topLevelCompletedPercentage: number;
  topLevelFailed: number;
  topLevelFailedPercentage: number;
  topLevelPending: number;
  topLevelPendingPercentage: number;
  topLevelTotal: number;
  totalCompleted: number;
  totalFailed: number;
};

export type MigrationSoftware = {
  name?: string;
  version?: string;
};

export type ingressStatus = "PAUSED" | "IN_PROGRESS" | "COMPLETED" | "DELETED" | "FAILED";
export type mediaStatus = "PAUSED" | "IN_PROGRESS" | "COMPLETED" | "DELETED" | "FAILED";
export type pushStatus = "PAUSED" | "IN_PROGRESS" | "COMPLETE" | "FAILED";
export type snapshotStatus = "NOT_RUNNING" | "IN_PROGRESS" | "COMPLETED" | "DELETED" | "FAILED";

export type MigrationStatus = {
  ingress: ingressStatus;
  media: mediaStatus;
  push: pushStatus;
  snapshot: snapshotStatus;
};

export type EntityCount = {
  completed: number;
  completedPercentage: number;
  entityName: string;
  failed: number;
  failedPercentage: number;
  isTopLevelEntity: boolean;
  pending: number;
  pendingPercentage: number;
  total: number;
};

export type ErrorExtensions = {
  code: string;
  codes: string[];
  data: ErrorException;
};

export type ErrorException = {
  exception: ErrorExceptionContent;
};

export type ErrorExceptionContent = {
  type: string;
  message: string;
  trace: string;
};

export const GET_LIST_MIGRATIONS = gql`
  query {
    migrations_listMigrations {
      id
      installId
      started
      lastUpdated
      companyName
      assignedUser {
        name
      }
      software {
        name
        version
      }
      status {
        ingress
        media
        push
        snapshot
      }
      entityStats {
        ingress {
          totalCompleted
          totalFailed
          topLevelTotal
          topLevelCompleted
          topLevelCompletedPercentage
          topLevelFailed
          topLevelFailedPercentage
          topLevelPending
          topLevelPendingPercentage
        }
        media {
          totalCompleted
          totalFailed
          topLevelTotal
          topLevelCompleted
          topLevelCompletedPercentage
          topLevelFailed
          topLevelFailedPercentage
          topLevelPending
          topLevelPendingPercentage
        }
        push {
          totalCompleted
          totalFailed
          topLevelTotal
          topLevelCompleted
          topLevelCompletedPercentage
          topLevelFailed
          topLevelFailedPercentage
          topLevelPending
          topLevelPendingPercentage
        }
        snapshot {
          totalCompleted
          totalFailed
          topLevelTotal
          topLevelCompleted
          topLevelCompletedPercentage
          topLevelFailed
          topLevelFailedPercentage
          topLevelPending
          topLevelPendingPercentage
        }
      }
      generateSnapshotOnStable
    }
  }
`;

export const GET_MIGRATION_ENTITYSTATS = gql`
  query migrations_findMigrationById($migrationId: Guid) {
    migrations_findMigrationById(migrationId: $migrationId) {
      entityStats {
        ingress {
          entityCounts {
            completed
            completedPercentage
            entityName
            failed
            isTopLevelEntity
            pending
            pendingPercentage
            total
          }
        }
      }
    }
  }
`;

export const GET_MIGRATION_TRANSFORMATION = gql`
  query migrations_findMigrationById($migrationId: Guid) {
    migrations_findMigrationById(migrationId: $migrationId) {
      id
      template {
        transformation {
          id
          name
          description
          tables {
            id
            tableName
            transformationId
            mappings {
              id
              snapshotMappedTableId
              mapping {
                ... on DictionaryMapping {
                  defaultValue
                  rawUpdateField
                  values {
                    key
                    value
                  }
                }
                ... on ExpressionMapping {
                  expression
                  rawUpdateField
                  childMappings {
                    ... on SqlExpressionMapping {
                      expressionKeys
                      query
                      queryKeyField
                      rawKeyField
                    }
                    ... on StringExpressionMapping {
                      expressionKey
                      value
                    }
                  }
                }
                ... on LookupTableMapping {
                  defaultValue
                  lookupDbTable
                  lookupKeyField
                  lookupValueField
                  rawUpdateField
                }
                ... on SqlMapping {
                  query
                  queryKeyField
                  rawKeyField
                  updateFields {
                    queryField
                    rawUpdateField
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_MIGRATION_PROGRESS = gql`
  query migrations_findMigrationById($migrationId: Guid) {
    migrations_findMigrationById(migrationId: $migrationId) {
      id
      started
      lastUpdated
      companyName
      assignedUser {
        name
      }
      software {
        name
        version
      }
      status {
        ingress
        media
        push
        snapshot
      }
      entityStats {
        ingress {
          totalCompleted
          totalFailed
          topLevelTotal
          topLevelCompleted
          topLevelCompletedPercentage
          topLevelFailed
          topLevelFailedPercentage
          topLevelPending
          topLevelPendingPercentage
        }
        media {
          totalCompleted
          totalFailed
          topLevelTotal
          topLevelCompleted
          topLevelCompletedPercentage
          topLevelFailed
          topLevelFailedPercentage
          topLevelPending
          topLevelPendingPercentage
        }
        push {
          topLevelCompletedPercentage
        }
        snapshot {
          topLevelCompletedPercentage
        }
      }
      generateSnapshotOnStable
    }
  }
`;

export const GET_AGENT_BY_MIGRATION = gql`
  query migrations_findMigrationById($migrationId: Guid) {
    migrations_findMigrationById(migrationId: $migrationId) {
      id
      companyName
      software {
        name
        version
      }
    }
  }
`;

export const SET_SNAPSHOT_GENERATION = gql`
  mutation migrations_setSnapshotGeneration(
    $migrationId: Guid
    $generateSnapshotOnStable: Boolean
  ) {
    migrations_setSnapshotGeneration(
      migrationId: $migrationId
      generateSnapshotOnStable: $generateSnapshotOnStable
    ) {
      id
      generateSnapshotOnStable
    }
  }
`;

export const DELETE_MIGRATION_BY_ID = gql`
  mutation migrations_deleteMigrationById(
    $migrationId: Guid
  ) {
    migrations_deleteMigrationById(
      migrationId: $migrationId
    ) {
      id
      companyName
    }
  }
`;
