import React from "react";

import { useQuery } from "@apollo/client";

import {
  FIND_TRANSFORMATION_BY_ID,
  RemappingQuery,
} from "../../../API/remappingService";

type TransformationByIdProps = {
  transformationId: number;
};

const TransformationById: React.FC<TransformationByIdProps> = ({
  transformationId,
}) => {
  const { data, error, loading } = useQuery<RemappingQuery>(
    FIND_TRANSFORMATION_BY_ID,
    {
      variables: { transformationId },
      pollInterval: 0,
      errorPolicy: "all",
    }
  );
  if (error) {
    console.log(error.message);
  }

  return loading ? (
    <p>loading</p>
  ) : (
    <div>
      <h3>transformationById</h3>
      <div>
        <pre>
          <b>id: </b>
          {data?.remapping_findTransformationById?.id}
        </pre>
        <p>
          <b>name: </b>
          {data?.remapping_findTransformationById?.name}
        </p>
        <p>
          <b>description: </b>
          {data?.remapping_findTransformationById?.description}
        </p>
        <h4>tables</h4>
        {data?.remapping_findTransformationById?.tables.map((t) => (
          <div key={t.id}>
            <p>
              <b>id: </b>
              {t.id}
            </p>
            <p>
              <b>tableName: </b>
              {t.tableName}
            </p>
            <p>
              <b>transformationId: </b>
              {t.transformationId}
            </p>
            <h5>mappings</h5>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TransformationById;
