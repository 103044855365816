import React, { useState } from "react";

import { useQuery } from "@apollo/client";

import { apiClient } from "../index";

import {
  GET_MIGRATION_ENTITYSTATS,
  MigrationQuery,
} from "../API/migrationService";

import {
  DatabasesQuery,
  RawDatabaseTable,
  GET_RAW_DATABASE_SCHEMA,
} from "../API/databaseService";

import CollapsibleCard from "../containers/CollapsibleCard";

import ProgressBar from "react-bootstrap/ProgressBar";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Accordion from "react-bootstrap/Accordion";
import Spinner from "react-bootstrap/Spinner";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";

import RawEntityPreview from "../components/RawEntityPreview";

import {
  SortDown,
  CardList,
  LayoutThreeColumns,
  Upload,
  XCircle,
} from "react-bootstrap-icons";

// import { Link } from "react-router-dom";

type EntityProgressProps = {
  migrationId: string;
};

const EntityProgress: React.FunctionComponent<EntityProgressProps> = ({
  migrationId,
}) => {
  // eslint-disable-next-line
  const [isTopLevelOnly, setTopLevelOnly] = useState<boolean>(true);
  const [selectedAccordionKey, setSelectedAccordionKey] = useState<string>("");

  const [sortOrder, setSortOrder] = useState<number>(0);

  const [currentDBTable, setCurrentDBTable] = useState<
    RawDatabaseTable | undefined
  >(undefined);

  const timeElapsed = Date.now();
  const today = new Date(timeElapsed);

  const { data, error, loading, startPolling, stopPolling } =
    useQuery<MigrationQuery>(GET_MIGRATION_ENTITYSTATS, {
      variables: { migrationId },
      pollInterval: 1000,
      errorPolicy: "all",
    });

  if (error) {
    console.log(today.toISOString() + ":" + error.message);
  }
  const currentMigration = data?.migrations_findMigrationById;
  let sortedEntities =
    currentMigration?.entityStats.ingress.entityCounts.slice();

  switch (sortOrder) {
    case 0:
      sortedEntities?.sort(
        (first, second) => 0 - (first.entityName > second.entityName ? -1 : 1)
      );
      break;
    case 1:
      sortedEntities?.sort(
        (first, second) => 0 - (first.entityName > second.entityName ? 1 : -1)
      );
      break;
    case 2:
      sortedEntities?.sort(
        (first, second) =>
          first.completedPercentage - second.completedPercentage ||
          first.total - second.total
      );
      break;
    case 3:
      sortedEntities?.sort(
        (first, second) =>
          second.completedPercentage - first.completedPercentage ||
          second.total - first.total
      );
      break;
    case 4:
      sortedEntities?.sort(
        (first, second) => 0 - (first.total > second.total ? -1 : 1)
      );
      break;
    case 5:
      sortedEntities?.sort(
        (first, second) => 0 - (first.total > second.total ? 1 : -1)
      );
      break;
    case 6:
      sortedEntities?.sort(
        (first, second) => 0 - (first.completed > second.completed ? -1 : 1)
      );
      break;
    case 7:
      sortedEntities?.sort(
        (first, second) => 0 - (first.completed > second.completed ? 1 : -1)
      );
      break;
  }

  const handlePollChange = (newValue: boolean) => {
    if (newValue) {
      startPolling(1000);
    } else {
      stopPolling();
    }
    console.log(newValue);
  };

  const getDBSchema = (migrationId: string, entityName: string) => {
    setCurrentDBTable(undefined);

    apiClient
      .query<DatabasesQuery>({
        query: GET_RAW_DATABASE_SCHEMA,
        variables: {
          migrationId: migrationId,
        },
        errorPolicy: "all",
      })
      .then((response) => {
        if (response.errors) {
          console.error(response.errors[0].message);
        } else {
          var dbSchema = response.data?.databases_getRawDatabaseSchema;

          var dbTable = dbSchema?.tables.filter(
            (t) => t.entityName === entityName
          )[0];

          setCurrentDBTable(dbTable);
        }
      })
      .catch((err) => console.error(err));
  };

  return (
    <CollapsibleCard
      headerText="Entity Progress"
      onPollChange={handlePollChange}
      showPoll={false}
      options={
        //   <ToggleButton
        //     className="me-3"
        //     type="checkbox"
        //     variant="secondary"
        //     size="sm"
        //     checked={isTopLevelOnly}
        //     value="1"
        //     onChange={(e) => setTopLevelOnly(e.currentTarget.checked)}
        //   >
        //     {isTopLevelOnly ? " Top level only" : " Hide sub entities"}
        //   </ToggleButton>
        <>
          <Form>
            <Container fluid>
              <Row>
                <Col className="align-self-center" sm="2">
                  <SortDown width={16} height={16} />
                </Col>
                <Col sm="10">
                  <Form.Select
                    size="sm"
                    onChange={(e) =>
                      setSortOrder(parseInt(e.currentTarget.value))
                    }
                  >
                    <option value="0">entityName A-Z</option>
                    <option value="1">entityName Z-A</option>
                    <option value="2">Completed % asc</option>
                    <option value="3">Completed % desc</option>
                    <option value="4">Total asc</option>
                    <option value="5">Total desc</option>
                    <option value="6">Completed asc</option>
                    <option value="7">Completed desc</option>
                  </Form.Select>
                </Col>
              </Row>
            </Container>
          </Form>
        </>
      }
    >
      {loading === true ? (
        <Spinner animation="border" role="status" variant="secondary">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      ) : (
        <>
          <Accordion
            // defaultActiveKey="0"
            onSelect={(e) => {
              if (typeof e === "string") {
                setSelectedAccordionKey(e);
                getDBSchema(migrationId, e);
              }
            }}
          >
            {sortedEntities?.map((item, index) => {
              return (
                <div key={item.entityName}>
                  {item.isTopLevelEntity ||
                  item.isTopLevelEntity === isTopLevelOnly ? (
                    <Accordion.Item eventKey={item.entityName} key={index}>
                      <Accordion.Header>
                        <Container fluid className="p-0 pe-3">
                          <Row>
                            <Col
                              className="text-truncate"
                              sm="9"
                              md="9"
                              lg={{ span: 10, order: "first" }}
                              xl={{ span: 5, order: "first" }}
                            >
                              <h5>
                                {/* <Link
                                    to={{
                                      pathname:
                                        "/refine/" +
                                        migrationId +
                                        "/" +
                                        item.entityName,
                                    }}
                                  > */}
                                <CardList size="32" className="me-3" />
                                {item.entityName}
                                {/* </Link> */}
                              </h5>
                            </Col>
                            <Col
                              className="text-truncate text-end ps-0 align-self-center"
                              sm="3"
                              md="3"
                              lg={{ span: 2, order: 2 }}
                              xl={{ span: 1, order: "last" }}
                            >
                              {item.isTopLevelEntity
                                ? item.completed + "/" + item.total
                                : item.completed}
                            </Col>
                            <Col
                              className="align-self-center"
                              lg={{ span: 12, order: "last" }}
                              xl={{ span: 6, order: 2 }}
                            >
                              {item.isTopLevelEntity ? (
                                <ProgressBar
                                  style={{ height: 30 }}
                                  animated={item.completedPercentage < 100}
                                  variant="success"
                                  now={item.completedPercentage}
                                  label={`${item.completedPercentage}%`}
                                />
                              ) : (
                                <ProgressBar
                                  style={{ height: 30 }}
                                  animated={false}
                                  variant="warning"
                                  now={100}
                                  label={item.completed}
                                />
                              )}
                            </Col>
                          </Row>
                        </Container>
                      </Accordion.Header>
                      <Accordion.Body>
                        <Container fluid className="p-0 mt-2">
                          <Row>
                            <Col sm>
                              <h6>
                                <LayoutThreeColumns
                                  size="24"
                                  className="me-2"
                                />
                                <b>Columns: </b>
                                {currentDBTable?.fields.length.toString()}
                              </h6>
                            </Col>
                            <Col sm>
                              <h6>
                                <Upload size="24" className="me-2" />
                                <b>Uploaded: </b>
                                {item.isTopLevelEntity
                                  ? item.completed + "/" + item.total
                                  : item.completed}
                              </h6>
                            </Col>
                            <Col sm>
                              <h6>
                                <XCircle size="24" className="me-2" />
                                <b>Failed: </b>
                                {item.failed}
                              </h6>
                            </Col>
                            <Col sm>
                              {/* <h6>
                                  <b>Date Received: </b>
                                  <i>(Placeholder)</i>
                                </h6> */}
                            </Col>
                            <Col sm>
                              {/* <h6>
                                  <b>Date Started: </b>
                                  <i>(Placeholder)</i>
                                </h6> */}
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <RawEntityPreview
                                migrationId={migrationId}
                                rawDBTable={currentDBTable}
                                isOpen={
                                  item.entityName === selectedAccordionKey
                                }
                              />
                            </Col>
                          </Row>
                        </Container>
                      </Accordion.Body>
                    </Accordion.Item>
                  ) : null}
                </div>
              );
            })}
          </Accordion>
        </>
      )}
    </CollapsibleCard>
  );
};

export default EntityProgress;
