import React, { useState } from "react";

import { ResultSet } from "../API/databaseService";

import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";

import { ArrowsAngleContract, ArrowsAngleExpand } from "react-bootstrap-icons";

export type SelectQueryProps = {
  queryResults: ResultSet | undefined;
};

const SelectQuery: React.FunctionComponent<SelectQueryProps> = ({
  queryResults,
}) => {
  const [tableExpanded, setTableExpanded] = useState<boolean>(false);

  if (queryResults) {
    return (
      <>
        <Button
          variant="light"
          className="m-1 me-0 float-end"
          size="sm"
          onClick={() => setTableExpanded(!tableExpanded)}
        >
          {tableExpanded === true ? (
            <ArrowsAngleContract />
          ) : (
            <ArrowsAngleExpand />
          )}
        </Button>
        <div
          className="mt-4 border border-secondary"
          style={{
            overflowY: "scroll",
            clear: "both",
            height: tableExpanded ? "75vh" : "300px",
          }}
        >
          {queryResults?.records.length > 0 ? (
            <Table striped bordered hover responsive="md" size="sm">
              <thead>
                <tr>
                  <th>#</th>
                  {queryResults?.records[0]?.fields.map((f, index) => (
                    <th key={index}>{f.name}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {queryResults?.records.map((r, index) => (
                  <tr key={index}>
                    <td>
                      <b>{index + 1}</b>
                    </td>
                    {r.fields.map((f, findex) => (
                      <td key={findex}>{f.value}</td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <p>No Results</p>
          )}
        </div>
      </>
    );
  } else {
    return null;
  }
};

export default SelectQuery;
