import React from "react";
//  { useContext, createContext, useState, ReactNode }

import {
  BrowserRouter as Router,
  Switch,
  Route,
  // Redirect,
  // useHistory,
  // useLocation,
} from "react-router-dom";

import "./App.css";

import Navigation from "../containers/Navigation";
import Dashboard from "../containers/Dashboard";
import MigrationDetail from "../containers/MigrationDetail";
import RefineMigration from "../containers/RefineMigration";
import NotFoundPage from "./NotFoundPage";

import SnapshotPlayground from "../components/Playground/SnapshotPlayground";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";

library.add(fab, fas);

export type AppProps = {
  onProfileLogout?: any;
};

const App: React.FC<AppProps> = ({ onProfileLogout }) => {
  return (
    <Router>
      <div className="App">
        <Navigation onProfileLogout={onProfileLogout} />
        <Switch>
          <Route exact path="/">
            <Dashboard />
          </Route>
          <Route
            path="/refine/:migrationId/:entityName"
            component={RefineMigration}
          />
          <Route path="/refine/:migrationId" component={RefineMigration} />
          <Route path="/refine">
            <RefineMigration />
          </Route>
          <Route path="/playground">
            <SnapshotPlayground />
          </Route>
          <Route path="/login"></Route>
          <Route
            path="/migration/:migrationId/:entityTab/:entityName"
            component={MigrationDetail}
          />
          <Route
            path="/migration/:migrationId/:entityTab"
            component={MigrationDetail}
          />
          <Route path="/migration/:migrationId" component={MigrationDetail} />
          <Route>
            <NotFoundPage />
          </Route>
        </Switch>
      </div>
    </Router>
  );
};

export default App;
