import React from "react";

import {
  MappingUnion,
  DictionaryMapping,
  ExpressionMapping,
  LookupTableMapping,
  SnapshotMapping,
  SqlMapping,
} from "../API/remappingService";

import CollapsibleCard from "../containers/CollapsibleCard";

import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Tab from "react-bootstrap/Tab";

import SqlMappingPane from "../components/SQLMappingPane";

export type EntityTransformsProps = {
  migrationId: string;
  entityName: string;
  mappingList: SnapshotMapping[] | undefined;
  tabKey: string | number | undefined;
  onTabChanged?: any;
  onMappingChange?: any;
};

const EntityTransforms: React.FunctionComponent<EntityTransformsProps> = ({
  migrationId,
  entityName,
  mappingList,
  tabKey,
  onTabChanged,
  onMappingChange,
}) => {
  const MappingUnionName = (mu: MappingUnion) => {
    var retVal: string = "";
    if (isDictionaryMapping(mu)) {
      retVal = "DictionaryMapping";
    } else if (isExpressionMapping(mu)) {
      retVal = "ExpressionMapping";
    } else if (isLookupTableMapping(mu)) {
      retVal = "LookupTableMapping";
    } else if (isSqlMapping(mu)) {
      retVal = "SqlMapping";
    }
    return retVal;
  };

  function isDictionaryMapping(
    union: MappingUnion
  ): union is DictionaryMapping {
    return (union as any).values !== undefined;
  }

  function isExpressionMapping(
    union: MappingUnion
  ): union is ExpressionMapping {
    return (union as any).expression !== undefined;
  }

  function isLookupTableMapping(
    union: MappingUnion
  ): union is LookupTableMapping {
    return (union as any).lookupDbTable !== undefined;
  }

  function isSqlMapping(union: MappingUnion): union is SqlMapping {
    return (union as any).query !== undefined;
  }

  return (
    <CollapsibleCard headerText={entityName.toUpperCase() + " mappings"}>
      <Container fluid>
        <Row>
          <Col>
            {mappingList ? (
              <Tab.Container
                id="mappingTabs"
                activeKey={tabKey}
                onSelect={(k) => onTabChanged(k)}
              >
                <Row>
                  <Col sm={3} lg={2}>
                    <Nav variant="pills" className="flex-column">
                      {mappingList.map((m, index) => (
                        <Nav.Item key={index}>
                          <Nav.Link eventKey={m.id}>
                            {m.id > 0 ? "[" + (index + 1) + "] " : "[New] "}
                            {MappingUnionName(m.mapping)}
                          </Nav.Link>
                        </Nav.Item>
                      ))}
                    </Nav>
                  </Col>
                  <Col sm={9} lg={10}>
                    <Tab.Content>
                      {mappingList.map((m) => (
                        <Tab.Pane eventKey={m.id} key={m.id}>
                          {isDictionaryMapping(m.mapping) ? (
                            <p>Dictionary goes here</p>
                          ) : isExpressionMapping(m.mapping) ? (
                            <p>Expression goes here</p>
                          ) : isLookupTableMapping(m.mapping) ? (
                            <p>Lookup Table goes here</p>
                          ) : isSqlMapping(m.mapping) ? (
                            <SqlMappingPane
                              onMappingChange={onMappingChange}
                              migrationId={migrationId}
                              entityName={entityName}
                              mappingId={m.id}
                              mappedTableId={m.snapshotMappedTableId}
                              sqlMapping={m.mapping}
                            />
                          ) : null}
                        </Tab.Pane>
                      ))}
                    </Tab.Content>
                  </Col>
                </Row>
              </Tab.Container>
            ) : null}
          </Col>
        </Row>
      </Container>
    </CollapsibleCard>
  );
};

export default EntityTransforms;
