import React, { useState, useEffect } from "react";

import { useHistory } from "react-router-dom";

import { apiClient } from "../index";

import Alert from "react-bootstrap/Alert";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import Spinner from "react-bootstrap/Spinner";
import SplitButton from "react-bootstrap/SplitButton";
import Dropdown from "react-bootstrap/Dropdown";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Button from "react-bootstrap/Button";

import CollapsibleCard from "../containers/CollapsibleCard";

import Editor from "@monaco-editor/react";

import QueryGrid from "./Datagrids/QueryGrid";

import {
  // Calendar3,
  FileEarmarkSpreadsheet,
  ArrowsAngleContract,
  ArrowsAngleExpand,
  ArrowReturnLeft,
} from "react-bootstrap-icons";

import {
  LIST_ENTITY_QUERIES_FOR_MIGRATION_LITE,
  SnapshotEntityQuery,
  EntityQueriesQuery,
} from "../API/entityQueryService";

import {
  GET_EXECUTE_SELECT_QUERY_FROM_SNAPSHOT,
  ResultSet,
  DatabasesQuery,
  ErrorExtensions,
} from "../API/databaseService";

export type EntitySnapshotViewerProps = {
  migrationId: string;
  entityName: string;
};

const EntitySnapshotViewer: React.FunctionComponent<EntitySnapshotViewerProps> =
  ({ migrationId, entityName }) => {
    let history = useHistory();

    const [loading, setLoading] = useState<boolean>(true);
    const [resultSet, setResultSet] = useState<ResultSet | undefined>(
      undefined
    );

    const [snapshotEntity, setSnapshotEntity] = useState<
      SnapshotEntityQuery | undefined
    >(undefined);

    const [entityQueryList, setEntityQueryList] = useState<
      SnapshotEntityQuery[] | undefined
    >(undefined);

    const [editorExpanded, setEditorExpanded] = useState<boolean>(false);
    const [editorWordWrap, setEditorWordWrap] = useState<boolean>(false);

    const [combinedQueryString, setCombinedQueryString] = useState<string>("");

    const [queryErrorMessage, setQueryErrorMessage] = useState<string>("");
    const [queryErrorException, setQueryErrorException] = useState<string>("");
    const [queryError, setQueryError] = useState<any>(null);

    const [pageIndex, setPageIndex] = useState<number>(0);
    var rowLimit: number = 1000;

    useEffect(() => {
      const listEntityQueriesEntityName = (
        migrationId: string,
        entityName: string
      ) => {
        apiClient
          .query<EntityQueriesQuery>({
            query: LIST_ENTITY_QUERIES_FOR_MIGRATION_LITE,
            variables: {
              migrationId: migrationId,
            },
            errorPolicy: "all",
          })
          .then((response) => {
            if (response.errors) {
              const errorMessage = response.errors[0].message;
              console.error(errorMessage);

              if (response.errors[0].extensions) {
                console.error(response.errors[0].extensions);
              }

              setLoading(false);
            } else {
              let filtered =
                response.data.entityQueries_listQueriesForMigration.filter(
                  (x) => x.name === entityName
                );

              if (filtered && filtered.length > 0) {
                let matchingId: number = filtered[0].id;
                console.log("EntityId", matchingId);

                setSnapshotEntity(filtered[0]);

                if (filtered[0].baseQuery.length > 0) {
                  let tempSql: string =
                    "SELECT ROW_NUMBER () OVER () as _rownumber, COUNT(*) OVER() as _rowcount, * from " +
                    filtered[0].name;

                  setCombinedQueryString(tempSql);
                  executeSelectQueryFromSnapshot(
                    migrationId,
                    1, // has to be non zero - will be replaced with known id once snapshot status works
                    tempSql,
                    pageIndex * rowLimit,
                    rowLimit
                  );
                } else {
                  setCombinedQueryString("");
                  setResultSet(undefined);
                }
              }

              setEntityQueryList(
                response.data.entityQueries_listQueriesForMigration
              );

              setLoading(false);
            }
          })
          .catch((err: any) => {
            console.error(err);
            setLoading(false);
          });
      };

      listEntityQueriesEntityName(migrationId, entityName);
    }, [migrationId, entityName, pageIndex, rowLimit]);

    const executeSelectQueryFromSnapshot = (
      migrationId: string,
      snapshotExecutionId: number,
      query: string,
      offset: number,
      limit: number
    ) => {
      if (query !== "") {
        setQueryErrorMessage("");
        setQueryErrorException("");
        setQueryError(null);

        apiClient
          .query<DatabasesQuery>({
            query: GET_EXECUTE_SELECT_QUERY_FROM_SNAPSHOT,
            variables: {
              migrationId: migrationId,
              snapshotExecutionId: snapshotExecutionId,
              query: query,
              offset: offset,
              limit: limit,
            },
            fetchPolicy: "network-only",
            errorPolicy: "all",
          })
          .then((response) => {
            if (response.errors) {
              const errorMessage = response.errors[0].message;
              setQueryErrorMessage(errorMessage);
              if (response.errors[0].extensions) {
                const ext: ErrorExtensions = response.errors[0]
                  .extensions as ErrorExtensions;
                setQueryErrorException(ext.data.exception.message);
              }
              setResultSet(undefined);
            } else {
              setResultSet(
                response.data.databases_executeSelectQueryFromSnapshot
              );
            }
          })
          .catch((err) => {
            console.error(err);
            setQueryErrorMessage("Error executing select query from snapshot");
            setQueryErrorException("");
            setQueryError(err);
            setResultSet(undefined);
            console.error("executeSelectQuery", err);
          });
      } else {
        setQueryErrorMessage("");
        setQueryErrorException("");
        setQueryError(undefined);
        setResultSet(undefined);
      }
    };

    const handlePageChanged = (value: number) => {
      setPageIndex(value);
    };

    return (
      <>
        <CollapsibleCard showPoll={false} showHeader={false}>
          {loading ? (
            <Spinner animation="border" role="status" variant="secondary">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          ) : (
            <Container fluid>
              {/* <Row className="my-2">
                <Col xl="2">
                  <Calendar3 width="20" height="20" className="me-2" />
                  Snapshot taken 03/06/2021 14:22
                </Col>
                <Col xl="2"></Col>
                <Col xl="2"></Col>
                <Col xl="2"></Col>
                <Col xl="2"></Col>
                <Col xl="2"></Col>
              </Row> */}
              <Row>
                <Col xl="10">
                  <Form>
                    <Form.Select
                      size="lg"
                      value={entityName}
                      onChange={(e) => {
                        setPageIndex(0);
                        history.push(
                          "/migration/" +
                            migrationId +
                            "/snapshot/" +
                            e.currentTarget.value
                        );
                      }}
                    >
                      {entityQueryList
                        ?.slice()
                        .sort(
                          (first, second) =>
                            0 - (first.name > second.name ? -1 : 1)
                        )
                        .map((el) => (
                          <option key={el.id} value={el.name}>
                            {el.displayName}
                          </option>
                        ))}
                    </Form.Select>
                  </Form>
                </Col>
                <Col xl="2">
                  <SplitButton
                    disabled={true}
                    align="end"
                    variant="success"
                    title={
                      <>
                        <FileEarmarkSpreadsheet
                          width="16"
                          height="16"
                          className="me-2"
                        />
                        Download CSV
                      </>
                    }
                  >
                    <Dropdown.Item eventKey="1">Download All</Dropdown.Item>
                  </SplitButton>
                </Col>
              </Row>
            </Container>
          )}
        </CollapsibleCard>

        <CollapsibleCard
          headerText={
            loading
              ? "loading..."
              : "Snapshot Preview Query for " + snapshotEntity?.displayName
          }
        >
          {loading ? (
            <Spinner animation="border" role="status" variant="secondary">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          ) : (
            <>
              <Container fluid>
                <Row>
                  <Col>
                    <OverlayTrigger
                      placement="left"
                      overlay={
                        <Tooltip id="tooltip-undo">
                          {editorExpanded === true
                            ? "Contract Editor"
                            : "Expand Editor"}
                        </Tooltip>
                      }
                    >
                      <Button
                        disabled={loading}
                        variant="light"
                        className="ms-1 float-end"
                        size="sm"
                        onClick={() => setEditorExpanded(!editorExpanded)}
                      >
                        {editorExpanded === true ? (
                          <ArrowsAngleContract />
                        ) : (
                          <ArrowsAngleExpand />
                        )}
                      </Button>
                    </OverlayTrigger>
                    <OverlayTrigger
                      placement="left"
                      overlay={
                        <Tooltip id="tooltip-wordwrap">
                          {editorWordWrap === true
                            ? "Disable WordWrap"
                            : "Enable WordWrap"}
                        </Tooltip>
                      }
                    >
                      <Button
                        disabled={loading}
                        variant={editorWordWrap === true ? "info" : "light"}
                        className="ms-1 float-end"
                        size="sm"
                        onClick={() => setEditorWordWrap(!editorWordWrap)}
                      >
                        <ArrowReturnLeft />
                      </Button>
                    </OverlayTrigger>
                    {combinedQueryString.length > 0 ? (
                      <Editor
                        className="border border-secondary py-3"
                        height={editorExpanded ? "75vh" : "80px"}
                        language="sql"
                        defaultValue=""
                        value={combinedQueryString}
                        options={{
                          readOnly: true,
                          wordWrap: editorWordWrap === true ? "on" : "off",
                        }}
                      ></Editor>
                    ) : (
                      <pre>There is no base query</pre>
                    )}
                  </Col>
                </Row>
              </Container>
            </>
          )}
        </CollapsibleCard>

        <CollapsibleCard headerText={loading ? "loading..." : "Results"}>
          <Container fluid>
            <Row>
              <Col>
                {queryErrorMessage && queryErrorMessage.length > 0 ? (
                  <Alert variant="danger" className="my-4">
                    <Alert.Heading>{queryErrorMessage}</Alert.Heading>
                    <p>{queryErrorException}</p>

                    {queryError ? (
                      <pre>
                        <code>{JSON.stringify(queryError, null, 2)}</code>
                      </pre>
                    ) : null}
                  </Alert>
                ) : (
                  <QueryGrid
                    queryResults={resultSet}
                    pageIndex={pageIndex}
                    rowLimit={rowLimit}
                    onPageChanged={handlePageChanged}
                    defaultColumnWidth={200}
                  />
                )}
              </Col>
            </Row>
          </Container>
        </CollapsibleCard>
      </>
    );
  };
export default EntitySnapshotViewer;
