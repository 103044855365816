import React, { useState, useEffect } from "react";

import { apiClient } from "../index";

import ConfirmDialog from "../components/Dialogs/ConfirmDialog";

import Alert from "react-bootstrap/Alert";

import {
  GET_VALUE_OCCURRENCES,
  ValueOccurrence,
  DatabasesQuery,
  ErrorExtensions,
} from "../API/databaseService";

import {
  FIND_FIELD_QUERY,
  SET_ENTITY_QUERY_MAPPED_VALUES,
  EntityQueriesQuery,
  EntityQueriesMutation,
  SnapshotFieldQuery,
  SetSnapshotFieldQueryAllowedValuesInput,
  FieldQueryMappedValueInput,
} from "../API/entityQueryService";

import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ListGroup from "react-bootstrap/ListGroup";
import Collapse from "react-bootstrap/Collapse";
import Spinner from "react-bootstrap/Spinner";

import {
  ArrowCounterclockwise,
  Save2,
  ChevronCompactDown,
  ChevronCompactUp,
} from "react-bootstrap-icons";

export type FieldQueryProps = {
  migrationId: string;
  fieldQueryId: number;
};

interface IMappedValue extends FieldQueryMappedValueInput {
  originalValue: number;
  dirty: boolean;
  occurrences: number;
}

const FieldQuery: React.FunctionComponent<FieldQueryProps> = ({
  migrationId,
  fieldQueryId,
}) => {
  const [loadingFieldQuery, setLoadingFieldQuery] = useState<boolean>(true);
  const [loadingOccurences, setLoadingOccurences] = useState<boolean>(true);

  const [showZeros, setShowZeros] = useState<boolean>(false);

  const [valuesExpanded, setValuesExpanded] = useState<boolean>(false);

  const [countDirty, setCountDirty] = useState<number>(0);

  const [fieldQuery, setFieldQuery] = useState<SnapshotFieldQuery | undefined>(
    undefined
  );
  const [mappedValues, setMappedValues] = useState<IMappedValue[] | undefined>(
    undefined
  );

  const [valueOccurrences, setValueOccurrences] = useState<
    ValueOccurrence[] | undefined
  >(undefined);

  const [queryErrorMessage, setQueryErrorMessage] = useState<string>("");
  const [queryErrorException, setQueryErrorException] = useState<string>("");

  const [occurrenceErrorMessage, setOccurrenceErrorMessage] =
    useState<string>("");
  const [occurrenceErrorException, setOccurrenceErrorException] =
    useState<string>("");
  const [occurrenceError, setOccurrenceError] = useState<any>(null);

  const [saveDialogShow, setSaveDialogShow] = useState<boolean>(false);
  const [resetDialogShow, setResetDialogShow] = useState<boolean>(false);

  const handleSaveDialogHide = () => {
    setSaveDialogShow(false);
  };

  const handleSaveDialogOK = () => {
    setSaveDialogShow(false);
    SaveMappedValues();
  };

  const HandleSaveClick = () => {
    setSaveDialogShow(true);
  };

  const HandleResetClick = () => {
    setResetDialogShow(true);
  };

  const handleResetDialogHide = () => {
    setResetDialogShow(false);
  };

  const handleResetDialogOK = () => {
    setMappedValues([]);
    getFieldQuery(fieldQueryId);
    setMappedValues(PopulateMappedValues(fieldQuery, valueOccurrences));
    setResetDialogShow(false);
  };

  const getFieldQuery = (fieldQueryId: number) => {
    setLoadingFieldQuery(true);

    apiClient
      .query<EntityQueriesQuery>({
        query: FIND_FIELD_QUERY,
        variables: {
          fieldQueryId: fieldQueryId,
        },
        errorPolicy: "all",
        fetchPolicy: "network-only",
      })
      .then((response) => {
        if (response.errors) {
          const errorMessage = response.errors[0].message;
          console.error(errorMessage);
          setQueryErrorMessage(errorMessage);
          if (response.errors[0].extensions) {
            const ext: ErrorExtensions = response.errors[0]
              .extensions as ErrorExtensions;
            if (ext.data) {
              setQueryErrorException(ext.data.exception.message);
            }
          }

          setLoadingFieldQuery(false);
        } else {
          // console.log(
          //   "Loading Field Query",
          //   response.data.entityQueries_findFieldQuery
          // );

          setFieldQuery(response.data.entityQueries_findFieldQuery);
          setCountDirty(0);
          setLoadingFieldQuery(false);
        }
      })
      .catch((err) => {
        setQueryErrorMessage("Error loading field query");
        setQueryErrorException(JSON.stringify(err));
        console.error("getFieldQuery", err);
        setLoadingFieldQuery(false);
      });
  };

  const listValueOccurrences = (migrationId: string, fieldQueryId: number) => {
    setLoadingOccurences(true);

    apiClient
      .query<DatabasesQuery>({
        query: GET_VALUE_OCCURRENCES,
        variables: {
          migrationId: migrationId,
          fieldQueryId: fieldQueryId,
        },
        errorPolicy: "all",
        fetchPolicy: "network-only",
      })
      .then((response) => {
        if (response.errors) {
          const errorMessage = response.errors[0].message;
          console.error(errorMessage);
          setOccurrenceErrorMessage(errorMessage);
          if (response.errors[0].extensions) {
            const ext: ErrorExtensions = response.errors[0]
              .extensions as ErrorExtensions;
            if (ext.data) {
              console.error(ext.data.exception);
              setOccurrenceErrorException(
                "[" +
                  ext.data.exception.type +
                  "] " +
                  ext.data.exception.message
              );
            }
          }
          setShowZeros(true);
          setLoadingOccurences(false);
        } else {
          setValueOccurrences(response.data.databases_getValueOccurrences);
          setLoadingOccurences(false);
        }
      })
      .catch((err: any) => {
        setShowZeros(true);
        setOccurrenceErrorMessage("Error loading occurences");
        //setOccurrenceErrorException(JSON.stringify(err));
        setOccurrenceError(err);
        console.error("listValueOccurrences", err);
        setLoadingOccurences(false);
      });
  };

  const mutationSetEntityQueryMappedValues = (
    request: SetSnapshotFieldQueryAllowedValuesInput
  ) => {
    // console.log(JSON.stringify(request));

    apiClient
      .mutate<EntityQueriesMutation>({
        mutation: SET_ENTITY_QUERY_MAPPED_VALUES,
        variables: {
          request: request,
        },
        errorPolicy: "all",
      })
      .then((response) => {
        if (response.errors) {
          const errorMessage = response.errors[0].message;
          console.error(errorMessage);
        } else {
          // mutation response is broken - only gives back the allowed values used
          // refetch query instead
          // console.log(response.data?.entityQueries_setFieldMappedValues);
          //setFieldQuery(response.data?.entityQueries_setFieldMappedValues);

          getFieldQuery(request.fieldQueryId);

          setCountDirty(0);
        }
      })
      .catch((err) => console.error(err));
  };

  const PopulateMappedValues = (
    fieldQuery: SnapshotFieldQuery | undefined,
    valueOccurrences: ValueOccurrence[] | undefined
  ): IMappedValue[] => {
    var mappedValuesInput: IMappedValue[] = [];

    valueOccurrences?.forEach((v) => {
      const foundVal = fieldQuery?.mappedValues.find(
        (m) =>
          m.sourceValue.toString() ===
          (v.value !== null ? v.value.toString() : "[null]")
      );

      var y: IMappedValue = {
        originalValue: foundVal ? foundVal.fieldDefinitionAllowedValueId : 0,
        fieldDefinitionAllowedValueId: foundVal
          ? foundVal.fieldDefinitionAllowedValueId
          : 0,
        sourceValue: v.value !== null ? v.value.toString() : "[null]",
        dirty: false,
        occurrences: v.count ? v.count : 0,
      };

      //if (y.occurrences > 0) {
      mappedValuesInput.push(y);
      //}
    });

    fieldQuery?.mappedValues.forEach((m) => {
      if (!mappedValuesInput.find((i) => i.sourceValue === m.sourceValue)) {
        var y: IMappedValue = {
          originalValue: m.fieldDefinitionAllowedValueId,
          fieldDefinitionAllowedValueId: m.fieldDefinitionAllowedValueId,
          sourceValue:
            m.sourceValue !== null ? m.sourceValue.toString() : "[null]",
          dirty: false,
          occurrences: 0,
        };

        mappedValuesInput.push(y);
      }
    });

    return mappedValuesInput;
  };

  const SaveMappedValues = () => {
    var mappedValuesInput: IMappedValue[] | undefined = mappedValues;

    if (mappedValuesInput === undefined) {
      mappedValuesInput = PopulateMappedValues(fieldQuery, valueOccurrences);
    }

    setMappedValues(mappedValuesInput);

    var fieldQueryMappedValues: FieldQueryMappedValueInput[] = [];

    mappedValuesInput.forEach((x) => {
      var y: FieldQueryMappedValueInput = {
        fieldDefinitionAllowedValueId: x.fieldDefinitionAllowedValueId,
        sourceValue: x.sourceValue,
      };
      fieldQueryMappedValues.push(y);
    });

    var input: SetSnapshotFieldQueryAllowedValuesInput = {
      fieldQueryId: fieldQueryId,
      mappedValues: fieldQueryMappedValues,
    };

    mutationSetEntityQueryMappedValues(input);
  };

  const updateMappedValue = (sourceValue: string, value: number) => {
    console.log(sourceValue, value);

    var mappedValuesInput: IMappedValue[] | undefined = mappedValues?.slice();

    // if (mappedValuesInput === undefined) {
    //   mappedValuesInput = PopulateMappedValues(fieldQuery, valueOccurrences);
    // }

    if (mappedValuesInput) {
      var objIndex = mappedValuesInput.findIndex(
        (obj) => obj.sourceValue === sourceValue
      );

      mappedValuesInput[objIndex].fieldDefinitionAllowedValueId = value;
      mappedValuesInput[objIndex].dirty =
        mappedValuesInput[objIndex].originalValue !== value;

      setCountDirty(mappedValuesInput.filter((d) => d.dirty === true).length);

      console.log(mappedValuesInput);

      setMappedValues(mappedValuesInput);
    }
  };

  useEffect(() => {
    listValueOccurrences(migrationId, fieldQueryId);
    getFieldQuery(fieldQueryId);
    setMappedValues(PopulateMappedValues(fieldQuery, valueOccurrences));
  }, [migrationId, fieldQueryId, fieldQuery, valueOccurrences]);

  return (
    <>
      <Card>
        <Card.Header>
          <Container fluid>
            <Row>
              <Col xl="6">
                <h5>{fieldQuery?.name}</h5>
              </Col>
              <Col xl="2" className="mt-1 mt-xl-0 p-0 pe-1">
                {/* <Button
                      style={{ width: "100%" }}
                      onClick={HandleRevertClick}
                    >
                      <>
                        <ArrowCounterclockwise
                          width={20}
                          height={20}
                          className="me-1"
                        />
                        Revert
                      </>
                    </Button> */}
                <Form>
                  <Form.Check
                    className="mt-2 me-2 float-end"
                    type="checkbox"
                    label="Show Zeros"
                    checked={showZeros}
                    onChange={() => setShowZeros(!showZeros)}
                  />
                </Form>
              </Col>
              <Col xl="2" className="mt-1 mt-xl-0 p-0 pe-1">
                <Button
                  disabled={
                    countDirty === 0 ||
                    loadingFieldQuery === true ||
                    loadingOccurences === true
                  }
                  style={{ width: "100%" }}
                  onClick={HandleResetClick}
                >
                  <>
                    <ArrowCounterclockwise
                      width={20}
                      height={20}
                      className="me-1"
                    />
                    Reset
                  </>
                </Button>
              </Col>
              <Col xl="2" className="mt-1 mt-xl-0 p-0 pe-1">
                <Button
                  disabled={
                    loadingFieldQuery === true || loadingOccurences === true
                  }
                  style={{ width: "100%" }}
                  variant={countDirty === 0 ? "success" : "warning"}
                  onClick={HandleSaveClick}
                >
                  <>
                    <Save2 width={20} height={20} className="me-1" /> Save
                  </>
                </Button>
              </Col>
            </Row>
          </Container>
        </Card.Header>
        <Card.Body>
          {loadingFieldQuery === true || loadingOccurences === true ? (
            <Spinner animation="border" role="status" variant="secondary">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          ) : (
            <>
              <Table striped bordered hover size="sm">
                <thead>
                  <tr>
                    <th style={{ width: "40%" }}>Source value</th>
                    <th>Occurrences</th>
                    <th style={{ width: "40%" }}>Target Value</th>
                  </tr>
                </thead>
                <tbody>
                  {mappedValues
                    ?.sort(
                      (first, second) =>
                        second.occurrences - first.occurrences ||
                        (first.sourceValue > second.sourceValue ? 1 : -1)
                    )
                    .map((m) =>
                      m.occurrences > 0 || showZeros ? (
                        <tr key={m.sourceValue}>
                          <td>
                            {m.sourceValue.length > 0 ? (
                              m.sourceValue
                            ) : (
                              <i>[null]</i>
                            )}
                          </td>
                          <td>{m.occurrences}</td>
                          <td>
                            <Form>
                              <Form.Select
                                value={m.fieldDefinitionAllowedValueId}
                                onChange={(e) =>
                                  updateMappedValue(
                                    m.sourceValue,
                                    parseInt(e.currentTarget.value)
                                  )
                                }
                                className={m.dirty === true ? "bg-warning" : ""}
                              >
                                <option value={0}>[null]</option>
                                {fieldQuery?.allowedValues.map((a) => (
                                  <option key={a.id} value={a.id}>
                                    {a.value}
                                  </option>
                                ))}
                              </Form.Select>
                            </Form>
                          </td>
                        </tr>
                      ) : null
                    )}
                </tbody>
              </Table>
              {queryErrorMessage && queryErrorMessage.length > 0 ? (
                <Alert variant="danger" className="my-4">
                  <Alert.Heading>{queryErrorMessage}</Alert.Heading>
                  <p>{queryErrorException}</p>
                </Alert>
              ) : null}
              {occurrenceErrorMessage && occurrenceErrorMessage.length > 0 ? (
                <Alert variant="danger" className="my-4">
                  <Alert.Heading>{occurrenceErrorMessage}</Alert.Heading>
                  <p>{occurrenceErrorException}</p>
                  {occurrenceError ? (
                    <pre>
                      <code>{JSON.stringify(occurrenceError, null, 2)}</code>
                    </pre>
                  ) : null}
                </Alert>
              ) : null}
            </>
          )}
        </Card.Body>
        <Card.Footer>
          <pre className="m-1">{`${countDirty} change${
            countDirty === 1 ? "" : "s"
          }`}</pre>
        </Card.Footer>
      </Card>
      <div className="d-grid gap-2">
        <Button
          className="mt-3"
          variant="light"
          size="sm"
          onClick={() => setValuesExpanded(!valuesExpanded)}
        >
          {valuesExpanded ? <ChevronCompactUp /> : <ChevronCompactDown />}
        </Button>
      </div>
      <Collapse in={valuesExpanded}>
        <Container className="mt-3">
          <Row>
            <Col className="px-0 me-1">
              <Card className="m-0">
                <Card.Header>{`${fieldQuery?.mappedValues.length} Mapped Values`}</Card.Header>
                <Card.Body>
                  <ListGroup>
                    {fieldQuery?.mappedValues
                      .slice()
                      .sort(
                        (first, second) =>
                          0 - (first.sourceValue > second.sourceValue ? -1 : 1)
                      )
                      .map((m) => (
                        <ListGroup.Item key={m.id}>
                          {m.sourceValue.length > 0 ? (
                            m.sourceValue
                          ) : (
                            <i>[null]</i>
                          )}
                        </ListGroup.Item>
                      ))}
                  </ListGroup>
                </Card.Body>
              </Card>
            </Col>
            <Col className="px-0 ms-1">
              <Card className="m-0">
                <Card.Header>{`${fieldQuery?.allowedValues.length} Allowed Values`}</Card.Header>
                <Card.Body>
                  <ListGroup>
                    {fieldQuery?.allowedValues.slice().map((a) => (
                      <ListGroup.Item key={a.id}>{a.value}</ListGroup.Item>
                    ))}
                  </ListGroup>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col>
              <pre>{fieldQuery?.id}</pre>
            </Col>
          </Row>
        </Container>
      </Collapse>

      <ConfirmDialog
        title={`Save mapped values?`}
        message={"Save all values?"}
        show={saveDialogShow}
        onHideHandler={handleSaveDialogHide}
        onOKHandler={handleSaveDialogOK}
        icon={<Save2 width={30} height={30} className="ms-2" />}
      />
      <ConfirmDialog
        title={`Reset changes made`}
        message={"Undo all changes?"}
        show={resetDialogShow}
        onHideHandler={handleResetDialogHide}
        onOKHandler={handleResetDialogOK}
        icon={<ArrowCounterclockwise width={30} height={30} className="ms-2" />}
      />
    </>
  );
};

export default FieldQuery;
