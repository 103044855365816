import React from "react";

import { useQuery } from "@apollo/client";

import {
  FIND_MAPPING_SCRIPT_BY_ID,
  RemappingQuery,
} from "../../../API/remappingService";

type MappingScriptByIdProps = {
  mappingScriptId: number;
};

const MappingScriptById: React.FC<MappingScriptByIdProps> = ({
  mappingScriptId,
}) => {
  const { data, error, loading } = useQuery<RemappingQuery>(
    FIND_MAPPING_SCRIPT_BY_ID,
    {
      variables: { mappingScriptId },
      pollInterval: 1000,
      errorPolicy: "all",
    }
  );
  if (error) {
    console.log(error.message);
  }

  return loading ? (
    <p>loading</p>
  ) : (
    <div>
      <h4>mappingScriptById</h4>
      <div>
        <pre>{data?.remapping_findMappingScriptById.id}</pre>
        <p>{data?.remapping_findMappingScriptById.name}</p>
        <p>{data?.remapping_findMappingScriptById.description}</p>
        <p>{data?.remapping_findMappingScriptById.content}</p>
      </div>
    </div>
  );
};

export default MappingScriptById;
