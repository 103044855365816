import React, { useState, useEffect } from "react";

import { apiClient } from "../index";

import Alert from "react-bootstrap/Alert";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import CollapsibleCard from "../containers/CollapsibleCard";

import QueryGrid from "./Datagrids/QueryGrid";

import {
  GET_EXECUTE_SELECT_QUERY_FROM_STRUCTURED_VIEW,
  ResultSet,
  DatabasesQuery,
  ErrorExtensions,
} from "../API/databaseService";

export type EntityDataViewerProps = {
  migrationId: string;
  sqlQuery: string;
  pageIndex: number;
  onPageChanged?: any;
};

const EntityDataViewer: React.FunctionComponent<EntityDataViewerProps> = ({
  migrationId,
  sqlQuery,
  pageIndex,
  onPageChanged,
}) => {
  const [loading, setLoading] = useState<boolean>(true);

  const [resultSet, setResultSet] = useState<ResultSet | undefined>({
    records: [],
  });

  const [queryErrorMessage, setQueryErrorMessage] = useState<string>("");
  const [queryErrorException, setQueryErrorException] = useState<string>("");
  const [queryError, setQueryError] = useState<any>(null);

  var rowLimit: number = 1000;

  useEffect(() => {
    executeSelectQueryFromStucturedView(
      migrationId,
      sqlQuery,
      pageIndex < 0 ? 0 : pageIndex * rowLimit,
      rowLimit
    );
  }, [migrationId, sqlQuery, pageIndex, rowLimit]);

  const executeSelectQueryFromStucturedView = (
    migrationId: string,
    query: string,
    offset: number,
    limit: number
  ) => {
    if (query !== "") {
      setLoading(true);
      setQueryErrorMessage("");
      setQueryErrorException("");
      setQueryError(null);

      apiClient

        .query<DatabasesQuery>({
          query: GET_EXECUTE_SELECT_QUERY_FROM_STRUCTURED_VIEW,
          variables: {
            migrationId: migrationId,
            query: query,
            offset: offset,
            limit: limit,
          },
          fetchPolicy: "network-only",
          errorPolicy: "all",
        })

        .then((response) => {
          if (response.errors) {
            const errorMessage = response.errors[0].message;
            setQueryErrorMessage(errorMessage);

            if (response.errors[0].extensions) {
              const ext: ErrorExtensions = response.errors[0]
                .extensions as ErrorExtensions;
              setQueryErrorException(ext.data.exception.message);
            }

            setResultSet(undefined);
            setLoading(false);
          } else {
            setResultSet(
              response.data.databases_executeSelectQueryFromStucturedView
            );

            setLoading(false);
          }
        })

        .catch((err) => {
          console.error(err);
          setQueryErrorMessage("Error executing query on structured view");
          setQueryErrorException("");
          setQueryError(err);
          setResultSet(undefined);
          setLoading(false);
          console.error("executeSelectQuery", err);
        });
    } else {
      setLoading(false);
      setQueryErrorMessage("");
      setQueryErrorException("");
      setQueryError(undefined);
      setResultSet({ records: [] });
    }
  };

  return (
    <>
      <CollapsibleCard headerText="Results">
        <Container fluid>
          <Row>
            <Col>
              {queryErrorMessage && queryErrorMessage.length > 0 ? (
                <Alert variant="danger" className="my-4">
                  <Alert.Heading>{queryErrorMessage}</Alert.Heading>
                  <p>{queryErrorException}</p>

                  {queryError ? (
                    <pre>
                      <code>{JSON.stringify(queryError, null, 2)}</code>
                    </pre>
                  ) : null}
                </Alert>
              ) : (
                <QueryGrid
                  queryResults={resultSet}
                  pageIndex={pageIndex < 0 ? 0 : pageIndex}
                  rowLimit={rowLimit}
                  onPageChanged={onPageChanged}
                  loadingData={loading}
                  defaultColumnWidth={200}
                />
              )}
            </Col>
          </Row>
        </Container>
      </CollapsibleCard>
    </>
  );
};
export default EntityDataViewer;
