import { gql } from "@apollo/client";

export type EntityQueriesQuery = {
  entityQueries_findEntityQuery: SnapshotEntityQuery;
  entityQueries_findFieldQuery: SnapshotFieldQuery;
  entityQueries_listQueriesForMigration: SnapshotEntityQuery[];
};

export type PreviewEntityQuery = {
  entityQueries_previewEntityQuery: string;
}

export type EntityQueriesMutation = {
  entityQueries_setFieldMappedValues: SnapshotFieldQuery;
  entityQueries_updateEntityQuery: SnapshotEntityQuery;
};

export type SnapshotEntityQuery = {
  id: number;
  name: string;
  displayName: string;
  description: string;
  baseQuery: string;
  entityDefinitionId: number;
  fields: SnapshotFieldQuery[];
};

export type SnapshotFieldQuery = {
  id: number;
  selectStatement: string;
  fieldDefinitionId: number;
  mappedValues: SnapshotFieldQueryMappedValue[];
  name: string;
  displayName: string;
  description: string;
  dataType: string;
  prefixIds: number[];
  allowedValues: SnapshotFieldDefinitionAllowedValue[];
};

export interface ISnapshotFieldQuery extends SnapshotFieldQuery {
  originalSelectStatement: string;
  rowDirty: boolean;
  editorExpanded: boolean;
}

export type SnapshotFieldQueryMappedValue = {
  id: number;
  fieldDefinitionAllowedValueId: number;
  fieldQueryId: number;
  sourceValue: string;
  fieldQuery: SnapshotFieldQuery;
  allowedValue: SnapshotFieldDefinitionAllowedValue;
};

export type SetSnapshotFieldQueryAllowedValuesInput = {
  fieldQueryId: number;
  mappedValues: FieldQueryMappedValueInput[];
};

export type FieldQueryMappedValueInput = {
  fieldDefinitionAllowedValueId: number;
  sourceValue: string;
};

export type SnapshotFieldDefinitionAllowedValue = {
  id: number;
  fieldDefinitionId: number;
  value: string;
};

export type UpdateSnapshotEntityQueryInput = {
  baseQuery: string;
  entityQueryId: number;
  updatedFields?: UpdatedFieldQueryInput[];
};

export type UpdatedFieldQueryInput = {
  fieldQueryId: number;
  selectStatement: string;
};

export const FIND_ENTITY_QUERY = gql`
  query entityQueries_findEntityQuery($entityQueryId: Long) {
    entityQueries_findEntityQuery(entityQueryId: $entityQueryId) {
      baseQuery
      entityDefinitionId
      id
      fields {
        id
        selectStatement
        fieldDefinitionId
        mappedValues {
          id
          fieldDefinitionAllowedValueId
          fieldQueryId
          sourceValue
          fieldQuery {
            id
          }
          allowedValue {
            id
          }
        }
        name
        displayName
        description
        dataType
        prefixIds
        allowedValues {
          id
          value
        }
      }
      name
      displayName
      description
    }
  }
`;


export const FIND_FIELD_QUERY = gql`
  query entityQueries_findFieldQuery($fieldQueryId: Long) {
    entityQueries_findFieldQuery(fieldQueryId: $fieldQueryId) {
      id
      selectStatement
      fieldDefinitionId
      name
      displayName
      description
      dataType
      prefixIds
      allowedValues {
        id
        value
      }
      mappedValues {
        id
        fieldDefinitionAllowedValueId
        sourceValue
      }
    }
  }
`;

export const LIST_ENTITY_QUERIES_FOR_MIGRATION = gql`
  query entityQueries_listQueriesForMigration($migrationId: Guid) {
    entityQueries_listQueriesForMigration(migrationId: $migrationId) {
      id
      name
      displayName
      description
      baseQuery
      entityDefinitionId
      fields {
        id
        selectStatement
        fieldDefinitionId
        name
        displayName
        dataType
        description
        prefixIds
        allowedValues {
          id
          fieldDefinitionId
          value
        }
        mappedValues {
          id
          fieldDefinitionAllowedValueId
          fieldQueryId
          sourceValue
        }
      }
    }
  }
`;

export const LIST_ENTITY_QUERIES_FOR_MIGRATION_LITE = gql`
  query entityQueries_listQueriesForMigration($migrationId: Guid) {
    entityQueries_listQueriesForMigration(migrationId: $migrationId) {
      id
      name
      displayName
      description
      baseQuery
      # entityDefinitionId
      fields {
        id
        # selectStatement
        # fieldDefinitionId
        # name
        # displayName
        # dataType
        # description
        # prefixIds
        # allowedValues {
        #   id
        #   fieldDefinitionId
        #   value
        # }
        # mappedValues {
        #   id
        #   fieldDefinitionAllowedValueId
        #   fieldQueryId
        #   sourceValue
        # }
      }
    }
  }
`;

export const SET_ENTITY_QUERY_MAPPED_VALUES = gql`
  mutation entityQueries_setFieldMappedValues(
    $request: SetSnapshotFieldQueryAllowedValuesInput
  ) {
    entityQueries_setFieldMappedValues(request: $request) {
      id
      selectStatement
      fieldDefinitionId
      name
      displayName
      description
      dataType
      prefixIds
      allowedValues {
        id
        value
      }
      mappedValues {
        id
        fieldDefinitionAllowedValueId
        sourceValue
      }
    }
  }
`;

export const UPDATE_ENTITY_QUERY = gql`
  mutation entityQueries_updateEntityQuery(
    $request: UpdateSnapshotEntityQueryInput
  ) {
    entityQueries_updateEntityQuery(request: $request) {
      baseQuery
      entityDefinitionId
      id
      fields {
        id
        selectStatement
        fieldDefinitionId
        mappedValues {
          id
          fieldDefinitionAllowedValueId
          fieldQueryId
          sourceValue
          fieldQuery {
            id
          }
          allowedValue {
            id
          }
        }
        name
        displayName
        description
        dataType
        prefixIds
        allowedValues {
          id
          value
        }
      }
      name
      displayName
      description
    }
  }
`;

//WK 26/11/2021 - Used in EntityRawPreview.tsx only in order to get the full query
export const PREVIEW_ENTITY_QUERY = gql`
  query entityQueries_listQueriesForMigration($entityQueryId: Long, $migrationId: Guid) {
    entityQueries_previewEntityQuery(entityQueryId: $entityQueryId, migrationId: $migrationId)
  }
`;