import React, { useState, useEffect } from "react";

import { apiClient } from "../index";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Spinner from "react-bootstrap/Spinner";

import { SortDown, CheckCircle, XCircle } from "react-bootstrap-icons";

import CollapsibleCard from "../containers/CollapsibleCard";
import EntityTemplateGroupSelect from "./EntityTemplateGroupSelect";

import {
  LIST_ENTITY_QUERIES_FOR_MIGRATION_LITE,
  EntityQueriesQuery,
  SnapshotEntityQuery,
} from "../API/entityQueryService";

import {
  ASSIGN_ENTITY_TEMPLATE_TO_MIGRATION,
  EntityTemplatesMutation,
  AssignSnapshotEntityTemplateGroupInput,
} from "../API/entityTemplateService";
import { Link } from "react-router-dom";

export type EntityMappingListProps = {
  migrationId: string;
};

const EntityMappingList: React.FunctionComponent<EntityMappingListProps> = ({
  migrationId,
}) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [sortOrder, setSortOrder] = useState<number>(1);

  const [entityQueries, setEntityQueries] = useState<
    SnapshotEntityQuery[] | undefined
  >(undefined);

  const listEnityDefinitions = (migrationId: string) => {
    apiClient
      .query<EntityQueriesQuery>({
        query: LIST_ENTITY_QUERIES_FOR_MIGRATION_LITE,
        variables: {
          migrationId: migrationId,
        },
        fetchPolicy: "network-only",
        errorPolicy: "all",
      })
      .then((response) => {
        if (response.errors) {
          const errorMessage = response.errors[0].message;
          console.error(errorMessage);

          if (response.errors[0].extensions) {
            console.error(response.errors[0].extensions);
          }
        } else {
          setEntityQueries(response.data.entityQueries_listQueriesForMigration);
        }

        setLoading(false);
      })
      .catch((err: any) => {
        console.error(err);

        setLoading(false);
      });
  };

  const sortList = (
    sortOrder: number,
    listEnts: SnapshotEntityQuery[] | undefined
  ) => {
    let sortedEntities = listEnts?.slice();

    switch (sortOrder) {
      case 0:
        //not sorted
        break;
      case 1:
        sortedEntities?.sort(
          (first, second) => 0 - (first.name > second.name ? -1 : 1)
        );
        break;
      case 2:
        sortedEntities?.sort(
          (first, second) => 0 - (first.name > second.name ? 1 : -1)
        );
        break;
      case 3:
        sortedEntities?.sort(
          (first, second) =>
            0 - (first.fields.length > second.fields.length ? -1 : 1)
        );
        break;
      case 4:
        sortedEntities?.sort(
          (first, second) =>
            0 - (first.fields.length > second.fields.length ? 1 : -1)
        );
        break;
      case 5:
        sortedEntities?.sort(
          (first, second) =>
            0 - (first.baseQuery.length > second.baseQuery.length ? -1 : 1)
        );
        break;
      case 6:
        sortedEntities?.sort(
          (first, second) =>
            0 - (first.baseQuery.length > second.baseQuery.length ? 1 : -1)
        );
        break;
    }

    return sortedEntities;
  };

  const mutationSetSnapshotGeneration = (
    migrationId: string,
    entityTemplateGroupId: number
  ) => {
    var input: AssignSnapshotEntityTemplateGroupInput = {
      migrationId: migrationId,
      entityTemplateGroupId: entityTemplateGroupId,
    };

    apiClient
      .mutate<EntityTemplatesMutation>({
        mutation: ASSIGN_ENTITY_TEMPLATE_TO_MIGRATION,
        variables: {
          request: input,
        },
        errorPolicy: "all",
      })
      .then((response) => {
        console.log(response);
        listEnityDefinitions(migrationId);
      })
      .catch((err) => console.error(err));
  };

  const HandleEntityTemplateGroupSelected = (value: number) => {
    mutationSetSnapshotGeneration(migrationId, value);
  };

  useEffect(() => {
    listEnityDefinitions(migrationId);
  }, [migrationId]);

  return (
    <CollapsibleCard
      headerText="Alto Entity List"
      showPoll={false}
      options={
        <>
          <Form>
            <Row>
              <Col className="align-self-center" sm="2">
                <SortDown width={16} height={16} />
              </Col>
              <Col sm="10">
                <Form.Select
                  disabled={
                    loading
                      ? true
                      : entityQueries && entityQueries.length > 0
                      ? false
                      : true
                  }
                  size="sm"
                  onChange={(e) => {
                    setSortOrder(parseInt(e.currentTarget.value));
                  }}
                >
                  <option value="1">Entity Name A-Z</option>
                  <option value="2">Entity Name Z-A</option>
                  <option value="3">Field Count asc</option>
                  <option value="4">Field Count desc</option>
                  <option value="5">Query length asc</option>
                  <option value="6">Query length desc</option>
                </Form.Select>
              </Col>
            </Row>
          </Form>
        </>
      }
    >
      {loading ? (
        <Spinner animation="border" role="status" variant="secondary">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      ) : (
        <>
          {entityQueries && entityQueries.length > 0 ? (
            <Table bordered hover>
              <thead>
                <tr>
                  <th>Base Query</th>
                  <th>Name</th>
                  <th>Description</th>
                  <th>Field Count</th>
                </tr>
              </thead>
              <tbody>
                {sortList(sortOrder, entityQueries)?.map((e) => (
                  <tr key={e.id}>
                    <td>
                      {e.baseQuery?.length > 0 ? (
                        <>
                          <CheckCircle
                            width="16"
                            height="16"
                            className="me-1"
                            color="green"
                          />
                          Yes
                        </>
                      ) : (
                        <>
                          <XCircle
                            width="16"
                            height="16"
                            className="me-1"
                            color="red"
                          />
                          No
                        </>
                      )}
                    </td>
                    <td>
                      <Link
                        to={"/migration/" + migrationId + "/entities/" + e.name}
                        className="visitedLink"
                      >
                        {e.displayName}
                      </Link>
                    </td>
                    <td>{e.description}</td>
                    <td>{e.fields.length}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <>
              <h4>No Entity Queries found - Select preset</h4>
              <EntityTemplateGroupSelect
                onEntityTemplateGroupSelected={
                  HandleEntityTemplateGroupSelected
                }
              />
            </>
          )}
        </>
      )}
    </CollapsibleCard>
  );
};
export default EntityMappingList;
