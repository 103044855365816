import React, { useState } from "react";

import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Collapse from "react-bootstrap/Collapse";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

// import { Link } from "react-router-dom";

import * as Icons from "react-bootstrap-icons";

import Switch from "react-switch";

type Props = {
  headerText?: string;
  showPoll?: boolean;
  showHeader?: boolean;
  showFooter?: boolean;
  onPollChange?: any;
  badges?: JSX.Element;
  options?: JSX.Element;
  collapsedContent?: JSX.Element;
};

const CollapsibleCard: React.FC<Props> = ({
  headerText,
  showPoll,
  showHeader = true,
  showFooter = false,
  onPollChange,
  badges,
  options,
  collapsedContent,
  children,
}) => {
  const [open, setOpen] = useState(true);
  const [poll, setPoll] = useState(true);

  return (
    <>
      <Card className={open === true ? "shadow mb-3" : "shadow mb-3"}>
        {showHeader ? (
          <Card.Header>
            <OverlayTrigger
              placement="left"
              overlay={
                <Tooltip id="tooltip-collapse">
                  {open === true ? "Collapse" : "Expand"}
                </Tooltip>
              }
            >
              <Button
                size="sm"
                variant="light"
                className="m-0 float-end"
                onClick={() => {
                  if (typeof onPollChange === "function") {
                    open ? onPollChange(false) : onPollChange(!open && poll);
                  }
                  setOpen(!open);
                }}
                aria-controls="example-collapse-text"
                aria-expanded={open}
              >
                {open ? <Icons.ChevronUp /> : <Icons.ChevronDown />}
              </Button>
            </OverlayTrigger>

            {showPoll === true ? (
              <Switch
                className="ms-1 me-2 mt-1 float-end"
                disabled={!open}
                onChange={() => {
                  setPoll(!poll);
                  onPollChange(!poll);
                }}
                checked={poll}
              />
            ) : null}

            <span className="float-end me-1">{options}</span>
            {open === false && collapsedContent ? collapsedContent : headerText}
            <span>{badges}</span>
          </Card.Header>
        ) : (
          <></>
        )}

        <Collapse in={open}>
          <div>
            <Card.Body>{children}</Card.Body>
            {showFooter ? <Card.Footer>footer</Card.Footer> : <></>}
          </div>
        </Collapse>
      </Card>
    </>
  );
};

export default CollapsibleCard;
