import React from "react";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";

import TransformationHome from "./Transformation/TransformationHome";
import MappingScriptHome from "./MappingScript/MappingScriptHome";
import SnapshotTemplateHome from "./SnapshotTemplate/SnapshotTemplateHome";

const SnapshotPlayground: React.FC = () => {
  return (
    <Container fluid>
      <Row>
        <Col>
          <h1>Snapshot API playground</h1>
        </Col>
      </Row>
      <Row className="my-5">
        <Col>
          <Tabs defaultActiveKey="transformation" id="tab-playground">
            <Tab eventKey="transformation" title="Transformation">
              <TransformationHome />
            </Tab>
            <Tab eventKey="mappedTable" title="Mapped Table"></Tab>
            <Tab eventKey="mappingScript" title="Mapping Script">
              <MappingScriptHome />
            </Tab>
            <Tab eventKey="dictionaryMapping" title="Dictionary Mapping"></Tab>
            <Tab eventKey="expressionMapping" title="Expression Mapping"></Tab>
            <Tab
              eventKey="lookupTableMapping"
              title="Lookup Table Mapping"
            ></Tab>
            <Tab eventKey="sqlMapping" title="Sql Mapping"></Tab>
            <Tab eventKey="snapshotTemplate" title="Snapshot Template">
              <SnapshotTemplateHome />
            </Tab>
          </Tabs>
        </Col>
      </Row>
    </Container>
  );
};

export default SnapshotPlayground;
