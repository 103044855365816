import React, { useState, useEffect } from "react";

import { apiClient } from "../index";

import CollapsibleCard from "../containers/CollapsibleCard";

import {
  MigrationQuery,
  GET_AGENT_BY_MIGRATION,
  Migration,
} from "../API/migrationService";

import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

// import MiniMap from "../components/MiniMap";
// import * as Icon from "react-bootstrap-icons";

type AgentCardProps = {
  migrationId?: string;
};

const AgentCard: React.FC<AgentCardProps> = ({ migrationId }) => {
  const [loading, setLoading] = useState<Boolean>(false);
  const [migration, setMigration] = useState<Migration | undefined>(undefined);

  useEffect(() => {
    findMigration(migrationId);
  }, [migrationId]);

  const findMigration = (migrationId: string | undefined) => {
    if (migrationId) {
      setLoading(true);

      apiClient
        .query<MigrationQuery>({
          query: GET_AGENT_BY_MIGRATION,
          variables: {
            migrationId: migrationId,
          },
          errorPolicy: "all",
        })
        .then((response) => {
          if (response.errors) {
            const errorMessage = response.errors[0].message;
            console.error(errorMessage);

            if (response.errors[0].extensions) {
              console.error(response.errors[0].extensions);
            }
            setLoading(false);
          } else {
            setMigration(response.data.migrations_findMigrationById);
            setLoading(false);
          }
        })
        .catch((err: any) => {
          console.error(err);
          setLoading(false);
        });
    }
  };

  return (
    <>
      {migrationId ? (
        <CollapsibleCard
          headerText={loading === true ? "Loading..." : migration?.companyName}
        >
          <>
            <Container fluid>
              <Row className="mb-2">
                <Col sm md lg="12" xl="12">
                  <img
                    className="me-1"
                    src={
                      "../images/" +
                      migration?.software.name?.toLowerCase() +
                      ".png"
                    }
                    alt={migration?.software.name}
                  />
                  {migration?.software.name +
                    " (" +
                    migration?.software.version +
                    ")"}
                </Col>
              </Row>
              {/* <Row>
                    <Col sm md lg="6" xl="12">
                      <h5>
                        <Icon.Person height="24" width="24" className="me-2" />
                        Matthew James
                      </h5>
                      <h6>
                        <Icon.Phone height="24" width="24" className="me-2" />
                        07494 65894
                      </h6>
                      <h6>
                        <Icon.Telephone
                          height="24"
                          width="24"
                          className="me-2"
                        />
                        01209 312612
                      </h6>
                    </Col>
                    <Col sm md lg="6" xl="12">
                      <h6>
                        <Icon.Envelope
                          height="24"
                          width="24"
                          className="me-2"
                        />
                        mj@matthewjames.co.uk
                      </h6>
                      <h5>
                        <Icon.Code height="24" width="24" className="me-2" />
                        <code>93678/4213</code>
                      </h5>
                      <h6>
                        <Icon.GeoAlt height="24" width="24" className="me-2" />
                        NW5 1AD
                      </h6>
                    </Col>
                    <Col sm md lg xl="12">
                      <MiniMap postcode="NW5 1AD" />
                    </Col>
                  </Row> */}
            </Container>
          </>
        </CollapsibleCard>
      ) : (
        <CollapsibleCard headerText="Loading..."></CollapsibleCard>
      )}
    </>
  );
};

export default AgentCard;
