import React, { FC } from "react";
import { ICellRendererParams } from "ag-grid-community";

import { EntityFieldRow } from "../../API/databaseService";

import { ClipboardPlus } from "react-bootstrap-icons";

interface IEntityNameCellRendererParams
  extends Omit<ICellRendererParams, "data"> {
  data: EntityFieldRow;
}

const EntityNameRenderer: FC<IEntityNameCellRendererParams> = ({
  value,
  data,
}) => (
  <>
    <ClipboardPlus
      className="me-2 mb-1"
      onClick={() => navigator.clipboard.writeText(value)}
    />
    <span>{value}</span>
    {data.hasSQLMapping ? <i> SQL</i> : null}
  </>
);

export default EntityNameRenderer;
