import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./containers/App";
import * as serviceWorker from "./serviceWorker";

import {
  ApolloProvider,
  ApolloClient,
  createHttpLink,
  InMemoryCache,
} from "@apollo/client";

import { setContext } from "@apollo/client/link/context";

import { AuthService } from "./API/authService";

import SSORedirectDialog from "./components/Dialogs/SSORedirectDialog";

const apiUrl: string = process.env.REACT_APP_API_URL
  ? process.env.REACT_APP_API_URL
  : "This should be set in the .env file";

const authServiceURL: string = process.env.REACT_APP_AUTH_SERVICE_URL
  ? process.env.REACT_APP_AUTH_SERVICE_URL
  : "This should be set in the .env file";

/////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////
const authService = new AuthService(authServiceURL);

const doLogin = async () => {
  // Check to see if there is a token in local storage...
  if (!authService.hasLogin()) {
    console.log("There is no login!");
    // There is no token in local storage, so no user is logged in...
    if (authService.loginViaUrlToken()) {
      // A token is in the URL however, and so the local storage token has been set,
      // just clear the end of the URL so reload the page with the token cleared from
      // the URL...
      console.log("Logging in via token, yay!");

      window.location.replace(window.location.origin);
    } else {
      // A token was not set in the URL, or it was gibberish and couldn't be used for
      // authenticating. This will redirect the user to the Azure SSO system before
      // eventually redirecting them back here where they'll hopefully have a token
      // in the URL.
      console.log("No token in URL to log in from, redirecting...");

      authService.redirectToLogin();
    }
  } else {
    console.log("There is apparently a login...");
    // Someone is logged in apparently, so we can try and get their profile from the
    // auth service. The profile just contains their name and any roles they happen
    // to be in (not hugely important just yet, but the name might be useful).

    const p = await fetchProfile();
    console.log(p);
  }
};

const fetchProfile = async () => {
  try {
    return await authService.getProfileAsync();
  } catch (e) {
    console.log(
      "Failed to fetch profile, clearing any token and redirecting to login..."
    );
    console.log(e);
    // Something went horribly wrong, this should probably be expanded so you can
    // throw one error if it gets a 401 (i.e. the token is wrong and they should be
    // sent to the login page again) or if it's just a transient error that you want
    // to show on screen. ¯\_(ツ)_/¯

    authService.clearLogin();
    authService.redirectToLogin();
  }
};

doLogin();

/////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////

const authLink = setContext((_, { headers }) => {
  if (!authService.hasLogin()) {
    return {
      headers: {
        ...headers,
      },
    };
  }

  return {
    headers: {
      ...headers,
      authorization: `Bearer ${authService.getBearerToken()}`,
    },
  };
});

const httpLink = createHttpLink({
  uri: `${apiUrl}`,
});

export const apiClient = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

const handleLogout = () => {
  authService.clearLogin();
  authService.redirectToLogin();
};

ReactDOM.render(
  <React.StrictMode>
    {authService.hasLogin() ? (
      <ApolloProvider client={apiClient}>
        <App onProfileLogout={handleLogout} />
      </ApolloProvider>
    ) : (
      <SSORedirectDialog />
    )}
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
