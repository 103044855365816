import React, { useState, useEffect } from "react";

import { RecordField, ResultSet } from "../../API/databaseService";

import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Spinner from "react-bootstrap/Spinner";

import { ColDef, ColumnApi } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";

import Pagination from "@vlsergey/react-bootstrap-pagination";

import {
  ArrowsAngleContract,
  ArrowsAngleExpand,
  ArrowLeftRight,
} from "react-bootstrap-icons";

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";

interface MyRow {
  [name: string]: any;
}

export type QueryGridProps = {
  queryResults: ResultSet | undefined;
  pageIndex?: number;
  rowLimit?: number;
  onPageChanged?: any;
  loadingData?: boolean;
  defaultColumnWidth?: number;
};

const QueryGrid: React.FunctionComponent<QueryGridProps> = ({
  queryResults,
  pageIndex,
  rowLimit,
  onPageChanged,
  loadingData,
  defaultColumnWidth,
}) => {
  const [tableExpanded, setTableExpanded] = useState<boolean>(false);
  const [columnDefs, setColumnDefs] = useState<ColDef[]>([]);
  const [rowData, setRowData] = useState<MyRow[]>([]);

  const [columnApi, setColumnApi] = useState<ColumnApi | undefined>(undefined);

  const [rowCount, setRowCount] = useState<number>(0);
  const [pageCount, setPageCount] = useState<number>(1);

  useEffect(() => {
    if (queryResults && queryResults.records.length > 0) {
      var cds: ColDef[] = [];

      var addRowNumber: boolean = false;

      if (
        queryResults.records[0].fields.findIndex(
          (f) => f.name === "_rownumber"
        ) === -1
      ) {
        cds.push({
          headerName: "#",
          field: "_rownumber",
          width: 70,
          editable: true,
        });

        addRowNumber = true;
      }

      if (rowLimit && rowLimit > 0) {
        var rowCountField: RecordField | undefined =
          queryResults.records[0].fields.find((f) => f.name === "_rowcount");

        if (rowCountField) {
          var tempRowCount: number = parseInt(rowCountField.value);
          var numPages: number = Math.ceil(tempRowCount / rowLimit);

          setRowCount(tempRowCount);
          setPageCount(numPages);
        }
      }

      queryResults.records[0].fields.forEach((f) => {
        if (f.name !== "_rowcount") {
          if (f.name !== "_rownumber") {
            cds.push({
              headerName: f.name,
              field: f.name,
            });
          } else {
            cds.push({
              width: 70,
              headerName: "#",
              field: f.name,
            });
          }
        }
      });
      setColumnDefs(cds);

      var rows: MyRow[] = [];
      queryResults.records.forEach((r, i) => {
        var newRow: MyRow = {};
        if (addRowNumber) {
          newRow["_rownumber"] = i + 1;
        }

        r.fields.forEach((f) => {
          if (f.name !== "_rowcount") {
            newRow[f.name] = f.value;
          }
        });
        rows.push(newRow);
      });
      setRowData(rows);
    } else {
      setRowCount(0);
      setPageCount(0);
      setColumnDefs([]);
      setRowData([]);
    }

    // return () => {
    //   setColumnApi(undefined);
    // };
  }, [queryResults, rowLimit, pageIndex]);

  const onCellValueChanged = (event: {
    data: any;
    colDef: ColDef;
    oldValue: any;
    newValue: any;
  }) => {
    console.log("data after changes is: ", event.data);
    console.log(event.oldValue, event.newValue, event.colDef);
  };

  const resizeColumns = () => {
    if (columnApi) {
      columnApi.autoSizeAllColumns();
    }
  };

  if (queryResults) {
    return (
      <>
        <div style={{ height: 24 }}>
          <OverlayTrigger
            placement="left"
            overlay={
              <Tooltip id="tooltip-grid">
                {tableExpanded === true ? "Contract Grid" : "Expand Grid"}
              </Tooltip>
            }
          >
            <Button
              variant="light"
              className="ms-1 float-end"
              size="sm"
              onClick={() => setTableExpanded(!tableExpanded)}
            >
              {tableExpanded === true ? (
                <ArrowsAngleContract />
              ) : (
                <ArrowsAngleExpand />
              )}
            </Button>
          </OverlayTrigger>
          <OverlayTrigger
            placement="left"
            overlay={<Tooltip id="tooltip-autosize">Autosize Columns</Tooltip>}
          >
            <Button
              variant="light"
              className="ms-1 float-end"
              size="sm"
              onClick={resizeColumns}
            >
              <ArrowLeftRight />
            </Button>
          </OverlayTrigger>

          {loadingData === true ? (
            <>
              <Spinner
                animation="border"
                role="status"
                variant="secondary"
                size="sm"
                className="me-2"
              >
                <span className="visually-hidden">Loading...</span>
              </Spinner>
              Loading...
            </>
          ) : rowCount > 0 ? (
            `${rowCount} rows`
          ) : null}
        </div>
        <div
          className="ag-theme-alpine mt-2"
          style={{ height: tableExpanded ? "75vh" : 480 }}
        >
          <AgGridReact
            columnDefs={columnDefs}
            rowData={rowData}
            defaultColDef={{
              sortable: true,
              width: defaultColumnWidth ? defaultColumnWidth : 120,
              resizable: true,
              autoHeight: false,
              wrapText: false,
              editable: false,
              flex: 0,
            }}
            rowSelection="single"
            animateRows={true}
            rowDragManaged={true}
            suppressRowClickSelection={false}
            onGridReady={(params: { columnApi: any }) => {
              setColumnApi(params.columnApi);
            }}
            // onFirstDataRendered={resizeColumns}
            // onRowDataChanged={resizeColumns}
            // onModelUpdated={resizeColumns}
            onCellValueChanged={onCellValueChanged}
            debug={false}
          />
        </div>

        {pageCount > 1 ? (
          <Pagination
            className="mt-1"
            value={pageIndex ? pageIndex : 0}
            totalPages={pageCount}
            onChange={(e) =>
              onPageChanged ? onPageChanged(e.target.value) : null
            }
          />
        ) : null}
      </>
    );
  } else {
    return null;
  }
};

export default QueryGrid;
