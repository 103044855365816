import React from "react";

import { useQuery } from "@apollo/client";

import {
  FIND_TEMPLATE_BY_ID,
  RemappingQuery,
} from "../../../API/remappingService";

type SnapshotTemplateByIdProps = {
  snapshotTemplateId: number;
};

const SnapshotTemplateById: React.FC<SnapshotTemplateByIdProps> = ({
  snapshotTemplateId,
}) => {
  const { data, error, loading } = useQuery<RemappingQuery>(
    FIND_TEMPLATE_BY_ID,
    {
      variables: { snapshotTemplateId },
      pollInterval: 0,
      errorPolicy: "all",
    }
  );

  if (error) {
    console.log(error.message);
  }

  return loading ? (
    <p>loading</p>
  ) : (
    <div>
      <h4>snapshotTemplateById</h4>
      <p>
        <b>id: </b>
        {data?.remapping_findTemplateById.id}
      </p>
      <p>
        <b>generateSnapshotOnStable: </b>
        {data?.remapping_findTemplateById.generateSnapshotOnStable
          ? "true"
          : "false"}
      </p>
      {/* <p>
        <b>migrationId: </b>
        {data?.remapping_findTemplateById.}
      </p> */}
      <p>
        <b>transformationId: </b>
        {data?.remapping_findTemplateById.snapshotTransformationId}
      </p>

      <h6>Pre Scripts</h6>

      {data?.remapping_findTemplateById.preScripts.map((ps) => (
        <ul>
          <li>{ps.id}</li>
          <li>{ps.name}</li>
          <li>{ps.content}</li>
          <li>{ps.description}</li>
        </ul>
      ))}

      <h6>Post Scripts</h6>

      {data?.remapping_findTemplateById.postScripts.map((ps) => (
        <ul>
          <li>{ps.id}</li>
          <li>{ps.name}</li>
          <li>{ps.content}</li>
          <li>{ps.description}</li>
        </ul>
      ))}
    </div>
  );
};

export default SnapshotTemplateById;
