import React from "react";

import { useHistory } from "react-router-dom";

import { useQuery } from "@apollo/client";

import {
  GET_RAW_DATABASE_SCHEMA,
  DatabasesQuery,
} from "../API/databaseService";

import {
  FIND_TRANSFORMATION_BY_ID,
  RemappingQuery,
} from "../API/remappingService";

import CollapsibleCard from "../containers/CollapsibleCard";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Spinner from "react-bootstrap/Spinner";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

import { FileEarmarkCode } from "react-bootstrap-icons";

export type EntitySelectProps = {
  migrationId: string;
  entityName: string;
  transformationId: number;
  onEntityNameChange?: any;
  onAddSQLClicked?: any;
};

const EntitySelect: React.FunctionComponent<EntitySelectProps> = ({
  migrationId,
  entityName,
  transformationId,
  onEntityNameChange,
  onAddSQLClicked,
}) => {
  const {
    data: dataDB,
    error: errorDB,
    loading: loadingDB,
  } = useQuery<DatabasesQuery>(GET_RAW_DATABASE_SCHEMA, {
    variables: { migrationId },
    errorPolicy: "all",
  });

  const { data: dataTfmn } = useQuery<RemappingQuery>(
    FIND_TRANSFORMATION_BY_ID,
    {
      variables: { transformationId },
      errorPolicy: "all",
    }
  );

  const timeElapsed = Date.now();
  const today = new Date(timeElapsed);

  errorDB
    ? console.log(today.toISOString() + ":" + errorDB.message)
    : console.log(today.toISOString() + ": Loaded Migration Selector");

  const dbSchema = dataDB?.databases_getRawDatabaseSchema;

  const renderMappingIcon = (entNme: string) => {
    var retval = "";

    let a = dataTfmn?.remapping_findTransformationById;
    let b = a?.tables?.filter((t) => t.tableName === entNme)[0];

    if (b && b.mappings.length > 0) {
      retval = "\u270F\uFE0F";
    }

    return retval;
  };

  const history = useHistory();

  function handleNameChange(entName: string) {
    history.push("/refine/" + migrationId + "/" + entName);
  }

  return (
    <CollapsibleCard headerText="Entity Select">
      {loadingDB === true ? (
        <Spinner animation="border" role="status" variant="secondary">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      ) : (
        <Container fluid>
          <Row>
            <Col>
              <Form>
                <Row>
                  <Col lg="7">
                    <Form.Group>
                      <Form.Label>Entity Name</Form.Label>
                      <Form.Select
                        size="lg"
                        defaultValue={entityName}
                        onChange={(e) => {
                          if (onEntityNameChange) {
                            handleNameChange(e.currentTarget.value);
                            onEntityNameChange(e.currentTarget.value);
                          }
                        }}
                      >
                        {dbSchema?.tables.map((t) => (
                          <option key={t.entityName} value={t.entityName}>
                            {t.entityName}
                            {renderMappingIcon(t.entityName)}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group>
                      <Form.Label>Fields</Form.Label>
                      <Form.Control
                        type="input"
                        size="lg"
                        placeholder="Fields"
                        readOnly={true}
                        value={dbSchema?.tables
                          .filter((t) => t.entityName === entityName)[0]
                          .fields.length.toString()}
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                  <Col xl={2}>
                    <p>Mappings</p>
                    <Button className="me-1" onClick={onAddSQLClicked}>
                      <FileEarmarkCode
                        width={20}
                        height={20}
                        className="mb-1"
                      />{" "}
                      SQL
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </Container>
      )}
    </CollapsibleCard>
  );
};

export default EntitySelect;
