import React, { useState, useEffect } from "react";

import { apiClient } from "../index";

import {
  MigrationQuery,
  GET_MIGRATION_TRANSFORMATION,
} from "../API/migrationService";

import {
  CREATE_MAPPED_TABLE,
  RemappingMutation,
  SnapshotMapping,
  SqlMapping,
  MappedTable,
} from "../API/remappingService";

import EntitySelect from "../components/EntitySelect";
import EntityFieldGrid from "../components/Datagrids/EntityFieldGrid";
import EntityTransforms from "../components/EntityTransforms";
import EntityTemplateQuery from "../components/EntityTemplateQuery";

type MappingWrapperParams = {
  migrationId: string;
  entityName: string;
};

const MappingWrapper: React.FC<MappingWrapperParams> = ({
  migrationId,
  entityName,
}) => {
  const [loading, setLoading] = useState<boolean>(true);

  const [transformationId, setTransformationId] = useState(0);
  const [selectedEntityName, setSelectedEntityName] = useState(entityName);

  const [currentMappedTable, setCurrentMappedTable] = useState<
    MappedTable | undefined
  >(undefined);
  const [mappingList, setMappingList] = useState<SnapshotMapping[] | undefined>(
    undefined
  );

  const [tabKey, setTabKey] = useState<string | number | undefined>("");

  useEffect(() => {
    getTransformation(migrationId, entityName);
    setLoading(false);
  }, [migrationId, entityName]);

  function handleEntityNameChange(newValue: string) {
    getTransformation(migrationId, newValue);
    setSelectedEntityName(newValue);
  }

  function handleMappingChange() {
    getTransformation(migrationId, entityName);
  }

  function handleTabChange(newValue: string) {
    setTabKey(newValue);
  }

  const getTransformation = (migrationId: string, entityName: string) => {
    apiClient
      .query<MigrationQuery>({
        query: GET_MIGRATION_TRANSFORMATION,
        variables: {
          migrationId: migrationId,
        },
        fetchPolicy: "network-only",
        errorPolicy: "all",
      })
      .then((response) => {
        if (response.errors) {
          console.error(response.errors[0].message);
        } else {
          setTransformationId(
            response.data?.migrations_findMigrationById.template.transformation
              .id
          );
          var currentEntity =
            response.data?.migrations_findMigrationById.template.transformation.tables.filter(
              (t) => t.tableName === entityName
            )[0];
          if (currentEntity) {
            setCurrentMappedTable(currentEntity);
            setTabKey(
              currentEntity.mappings.length > 0
                ? currentEntity.mappings[0].id.toString()
                : "0"
            );
            setMappingList(
              currentEntity.mappings.length > 0
                ? currentEntity.mappings
                : undefined
            );
          } else {
            setMappingList(undefined);
            setCurrentMappedTable(undefined);
          }
        }
      })
      .catch((err) => console.error(err));
  };

  const mutationCreateMappedTable = (
    transformationId: number,
    tableName: string
  ) => {
    apiClient
      .mutate<RemappingMutation>({
        mutation: CREATE_MAPPED_TABLE,
        variables: {
          snapshotTransformationId: transformationId,
          tableName: tableName,
        },
        errorPolicy: "all",
      })
      .then((response) => {
        if (response.errors) {
          console.error(response.errors[0].message);
        } else {
          if (
            response.data?.remapping_createMappedTable.id &&
            response.data?.remapping_createMappedTable.id > 0
          ) {
            var tableId = response.data?.remapping_createMappedTable.id;
            AddSQLMapping(tableId);
          }
        }
      })
      .catch((err) => console.error(err));
  };

  const handleAddSQLClicked = () => {
    var tableId = currentMappedTable ? currentMappedTable.id : 0;

    console.log("tableid:" + tableId);

    if (!currentMappedTable) {
      mutationCreateMappedTable(transformationId, entityName);
    } else {
      AddSQLMapping(tableId);
    }
  };

  const AddSQLMapping = (tableId: number) => {
    const newSqlMapping: SqlMapping = {
      query: "",
      rawKeyField: "",
      queryKeyField: "",
      updateFields: [],
    };

    const newMapping: SnapshotMapping = {
      id: 0,
      snapshotMappedTableId: tableId,
      mapping: newSqlMapping,
    };

    var updatedMappingList = mappingList?.slice();

    if (!updatedMappingList) {
      updatedMappingList = [];
    }

    if (updatedMappingList?.filter((f) => f.id === 0).length === 0) {
      updatedMappingList?.push(newMapping);
      if (updatedMappingList) {
        setMappingList(updatedMappingList);
        setTabKey("0");
      }
    }
  };

  return (
    <>
      <EntitySelect
        migrationId={migrationId}
        entityName={selectedEntityName}
        transformationId={transformationId}
        onEntityNameChange={handleEntityNameChange}
        onAddSQLClicked={handleAddSQLClicked}
      />

      <EntityTemplateQuery
        migrationId={migrationId}
        entityName={selectedEntityName}
      />

      {mappingList && mappingList?.length > 0 ? (
        loading ? (
          <p>loading</p>
        ) : (
          <EntityTransforms
            migrationId={migrationId}
            entityName={selectedEntityName}
            mappingList={mappingList}
            tabKey={tabKey}
            onMappingChange={handleMappingChange}
            onTabChanged={handleTabChange}
          />
        )
      ) : null}

      <EntityFieldGrid
        migrationId={migrationId}
        entityName={selectedEntityName}
        mappingList={mappingList}
      />
    </>
  );
};

export default MappingWrapper;
