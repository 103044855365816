import React, { useState, useEffect } from "react";

import { useHistory } from "react-router-dom";

import { apiClient } from "../index";

import Alert from "react-bootstrap/Alert";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";

import CollapsibleCard from "../containers/CollapsibleCard";
import EntityMappingBaseQuery from "../components/EntityMappingBaseQuery";
import EntityMappingFieldRow from "../components/EntityMappingFieldRow";
import FieldQuery from "../components/FieldQuery";
import ConfirmDialog from "./Dialogs/ConfirmDialog";

import { SortDown, Save2 } from "react-bootstrap-icons";

import {
  LIST_ENTITY_QUERIES_FOR_MIGRATION_LITE,
  FIND_ENTITY_QUERY,
  UPDATE_ENTITY_QUERY,
  SnapshotEntityQuery,
  ISnapshotFieldQuery,
  EntityQueriesQuery,
  EntityQueriesMutation,
  UpdateSnapshotEntityQueryInput,
  UpdatedFieldQueryInput,
} from "../API/entityQueryService";

import {
  GET_EXPLAIN_SELECT_ENTITY_QUERY,
  DatabasesQuery,
  ExplainSelectTable,
  ErrorExtensions,
} from "../API/databaseService";

export type EntityMappingFieldsProps = {
  migrationId: string;
  entityName: string;
};

const EntityMappingFields: React.FunctionComponent<
  EntityMappingFieldsProps
> = ({ migrationId, entityName }) => {
  let history = useHistory();

  const [loading, setLoading] = useState<boolean>(true);
  const [loadingEntity, setLoadingEntity] = useState<boolean>(true);

  const [queryDirty, setQueryDirty] = useState<boolean>(false);
  const [queryParsed, setQueryParsed] = useState<boolean | undefined>(
    undefined
  );
  const [fieldsDirty, setFieldsDirty] = useState<boolean>(false);

  const [queryErrorMessage, setQueryErrorMessage] = useState<string>("");
  const [queryErrorException, setQueryErrorException] = useState<string>("");

  const [sortOrder, setSortOrder] = useState<number>(1);

  const [entityQueryId, setEntityQueryId] = useState<number>(0);

  const [entityQueryList, setEntityQueryList] = useState<
    SnapshotEntityQuery[] | undefined
  >(undefined);

  const [snapshotEntity, setSnapshotEntity] = useState<
    SnapshotEntityQuery | undefined
  >(undefined);
  const [originalBaseQuery, setOriginalBaseQuery] = useState<string>("");
  const [tempBaseQuery, setTempBaseQuery] = useState<string>("");

  const [updatedFields, setUpdatedFields] = useState<UpdatedFieldQueryInput[]>(
    []
  );

  const [fieldList, setFieldList] = useState<ISnapshotFieldQuery[] | undefined>(
    undefined
  );

  const [fieldQueryShow, setFieldQueryShow] = useState<boolean>(false);
  const [selectedFieldQueryId, setSelectedFieldQueryId] = useState<number>(0);

  const [queryFields, setQueryFields] = useState<
    ExplainSelectTable[] | undefined
  >(undefined);

  const [saveDialogShow, setSaveDialogShow] = useState<boolean>(false);

  const sortList = (
    sortOrder: number,
    listFields: ISnapshotFieldQuery[] | undefined
  ) => {
    let sortedFields = listFields?.slice();

    switch (sortOrder) {
      case 0:
        //not sorted
        break;
      case 1:
        sortedFields?.sort(
          (first, second) => 0 - (first.id > second.id ? -1 : 1)
        );
        break;
      case 2:
        sortedFields?.sort(
          (first, second) => 0 - (first.id > second.id ? 1 : -1)
        );
        break;
      case 3:
        sortedFields?.sort(
          (first, second) => 0 - (first.name > second.name ? -1 : 1)
        );
        break;
      case 4:
        sortedFields?.sort(
          (first, second) => 0 - (first.name > second.name ? 1 : -1)
        );
        break;
      case 5:
        sortedFields?.sort(
          (first, second) => 0 - (first.dataType > second.dataType ? -1 : 1)
        );
        break;
      case 6:
        sortedFields?.sort(
          (first, second) => 0 - (first.dataType > second.dataType ? 1 : -1)
        );
        break;
    }

    return sortedFields;
  };

  const mutationUpdateEntityQuery = (
    entityQueryId: number,
    baseQuery: string | undefined
  ) => {
    console.log(entityQueryId, baseQuery);

    if (baseQuery) {
      setQueryErrorMessage("");
      setQueryErrorException("");

      var input: UpdateSnapshotEntityQueryInput = {
        entityQueryId: entityQueryId,
        baseQuery: baseQuery,
      };

      if (updatedFields.length > 0) {
        input.updatedFields = updatedFields;
      }

      console.log("input", input);

      apiClient
        .mutate<EntityQueriesMutation>({
          mutation: UPDATE_ENTITY_QUERY,
          variables: {
            request: input,
          },
          errorPolicy: "all",
        })
        .then((response) => {
          if (response.errors) {
            const errorMessage = response.errors[0].message;
            setQueryErrorMessage(errorMessage);
            if (response.errors[0].extensions) {
              const ext: ErrorExtensions = response.errors[0]
                .extensions as ErrorExtensions;
              if (ext.data) {
                setQueryErrorException(ext.data.exception.message);
              }
            }
          } else {
            setQueryErrorMessage("");
            setQueryErrorException("");

            setQueryDirty(false);
            setFieldsDirty(false);

            console.log(
              "Updated",
              response.data?.entityQueries_updateEntityQuery
            );

            setSnapshotEntity(response.data?.entityQueries_updateEntityQuery);
            if (response.data?.entityQueries_updateEntityQuery.baseQuery) {
              setOriginalBaseQuery(
                response.data?.entityQueries_updateEntityQuery.baseQuery
              );
            }

            // if (response.data?.entityQueries_updateEntityQuery.fields) {
            //   let fieldQueryList: ISnapshotFieldQuery[] =
            //     response.data?.entityQueries_updateEntityQuery.fields.map(
            //       (x) => ({
            //         ...x,
            //         originalSelectStatement: x.selectStatement,
            //         rowDirty: false,
            //         editorExpanded: false,
            //       })
            //     );
            //   setFieldList(fieldQueryList);
            // }

            if (fieldList) {
              let fieldQueryList = fieldList.slice().map((x) => ({
                ...x,
                originalSelectStatement: x.selectStatement,
                rowDirty: false,
              }));
              console.log(fieldQueryList);
              setFieldList(fieldQueryList);
            }
          }
        })
        .catch((err) => console.error(err));
    }
  };

  const renderQueryIcon = (eq: SnapshotEntityQuery) => {
    if (eq.baseQuery && eq.baseQuery.length > 0) {
      return "\u2705";
    } else {
      return "\u274C";
    }
  };

  useEffect(() => {
    const listEntityQueriesEntityName = (
      migrationId: string,
      entityName: string
    ) => {
      apiClient
        .query<EntityQueriesQuery>({
          query: LIST_ENTITY_QUERIES_FOR_MIGRATION_LITE,
          variables: {
            migrationId: migrationId,
          },
          errorPolicy: "all",
        })
        .then((response) => {
          if (response.errors) {
            const errorMessage = response.errors[0].message;
            console.error(errorMessage);

            if (response.errors[0].extensions) {
              console.error(response.errors[0].extensions);
            }
          } else {
            let filtered =
              response.data.entityQueries_listQueriesForMigration.filter(
                (x) => x.name === entityName
              );

            if (filtered && filtered.length > 0) {
              let matchingId: number = filtered[0].id;
              setEntityQueryId(matchingId);
              findEntityQuery(matchingId);
            }

            setEntityQueryList(
              response.data.entityQueries_listQueriesForMigration
            );
          }
        })
        .catch((err: any) => {
          console.error(err);
        });
    };

    const findEntityQuery = (entityQueryId: number) => {
      setLoadingEntity(true);

      if (entityQueryId) {
        setQueryErrorMessage("");
        setQueryErrorException("");
        setUpdatedFields([]);

        apiClient
          .query<EntityQueriesQuery>({
            query: FIND_ENTITY_QUERY,
            variables: {
              entityQueryId: entityQueryId,
            },
            errorPolicy: "all",
            fetchPolicy: "network-only",
          })
          .then((response) => {
            if (response.errors) {
              const errorMessage = response.errors[0].message;
              console.error(errorMessage);

              if (response.errors[0].extensions) {
                console.error(response.errors[0].extensions);
              }

              setLoading(false);
            } else {
              if (response.data.entityQueries_findEntityQuery.id) {
                parseSQLQuery(
                  migrationId,
                  response.data.entityQueries_findEntityQuery.baseQuery
                );

                setSnapshotEntity(response.data.entityQueries_findEntityQuery);
                setOriginalBaseQuery(
                  response.data.entityQueries_findEntityQuery.baseQuery
                );
                setTempBaseQuery(
                  response.data.entityQueries_findEntityQuery.baseQuery
                );

                let fieldQueryList: ISnapshotFieldQuery[] =
                  response.data.entityQueries_findEntityQuery.fields.map(
                    (x) => ({
                      ...x,
                      originalSelectStatement: x.selectStatement,
                      rowDirty: false,
                      editorExpanded: false,
                    })
                  );
                setFieldList(fieldQueryList);

                setQueryDirty(false);
                setFieldsDirty(false);

                setLoading(false);
                setLoadingEntity(false);
              } else {
                setSnapshotEntity(undefined);
                setFieldList(undefined);

                setLoading(false);
                setLoadingEntity(false);
              }
            }
          })
          .catch((err: any) => {
            console.error(err);

            setLoading(false);
            setLoadingEntity(false);
          });
      }
    };

    const parseSQLQuery = (migrationId: string, query: string) => {
      if (query !== "") {
        apiClient
          .query<DatabasesQuery>({
            query: GET_EXPLAIN_SELECT_ENTITY_QUERY,
            variables: {
              query: query.trim(),
              migrationId: migrationId,
            },
            fetchPolicy: "network-only",
            errorPolicy: "all",
          })
          .then((response) => {
            if (response.errors) {
              const errorMessage = response.errors[0].message;
              console.log("Parse failed", query, errorMessage);
              setQueryParsed(false);
            } else {
              setQueryParsed(true);
              setQueryFields(response.data.databases_explainSelectEntityQuery);
            }
          })
          .catch((err) => {
            setQueryParsed(false);
            console.error(err);
          });
      } else {
        setQueryParsed(false);
      }
    };

    listEntityQueriesEntityName(migrationId, entityName);
  }, [migrationId, entityName]);

  const handleParseSuccess = (
    baseQuery: string,
    queryFields: ExplainSelectTable[]
  ) => {
    if (snapshotEntity) {
      setTempBaseQuery(baseQuery);
      setQueryDirty(originalBaseQuery !== baseQuery);
      setQueryFields(queryFields);
      setQueryParsed(true);
    }
  };

  const handleParseFailure = () => {
    setQueryParsed(false);
  };

  const handleEditorChange = (baseQuery: string) => {
    if (snapshotEntity) {
      setTempBaseQuery(baseQuery);
      setQueryDirty(originalBaseQuery !== baseQuery);
      setQueryParsed(undefined);
    }
  };

  const handleSelectedFieldQueryChanged = (selectedFieldQueryId: number) => {
    setSelectedFieldQueryId(selectedFieldQueryId);
    setFieldQueryShow(true);
  };

  const handleFieldUpdated = (fieldQueryId: number, value: string) => {
    var uf: UpdatedFieldQueryInput[] = updatedFields;

    var index = uf.findIndex((u) => u.fieldQueryId === fieldQueryId);

    if (index === -1) {
      uf.push({
        fieldQueryId: fieldQueryId,
        selectStatement: value !== "" ? value : '""',
      });
    } else {
      uf[index].selectStatement = value !== "" ? value : '""';
    }

    setUpdatedFields(uf);

    var fl: ISnapshotFieldQuery[] | undefined = fieldList?.slice();

    if (fl) {
      var i = fl.findIndex((u) => u.id === fieldQueryId);
      if (i > -1) {
        fl[i].selectStatement = value;
        fl[i].rowDirty =
          fl[i].selectStatement !== fl[i].originalSelectStatement;
      }

      setFieldList(fl);
    }

    if (fl?.find((f) => f.rowDirty === true)) {
      setFieldsDirty(true);
    } else {
      setFieldsDirty(false);
    }
  };

  const handleEditorExpandedToggle = (fieldQueryId: number) => {
    var fl: ISnapshotFieldQuery[] | undefined = fieldList?.slice();

    if (fl) {
      var i = fl.findIndex((u) => u.id === fieldQueryId);
      if (i > -1) {
        fl[i].editorExpanded = !fl[i].editorExpanded;
      }

      setFieldList(fl);
    }
  };

  const handleSaveDialogHide = () => {
    setSaveDialogShow(false);
  };

  const handleSaveDialogOK = () => {
    setSaveDialogShow(false);
    mutationUpdateEntityQuery(entityQueryId, tempBaseQuery);
  };

  return (
    <>
      <CollapsibleCard showPoll={false} showHeader={false}>
        {loading ? (
          <Spinner animation="border" role="status" variant="secondary">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        ) : (
          <Container fluid>
            <Row>
              <Col xl="10">
                <Form>
                  <Form.Select
                    size="lg"
                    value={entityName}
                    onChange={(e) => {
                      history.push(
                        "/migration/" +
                          migrationId +
                          "/entities/" +
                          e.currentTarget.value
                      );
                    }}
                  >
                    {entityQueryList
                      ?.slice()
                      .sort(
                        (first, second) =>
                          0 - (first.name > second.name ? -1 : 1)
                      )
                      .map((el) => (
                        <option key={el.id} value={el.name}>
                          {renderQueryIcon(el)} {el.displayName}
                        </option>
                      ))}
                  </Form.Select>
                </Form>
              </Col>
              <Col xl="2">
                <Button
                  disabled={loadingEntity}
                  style={{ width: 110 }}
                  variant={queryDirty || fieldsDirty ? "warning" : "success"}
                  className="m-1"
                  onClick={() => setSaveDialogShow(true)}
                >
                  {loadingEntity ? (
                    <>
                      <Spinner
                        size="sm"
                        animation="border"
                        role="status"
                        className="me-1"
                      >
                        <span className="visually-hidden">Loading...</span>
                      </Spinner>
                      Save
                    </>
                  ) : (
                    <>
                      <Save2 width={20} height={20} className="me-1" /> Save
                    </>
                  )}
                </Button>
              </Col>
            </Row>
            <Row>
              <Col>
                <blockquote className="blockquote mt-3">
                  {loadingEntity ? (
                    <Spinner
                      size="sm"
                      animation="grow"
                      role="status"
                      variant="secondary"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  ) : (
                    snapshotEntity?.description
                  )}
                </blockquote>
              </Col>
            </Row>
            {queryErrorMessage && queryErrorMessage.length > 0 ? (
              <Row>
                <Col>
                  <Alert variant="danger" className="my-4">
                    <Alert.Heading>{queryErrorMessage}</Alert.Heading>
                    <p>{queryErrorException}</p>
                  </Alert>
                </Col>
              </Row>
            ) : null}
          </Container>
        )}
      </CollapsibleCard>

      {loading ? null : (
        <>
          <EntityMappingBaseQuery
            migrationId={migrationId}
            baseQuery={snapshotEntity?.baseQuery}
            loading={loadingEntity}
            onParseSuccess={handleParseSuccess}
            onParseFailure={handleParseFailure}
            onEditorChanged={handleEditorChange}
          />
          <CollapsibleCard
            headerText={`${snapshotEntity?.displayName} [${snapshotEntity?.name}]`}
            showPoll={false}
            options={
              <>
                <Form>
                  <Container>
                    <Row>
                      <Col className="align-self-center" sm="2">
                        <SortDown width={16} height={16} />
                      </Col>
                      <Col sm="10">
                        <Form.Select
                          disabled={loadingEntity}
                          size="sm"
                          onChange={(e) => {
                            setSortOrder(parseInt(e.currentTarget.value));
                          }}
                        >
                          <option value="1">Field Id asc</option>
                          <option value="2">Field Id desc</option>
                          <option value="3">Field Name A-Z</option>
                          <option value="4">Field Name Z-A</option>
                          <option value="5">DataType A-Z</option>
                          <option value="6">DataType Z-A</option>
                        </Form.Select>
                      </Col>
                    </Row>
                  </Container>
                </Form>
              </>
            }
          >
            <Container fluid>
              {fieldList
                ? sortList(sortOrder, fieldList)?.map((f) => (
                    <EntityMappingFieldRow
                      key={f.id}
                      fieldQuery={f}
                      queryFields={queryFields}
                      loading={loadingEntity}
                      onSelectedFieldQueryChanged={
                        handleSelectedFieldQueryChanged
                      }
                      onFieldUpdated={handleFieldUpdated}
                      onEditorExpandedToggle={handleEditorExpandedToggle}
                    />
                  ))
                : null}
            </Container>
          </CollapsibleCard>
          <Modal
            show={fieldQueryShow}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
            keyboard={false}
            onHide={() => setFieldQueryShow(false)}
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                Field Query Mapper
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <FieldQuery
                migrationId={migrationId}
                fieldQueryId={selectedFieldQueryId}
              />
            </Modal.Body>
          </Modal>
          <ConfirmDialog
            title={`Save changes?`}
            message={
              queryParsed === undefined
                ? "Query not parsed - Save changes?"
                : queryParsed === true
                ? "Save all changes to entity mapping?"
                : "Query parse failed - Save anyway?"
            }
            show={saveDialogShow}
            onHideHandler={handleSaveDialogHide}
            onOKHandler={handleSaveDialogOK}
            icon={<Save2 width={30} height={30} className="me-2" />}
          />
        </>
      )}
    </>
  );
};
export default EntityMappingFields;
