import React, { useState, useEffect } from "react";

import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
// import ButtonGroup from "react-bootstrap/ButtonGroup";
// import Dropdown from "react-bootstrap/Dropdown";

import { DateTime } from "luxon";

// import { PauseFill } from "react-bootstrap-icons";
import { ArrowClockwise, CameraFill } from "react-bootstrap-icons";

import { apiClient } from "../../index";

import useInterval from "../../hooks/UseInterval";

import { ColDef, GridApi, RowNodeTransaction } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";

import {
  MigrationRow,
  MigrationQuery,
  MigrationMutation,
  SET_SNAPSHOT_GENERATION,
  MigrationStageCount,
} from "../../API/migrationService";

import { GET_LIST_MIGRATIONS } from "../../API/migrationService";

import CollapsibleCard from "../../containers/CollapsibleCard";

import AgentNameRenderer from "../CellRenderers/AgentNameRenderer";
import SoftwareRenderer from "../CellRenderers/SoftwareRenderer";
import ProgressRenderer from "../CellRenderers/ProgressRenderer";
import DateRenderer from "../CellRenderers/DateRenderer";
import ActionRenderer from "../CellRenderers/ActionRenderer";

import { ArrowsAngleContract, ArrowsAngleExpand } from "react-bootstrap-icons";

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import Badge from "react-bootstrap/Badge";

import ConfirmDialog from "../Dialogs/ConfirmDialog";

const fieldName = (name: keyof MigrationRow) => name;

export interface IMigrationProps {
  onMigrationChange?: any;
}

const MigrationGrid: React.FC<IMigrationProps> = (props) => {
  const [gridApi, setGridApi] = useState<GridApi | undefined>(undefined);
  const [delay, setDelay] = useState<number>(3000);
  const [isPlaying, setPlaying] = useState<boolean>(false);
  const [gridExpanded, setGridExpanded] = useState<boolean>(true);
  const [rowData, setRowData] = useState<MigrationRow[] | undefined>(undefined);

  const [snapshotDialogShow, setSnapshotDialogShow] = useState<boolean>(false);
  const [snapshotMigrationId, setSnapshotMigrationId] = useState<string>("");
  const [snapshotCompanyName, setSnapshotCompanyName] = useState<string>("");

  const columnDefs: ColDef[] = [
    {
      headerName: "Company Name",
      field: fieldName("companyName"),
      cellRenderer: "agentNameRenderer",
      width: 220,
      pinned: "left",
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
      },
    },
    {
      headerName: "Software",
      field: fieldName("softwareName"),
      cellRenderer: "softwareRenderer",
      width: 120,
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
      },
    },
    {
      headerName: "Status",
      field: fieldName("ingressStatus"),
      width: 110,
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
      },
    },
    {
      headerName: "Upload Progress",
      field: fieldName("completedPercentage"),
      cellRenderer: "progressRenderer",
      filter: "agNumberColumnFilter",
      filterParams: {
        buttons: ["reset"],
      },
      minWidth: 100,
      flex: 1,
      suppressCellFlash: true,
      cellClass: () => {
        return ["p-0"];
      },
    },
    {
      headerName: "Started",
      field: fieldName("started"),
      filter: "agDateColumnFilter",
      filterParams: {
        buttons: ["reset"],
      },
      cellRenderer: "dateRenderer",
      width: 130,
    },
    {
      headerName: "Received",
      field: fieldName("lastUpdated"),
      filter: "agDateColumnFilter",
      filterParams: {
        buttons: ["reset"],
      },
      cellRenderer: "dateRenderer",
      width: 130,
    },
    {
      headerName: "User",
      field: fieldName("assignedUserName"),
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
      },
      width: 130,
    },
    {
      headerName: "",
      field: fieldName("generateSnapshotOnStable"),
      pinned: "right",
      width: 80,
      cellRenderer: "actionRenderer",
      cellRendererParams: {
        snapshotClicked: (migrationId: string, companyName: string) => {
          setSnapshotMigrationId(migrationId);
          setSnapshotCompanyName(companyName);
          setSnapshotDialogShow(true);
        },
      },
    },
  ];

  useEffect(() => {
    console.log("mounted");
    return () => {
      console.log("unmounting...");
      setGridApi(undefined);
    };
  }, []); // <-- add this empty array here

  const onGridReady = (params: {
    api: React.SetStateAction<GridApi | undefined>;
  }) => {
    setGridApi(params.api);
    LoadData();
    setDelay(5000);
    setPlaying(true);
  };

  const CompletedPercentage = (stageCount: MigrationStageCount) => {
    if (stageCount.topLevelTotal > 0) {
      if (stageCount.topLevelCompletedPercentage < 100) {
        return (
          ((stageCount.topLevelCompleted + stageCount.topLevelFailed) /
            stageCount.topLevelTotal) *
          100
        );
      } else if (stageCount.topLevelCompletedPercentage > 100) {
        return 100;
      } else {
        return (stageCount.topLevelCompleted / stageCount.topLevelTotal) * 100;
      }
    } else {
      return 0;
    }
  };

  const LoadData = () => {
    setRowData(undefined);

    apiClient
      .query<MigrationQuery>({
        query: GET_LIST_MIGRATIONS,
        fetchPolicy: "network-only",
      })
      .then((response) => {
        if (response.error) {
          console.error(response.error.message);
        } else {
          var migRows: MigrationRow[] =
            response.data.migrations_listMigrations.map((m) => ({
              id: m.id,
              companyName:
                m.companyName !== "" ? m.companyName : "UNKNOWN NAME",
              softwareName: m.software?.name ? m.software?.name : "",
              assignedUserName: m.assignedUser?.name
                ? m.assignedUser?.name
                : "Unassigned",
              started: DateTime.fromISO(m.started + "Z"),
              lastUpdated: DateTime.fromISO(m.lastUpdated + "Z"),
              ingressStatus: m.status?.ingress,
              mediaStatus: m.status?.media,
              pushStatus: m.status?.push,
              snapshotStatus: m.status?.snapshot,
              completedPercentage:
                m.entityStats.ingress.topLevelCompletedPercentage === 100
                  ? CompletedPercentage(m.entityStats.media)
                  : 100,
              ingressCompletedPercentage: CompletedPercentage(
                m.entityStats.ingress
              ),
              mediaCompletedPercentage: CompletedPercentage(
                m.entityStats.media
              ),
              pushCompletedPercentage: CompletedPercentage(m.entityStats.push),
              snapshotCompletedPercentage: CompletedPercentage(
                m.entityStats.snapshot
              ),
              generateSnapshotOnStable: m.generateSnapshotOnStable,
            }));

          setRowData(
            migRows.sort(
              (first, second) => 0 - (first.started > second.started ? 1 : -1)
            )
          );
        }
      })
      .catch((err) => console.error(err));
  };

  useInterval(
    () => {
      onPoll();
    },
    isPlaying ? delay : null
  );

  const onPoll = async () => {
    apiClient
      .query<MigrationQuery>({
        query: GET_LIST_MIGRATIONS,
        fetchPolicy: "network-only",
        errorPolicy: "all",
      })
      .then((response) => {
        var migRows: MigrationRow[] =
          response.data.migrations_listMigrations.map((m) => ({
            id: m.id,
            companyName: m.companyName !== "" ? m.companyName : "UNKNOWN NAME",
            softwareName: m.software?.name ? m.software?.name : "",
            assignedUserName: m.assignedUser?.name
              ? m.assignedUser?.name
              : "Unassigned",
            started: DateTime.fromISO(m.started + "Z"),
            lastUpdated: DateTime.fromISO(m.lastUpdated + "Z"),
            ingressStatus: m.status?.ingress,
            mediaStatus: m.status?.media,
            pushStatus: m.status?.push,
            snapshotStatus: m.status?.snapshot,
            completedPercentage: 0,
            ingressCompletedPercentage: CompletedPercentage(
              m.entityStats.ingress
            ),
            mediaCompletedPercentage: CompletedPercentage(m.entityStats.media),
            pushCompletedPercentage: CompletedPercentage(m.entityStats.push),
            snapshotCompletedPercentage: CompletedPercentage(
              m.entityStats.snapshot
            ),
            generateSnapshotOnStable: m.generateSnapshotOnStable,
          }));

        var itemsToUpdate: MigrationRow[] = [];
        var itemsToAdd: MigrationRow[] = [];

        if (gridApi) {
          migRows
            .sort(
              (first, second) => 0 - (first.started > second.started ? 1 : -1)
            )
            .forEach((e: MigrationRow) => {
              var foundMigration: boolean = false;

              gridApi?.forEachNode((rowNode, index) => {
                const migRow = rowNode?.data as MigrationRow;

                if (migRow.id === e.id) {
                  foundMigration = true;
                }

                if (
                  migRow.id === e.id &&
                  (e.lastUpdated > migRow.lastUpdated ||
                    e.ingressStatus !== migRow.ingressStatus ||
                    e.assignedUserName !== migRow.assignedUserName ||
                    e.generateSnapshotOnStable !==
                      migRow.generateSnapshotOnStable)
                ) {
                  migRow.ingressStatus = e.ingressStatus;
                  migRow.mediaStatus = e.mediaStatus;
                  migRow.pushStatus = e.pushStatus;
                  migRow.snapshotStatus = e.snapshotStatus;

                  migRow.ingressCompletedPercentage =
                    e.ingressCompletedPercentage;
                  migRow.mediaCompletedPercentage = e.mediaCompletedPercentage;
                  migRow.pushCompletedPercentage = e.pushCompletedPercentage;
                  migRow.snapshotCompletedPercentage =
                    e.snapshotCompletedPercentage;

                  if (e.ingressCompletedPercentage === 100) {
                    migRow.completedPercentage = e.mediaCompletedPercentage;
                  } else {
                    migRow.completedPercentage = e.ingressCompletedPercentage;
                  }

                  migRow.lastUpdated = e.lastUpdated;

                  migRow.assignedUserName = e.assignedUserName;

                  migRow.generateSnapshotOnStable = e.generateSnapshotOnStable;

                  itemsToUpdate.push(migRow);
                }
              });

              if (foundMigration === false) {
                itemsToAdd.push(e);
              }
            });

          var res = gridApi?.applyTransaction({
            update: itemsToUpdate,
            add: itemsToAdd,
          });
          if (res) {
            //printResult(res);
          }
        }
      })
      .catch((err) => console.error(err));
  };

  const mutationSetSnapshotGeneration = (
    migrationId: string,
    generateSnapshotOnStable: boolean
  ) => {
    apiClient
      .mutate<MigrationMutation>({
        mutation: SET_SNAPSHOT_GENERATION,
        variables: {
          migrationId: migrationId,
          generateSnapshotOnStable: generateSnapshotOnStable,
        },
        errorPolicy: "all",
      })
      .then((response) => {
        var rowNode = gridApi?.getRowNode(snapshotMigrationId);
        rowNode?.setDataValue(
          "generateSnapshotOnStable",
          response.data?.migrations_setSnapshotGeneration
            .generateSnapshotOnStable
        );
      })
      .catch((err) => console.error(err));
  };

  // eslint-disable-next-line
  const printResult = (res: RowNodeTransaction) => {
    console.log("---------------------------------------");
    if (res.add) {
      res.add.forEach((rowNode: any) => {
        console.log("Added Row Node", rowNode);
      });
    }
    if (res.remove) {
      res.remove.forEach((rowNode: any) => {
        console.log("Removed Row Node", rowNode);
      });
    }
    if (res.update) {
      res.update.forEach((rowNode: any) => {
        console.log("Updated Row Node", rowNode);
      });
    }
  };

  const onRefreshButtonClick = async () => {
    LoadData();
  };

  const onSelectionChanged = () => {
    if (gridApi) {
      let rowsSelection = gridApi?.getSelectedRows() as MigrationRow[];
      if (props.onMigrationChange) {
        props.onMigrationChange(rowsSelection[0].id);
      }
    } else {
      console.log("not got the api yet :(");
    }
  };

  const handleDialogHide = () => {
    setSnapshotDialogShow(false);
  };

  const handleDialogOK = () => {
    setSnapshotDialogShow(false);
    mutationSetSnapshotGeneration(snapshotMigrationId, true);
  };

  const handlePollChange = (newValue: boolean) => {
    setPlaying(newValue);
  };

  const gridOptions = {
    getRowNodeId: (data: { id: any }) => {
      return data.id;
    },
  };

  return (
    <>
      <CollapsibleCard
        headerText="Incoming Migrations"
        onPollChange={handlePollChange}
        showPoll={true}
        badges={
          <>
            <Badge className="me-1 bg-primary">{/* {rowData?.length} */}</Badge>
          </>
        }
        options={
          <>
            {/* <Dropdown
              size="sm"
              as={ButtonGroup}
              menualign="right"
              className="me-1"
            >
              <Button size="sm" variant="danger">
                <PauseFill className="me-1" />
                Pause All
              </Button>

              <Dropdown.Toggle
                split
                variant="danger"
                id="dropdown-split-basic"
              />

              <Dropdown.Menu align="right">
                <Dropdown.Item>
                  <img
                    className="me-1"
                    src={"../images/encorelive.png"}
                    alt="Pause Encorelive"
                  />
                  EncoreLive
                </Dropdown.Item>
                <Dropdown.Item>
                  <img
                    className="me-1"
                    src={"../images/vebralive.png"}
                    alt="Pause Vebralive"
                  />
                  VebraLive
                </Dropdown.Item>
                <Dropdown.Item>
                  <img
                    className="me-1"
                    src={"../images/expertagent.png"}
                    alt="Pause Expert Agent"
                  />
                  Expert Agent
                </Dropdown.Item>
                <Dropdown.Item>
                  <img
                    className="me-1"
                    src={"../images/jupix.png"}
                    alt="Pause Jupix"
                  />
                  Jupix
                </Dropdown.Item>
                <Dropdown.Item>
                  <img
                    className="me-1"
                    src={"../images/winman.png"}
                    alt="Pause Winman"
                  />
                  Winman
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item>
                  <img
                    className="me-1"
                    src={"../images/dezrez.png"}
                    alt="Pause Dezrez"
                  />
                  Dezrez
                </Dropdown.Item>
                <Dropdown.Item>
                  <img
                    className="me-1"
                    src={"../images/reapit.png"}
                    alt="Pause Reapit"
                  />
                  Reapit
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item>Other</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown> */}

            <OverlayTrigger
              placement="left"
              overlay={
                <Tooltip id="tooltip-grid">
                  {gridExpanded === true ? "Contract Grid" : "Expand Grid"}
                </Tooltip>
              }
            >
              <Button
                variant="light"
                className="me-2"
                size="sm"
                onClick={() => setGridExpanded(!gridExpanded)}
              >
                {gridExpanded === true ? (
                  <ArrowsAngleContract />
                ) : (
                  <ArrowsAngleExpand />
                )}
              </Button>
            </OverlayTrigger>

            <OverlayTrigger
              placement="left"
              overlay={<Tooltip id="tooltip-grid">Refresh Grid</Tooltip>}
            >
              <Button
                size="sm"
                variant="success"
                className="me-1"
                onClick={onRefreshButtonClick}
              >
                <ArrowClockwise className="me-1" />
                Refresh
              </Button>
            </OverlayTrigger>
          </>
        }
      >
        <div
          className="ag-theme-alpine"
          style={{ height: gridExpanded ? "75vh" : "550px" }}
        >
          <AgGridReact
            gridOptions={gridOptions}
            defaultColDef={{
              sortable: true,
              width: 180,
              editable: false,
              flex: 0,
            }}
            columnDefs={columnDefs}
            frameworkComponents={{
              agentNameRenderer: AgentNameRenderer,
              softwareRenderer: SoftwareRenderer,
              progressRenderer: ProgressRenderer,
              dateRenderer: DateRenderer,
              actionRenderer: ActionRenderer,
            }}
            rowData={rowData}
            rowSelection="multiple"
            animateRows={true}
            rowDragManaged={true}
            suppressRowClickSelection={false}
            onGridReady={onGridReady}
            onSelectionChanged={onSelectionChanged}
            enableCellChangeFlash={true}
            onFirstDataRendered={() => {
              // gridApi?.selectIndex(0, false, false)
              gridApi?.forEachNode((node) =>
                node.setSelected(node.rowIndex === 0)
              );
            }}
          />
        </div>
      </CollapsibleCard>
      <ConfirmDialog
        title={`Queue Snapshot for ${snapshotCompanyName}?`}
        message={
          "Set the migration to generate a snapshot on reaching stable. \n Take snapshot with existing stable data? / Take snapshot when data supply is stable?"
        }
        show={snapshotDialogShow}
        onHideHandler={handleDialogHide}
        onOKHandler={handleDialogOK}
        icon={<CameraFill width={30} height={30} className="me-2" />}
      />
    </>
  );
};

export default MigrationGrid;
