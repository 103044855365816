import React, { useState, useEffect } from "react";

import { useHistory } from "react-router-dom";

import { apiClient } from "../index";

import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import Spinner from "react-bootstrap/Spinner";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Popover from "react-bootstrap/Popover";
import Button from "react-bootstrap/Button";

import CollapsibleCard from "../containers/CollapsibleCard";

import EntityDataViewer from "./EntityDataViewer";

import Editor from "@monaco-editor/react";

import {
  ArrowsAngleContract,
  ArrowsAngleExpand,
  ArrowReturnLeft,
  Files,
  ArrowClockwise,
} from "react-bootstrap-icons";

import {
  LIST_ENTITY_QUERIES_FOR_MIGRATION_LITE,
  PREVIEW_ENTITY_QUERY,
  PreviewEntityQuery,
  SnapshotEntityQuery,
  EntityQueriesQuery,
} from "../API/entityQueryService";

type EditorState = {
  text: string;
  copied: boolean;
};

export type EntityRawPreviewProps = {
  migrationId: string;
  entityName: string;
};

const EntityRawPreview: React.FunctionComponent<EntityRawPreviewProps> = ({
  migrationId,
  entityName,
}) => {
  let history = useHistory();

  const [renderCount, setRenderCount] = useState<number>(0);

  const [loading, setLoading] = useState<boolean>(true);

  const [generatingFullQuery, setGeneratingFullQuery] =
    useState<boolean>(false);

  const [snapshotEntity, setSnapshotEntity] = useState<
    SnapshotEntityQuery | undefined
  >(undefined);

  const [entityQueryList, setEntityQueryList] = useState<
    SnapshotEntityQuery[] | undefined
  >(undefined);

  const [editorExpanded, setEditorExpanded] = useState<boolean>(false);
  const [editorWordWrap, setEditorWordWrap] = useState<boolean>(false);

  const [editorState, setEditorState] = useState<EditorState>({
    copied: false,
    text: "",
  });

  const [sqlQuery, setSqlQuery] = useState<string>("");

  const [pageIndex, setPageIndex] = useState<number>(0);

  const handlePageChanged = (value: number) => {
    setPageIndex(value);
  };

  useEffect(() => {
    const getGeneratedFullQuery = (entityQueryId: number) => {
      setGeneratingFullQuery(true);
      setRenderCount(0);

      apiClient
        .query<PreviewEntityQuery>({
          query: PREVIEW_ENTITY_QUERY,
          variables: {
            entityQueryId: entityQueryId,
            migrationId: migrationId
          },
          errorPolicy: "all",
          fetchPolicy: "network-only",
        })
        .then((response) => {
          if (response.errors) {
            const errorMessage = response.errors[0].message;
            console.error(errorMessage);

            if (response.errors[0].extensions) {
              console.error(response.errors[0].extensions);
            }

            setEditorState({
              copied: false,
              text: "",
            });
            setSqlQuery("");

            setGeneratingFullQuery(false);

          } else {
            if (response.data.entityQueries_previewEntityQuery) {
              setEditorState({
                copied: false,
                text: response.data.entityQueries_previewEntityQuery,
              });
              setSqlQuery(
                response.data.entityQueries_previewEntityQuery
              );
            } else {
              setEditorState({
                copied: false,
                text: "",
              });
              setSqlQuery("");
            }

            setGeneratingFullQuery(false);
          }
        })
        .catch((err: any) => {
          setEditorState({
            copied: false,
            text: "",
          });
          setSqlQuery("");

          console.error(err);
          setGeneratingFullQuery(false);
        });
    };

    const listEntityQueriesEntityName = (
      migrationId: string,
      entityName: string
    ) => {
      apiClient
        .query<EntityQueriesQuery>({
          query: LIST_ENTITY_QUERIES_FOR_MIGRATION_LITE,
          variables: {
            migrationId: migrationId,
          },
          errorPolicy: "all",
        })
        .then((response) => {
          if (response.errors) {
            const errorMessage = response.errors[0].message;
            console.error(errorMessage);

            if (response.errors[0].extensions) {
              console.error(response.errors[0].extensions);
            }

            setLoading(false);
          } else {
            let filtered =
              response.data.entityQueries_listQueriesForMigration.filter(
                (x) => x.name === entityName
              );

            if (filtered && filtered.length > 0) {
              let matchingId: number = filtered[0].id;
              console.log("EntityId", matchingId);

              setSnapshotEntity(filtered[0]);

              if (filtered[0].baseQuery.length > 0) {
                getGeneratedFullQuery(matchingId);
              } else {
                setEditorState({
                  copied: false,
                  text: "",
                });
                setSqlQuery("");
              }
            }

            setEntityQueryList(
              response.data.entityQueries_listQueriesForMigration
            );

            setLoading(false);
          }
        })
        .catch((err: any) => {
          console.error(err);
          setLoading(false);
        });
    };

    listEntityQueriesEntityName(migrationId, entityName);
  }, [migrationId, entityName, renderCount]);

  function handleEditorChange(value: any, event: any) {
    setEditorState({
      copied: false,
      text: value,
    });
  }

  function handleCopyButton(event: any) {
    navigator.clipboard.writeText(editorState.text);

    setEditorState({
      ...editorState,
      copied: true,
    });
  }

  return (
    <>
      <CollapsibleCard showPoll={false} showHeader={false}>
        {loading ? (
          <Spinner animation="border" role="status" variant="secondary">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        ) : (
          <Container fluid>
            <Row>
              <Col xl="10">
                <Form>
                  <Form.Select
                    size="lg"
                    value={entityName}
                    onChange={(e) => {
                      setPageIndex(0);
                      setSqlQuery("");
                      history.push(
                        "/migration/" +
                          migrationId +
                          "/preview/" +
                          e.currentTarget.value
                      );
                    }}
                  >
                    {entityQueryList
                      ?.slice()
                      .sort(
                        (first, second) =>
                          0 - (first.name > second.name ? -1 : 1)
                      )
                      .map((el) => (
                        <option key={el.id} value={el.name}>
                          {el.displayName}
                        </option>
                      ))}
                  </Form.Select>
                </Form>
              </Col>
              <Col>
                <OverlayTrigger
                  trigger={["hover", "focus"]}
                  placement="bottom"
                  overlay={
                    <Popover id="popover-select">
                      <Popover.Header as="h3">Refresh query</Popover.Header>
                      <Popover.Body>
                        Fetches the query for the selected entity and displays
                        the data in the "Results" grid
                      </Popover.Body>
                    </Popover>
                  }
                >
                  <Button
                    disabled={generatingFullQuery}
                    style={{ width: 110 }}
                    variant={"success"}
                    className="m-1"
                    onClick={() => {
                      setEditorState({
                        copied: false,
                        text: "",
                      });
                      setSqlQuery("");
                      setPageIndex(0);
                      setRenderCount((renderCount) => renderCount + 1);
                    }}
                  >
                    {loading ? (
                      <>
                        <Spinner
                          size="sm"
                          animation="border"
                          role="status"
                          className="me-1"
                        >
                          <span className="visually-hidden">Loading...</span>
                        </Spinner>
                        Refresh
                      </>
                    ) : (
                      <>
                        <ArrowClockwise width={20} height={20} /> Refresh
                      </>
                    )}
                  </Button>
                </OverlayTrigger>
              </Col>
            </Row>
          </Container>
        )}
      </CollapsibleCard>

      <CollapsibleCard
        headerText={
          loading
            ? "loading..."
            : "Full Generated Query for " + snapshotEntity?.displayName
        }
      >
        {loading ? (
          <Spinner animation="border" role="status" variant="secondary">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        ) : (
          <>
            <Container fluid>
              <Row>
                <Col>
                  <OverlayTrigger
                    placement="left"
                    overlay={
                      <Tooltip id="tooltip-undo">
                        {editorExpanded === true
                          ? "Contract Editor"
                          : "Expand Editor"}
                      </Tooltip>
                    }
                  >
                    <Button
                      disabled={loading}
                      variant="light"
                      className="ms-1 float-end"
                      size="sm"
                      onClick={() => setEditorExpanded(!editorExpanded)}
                    >
                      {editorExpanded === true ? (
                        <ArrowsAngleContract />
                      ) : (
                        <ArrowsAngleExpand />
                      )}
                    </Button>
                  </OverlayTrigger>
                  <OverlayTrigger
                    placement="left"
                    overlay={
                      <Tooltip id="tooltip-wordwrap">
                        {editorWordWrap === true
                          ? "Disable WordWrap"
                          : "Enable WordWrap"}
                      </Tooltip>
                    }
                  >
                    <Button
                      disabled={loading}
                      variant={editorWordWrap === true ? "info" : "light"}
                      className="ms-1 float-end"
                      size="sm"
                      onClick={() => setEditorWordWrap(!editorWordWrap)}
                    >
                      <ArrowReturnLeft />
                    </Button>
                  </OverlayTrigger>
                  {navigator.clipboard !== undefined ? (
                    <OverlayTrigger
                      placement="left"
                      overlay={
                        <Tooltip id="tooltip-undo">
                          {editorState.copied === true ? "Copied" : "Copy"}
                        </Tooltip>
                      }
                    >
                      <Button
                        disabled={generatingFullQuery}
                        variant="light"
                        className="ms-1 float-end"
                        size="sm"
                        onClick={handleCopyButton}
                      >
                        <Files />
                      </Button>
                    </OverlayTrigger>
                  ) : (
                    <></>
                  )}

                  <>
                    <Editor
                      className="border border-secondary py-3"
                      height={editorExpanded ? "75vh" : "300px"}
                      language="sql"
                      defaultValue=""
                      value={editorState.text}
                      onChange={handleEditorChange}
                      options={{
                        readOnly: true,
                        wordWrap: editorWordWrap === true ? "on" : "off",
                      }}
                    ></Editor>
                  </>
                </Col>
              </Row>
            </Container>
          </>
        )}
      </CollapsibleCard>

      <EntityDataViewer
        migrationId={migrationId}
        sqlQuery={sqlQuery}
        pageIndex={pageIndex}
        onPageChanged={handlePageChanged}
      />
    </>
  );
};
export default EntityRawPreview;
