import React from "react";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Transformations from "./Transformations";
import TransformationById from "./TransformationById";
import CreateTransformation from "./CreateTransformation";

const TransformationHome: React.FC = () => {
  return (
    <Container fluid>
      <Row>
        <Col>
          <h2>Transformation</h2>
        </Col>
      </Row>
      <Row className="my-5">
        <Col>
          <Transformations />
        </Col>
        <Col>
          <TransformationById transformationId={1} />
        </Col>
      </Row>
      <Row className="my-5">
        <Col>
          <CreateTransformation />
        </Col>
      </Row>
    </Container>
  );
};

export default TransformationHome;
