import { gql } from "@apollo/client";

export type EntityTemplatesQuery = {
  entityTemplates_findEntityTemplateGroup: SnapshotEntityTemplateGroup;
  entityTemplates_listEntityTemplateGroups: SnapshotEntityTemplateGroup[];
};

export type EntityTemplatesMutation = {
  entityTemplates_assignToMigration: AssignSnapshotEntityTemplateGroupResponse;
  entityTemplates_importEntityTemplateGroup: SnapshotEntityTemplateGroup;
};

export type SnapshotEntityTemplateGroup = {
  id: number;
  name: string;
  entityTemplates: SnapshotEntityTemplate[];
};

export type SnapshotEntityTemplate = {
  id: number;
  baseQuery: string;
  entityDefinitionId: number;
  entityTemplateGroupId: number;
  definition: SnapshotEntityDefinition;
  fieldTemplates: SnapshotFieldTemplate[];
};

export type SnapshotEntityDefinition = {
  id: number;
  name: string;
  displayName: string;
  description: string;
  fields: SnapshotFieldDefinition[];
};

export type SnapshotFieldDefinition = {
  id: number;
  isPrimaryKey: string;
  isNullable: string;
  isForeignKey: boolean;
  entityDefinitionId: number;
  name: string;
  displayName: string;
  description: string;
  dataType: string;
  allowedValues?: SnapshotFieldDefinitionAllowedValue[];
  restrictions?: SnapshotFieldDefinitionRestriction[];
  foreignKeyField?: SnapshotFieldDefinition;
};

export type SnapshotFieldDefinitionAllowedValue = {
  id: number;
  fieldDefinitionId: number;
  value: string;
};

export type SnapshotFieldDefinitionRestriction = {
  fieldRestriction: SnapshotFieldRestriction;
  arguments: string;
};

export type SnapshotFieldRestriction = {
  id: number;
  displayName: string;
  errorFormat: string;
  whereStatement: string;
};

export type SnapshotFieldTemplate = {
  id: number;
  entityTemplateId: number;
  fieldDefinitionId: number;
  definition: SnapshotFieldDefinition;
  filters: SnapshotFieldFilter[];
  mappedValues: SnapshotFieldTemplateMappedValue[];
};

export type SnapshotFieldFilter = {
  id: number;
  displayName: string;
  selectStatement: string;
};

export type SnapshotFieldTemplateMappedValue = {
  id: number;
  fieldDefinitionAllowedValueId: number;
  fieldTemplateId: number;
  sourceValue: string;
};

export type AssignSnapshotEntityTemplateGroupResponse = {
  missedEntities: MissedEntityDefinition[];
};

export type MissedEntityDefinition = {
  entityDefinitionId: number;
  isEntireEntityMissing: boolean;
  missingFieldDefinitionIds: number[];
  entity: SnapshotEntityDefinition;
  fields: SnapshotFieldDefinition[];
};

export type ImportSnapshotEntityTemplateGroupInput = {
  groupName: string;
  entityTemplates: EntityTemplateItemInput[];
};

export type EntityTemplateItemInput = {
  entityDefinitionId: number;
  baseQuery: string;
  fieldTemplates: FieldTemplateItemInput[];
};

export type FieldTemplateItemInput = {
  fieldDefinitionId: number;
  selectStatement: string;
  fieldFilterIds: number[];
  mappedValues: FieldMappedValueItemInput[];
};

export type FieldMappedValueItemInput = {
  sourceValue: string;
  fieldDefinitionAllowedValueId: number;
};

export type AssignSnapshotEntityTemplateGroupInput = {
  entityTemplateGroupId: number;
  migrationId: string;
};

export const FIND_ENTITY_TEMPLATE_GROUP = gql`
  query entityTemplates_findEntityTemplateGroup(
    $entityTemplateGroupId: number
  ) {
    entityTemplates_findEntityTemplateGroup(
      entityTemplateGroupId: $entityTemplateGroupId
    ) {
      id
      name
      entityTemplates {
        id
        baseQuery
        entityDefinitionId
        entityTemplateGroupId
        definition {
          id
          name
          displayName
          description
          fields {
            id
            isPrimaryKey
            isNullable
            isForeignKey
            entityDefinitionId
            name
            displayName
            description
            dataType
            allowedValues {
              id
              fieldDefinitionId
              value
            }
            restrictions {
              fieldRestriction {
                id
                displayName
                errorFormat
                whereStatement
              }
              arguments
            }
            foreignKeyField {
              id
              name
            }
          }
        }
      }
    }
  }
`;

export const LIST_ENTITY_TEMPLATE_GROUPS = gql`
  query entityTemplates_listEntityTemplateGroups {
    entityTemplates_listEntityTemplateGroups {
      id
      name
      entityTemplates {
        id
        baseQuery
        entityDefinitionId
        entityTemplateGroupId
        definition {
          id
          name
          displayName
          description
          fields {
            id
            isPrimaryKey
            isNullable
            isForeignKey
            entityDefinitionId
            name
            displayName
            description
            dataType
            allowedValues {
              id
              fieldDefinitionId
              value
            }
            restrictions {
              fieldRestriction {
                id
                displayName
                errorFormat
                whereStatement
              }
              arguments
            }
            foreignKeyField {
              id
              name
            }
          }
          filters {
            id
            displayName
            selectStatement
          }
          mappedValues {
            id
            fieldDefinitionAllowedValueId
            fieldTemplateId
            sourceValue
          }
        }
      }
    }
  }
`;

export const LIST_ENTITY_TEMPLATE_GROUPS_LITE = gql`
  query entityTemplates_listEntityTemplateGroups {
    entityTemplates_listEntityTemplateGroups {
      id
      name
      entityTemplates {
        id
      }
    }
  }
`;

export const ASSIGN_ENTITY_TEMPLATE_TO_MIGRATION = gql`
  mutation entityTemplates_assignToMigration(
    $request: AssignSnapshotEntityTemplateGroupInput
  ) {
    entityTemplates_assignToMigration(request: $request) {
      missedEntities {
        entityDefinitionId
      }
    }
  }
`;

export const IMPORT_ENTITY_TEMPLATE_GROUP = gql`
  mutation entityTemplates_importEntityTemplateGroup(
    $request: ImportSnapshotEntityTemplateGroupInput
  ) {
    entityTemplates_importEntityTemplateGroup(request: $request) {
      id
      name
      entityTemplates {
        id
        baseQuery
        entityDefinitionId
        entityTemplateGroupId
        definition {
          id
          name
          displayName
          description
          fields {
            id
          }
        }
        fieldTemplates {
          id
          entityTemplateId
          fieldDefinitionId
          definition {
            id
            isPrimaryKey
            isNullable
            isForeignKey
            entityDefinitionId
            name
            displayName
            description
            dataType
            allowedValues {
              id
              fieldDefinitionId
              value
            }
            restrictions {
              fieldRestriction {
                id
                displayName
                errorFormat
                whereStatement
              }
              arguments
            }
            foreignKeyField {
              id
              name
            }
          }
          filters {
            id
            displayName
            selectStatement
          }
          mappedValues {
            id
            fieldDefinitionAllowedValueId
            fieldTemplateId
            sourceValue
          }
        }
      }
    }
  }
`;
