import React from "react";

export type SoftwareIconProps = {
  softwareName: string;
  showName: boolean;
};

const SoftwareIcon: React.FunctionComponent<SoftwareIconProps> = ({
  softwareName,
  showName,
}) => {
  function SoftwareFormatter(value: string) {
    if (value.toLowerCase() === "unknown software") {
      value = "Unknown";
    } else if (value.toLowerCase().includes("vebralive")) {
      value = value.toLowerCase().replace("vebralive", "VebraLive");
    } else if (value.toLowerCase().includes("encorelive")) {
      value = value.toLowerCase().replace("encorelive", "EncoreLive");
    }

    return value;
  }

  return (
    <>
      <img
        className="me-1"
        src={
          `${process.env.PUBLIC_URL}/images/` +
          SoftwareFormatter(softwareName).toLowerCase() +
          ".png"
        }
        alt={SoftwareFormatter(softwareName)}
      />
      {showName ? (
        <span>{SoftwareFormatter(softwareName).replace("_media", "")}</span>
      ) : null}
    </>
  );
};

export default SoftwareIcon;
