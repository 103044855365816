import React from "react";

import { useQuery } from "@apollo/client";

import {
  LIST_TRANSFORMATIONS,
  RemappingQuery,
} from "../../../API/remappingService";

const Transformations: React.FC = () => {
  const { data, error, loading } = useQuery<RemappingQuery>(
    LIST_TRANSFORMATIONS,
    {
      pollInterval: 0,
      errorPolicy: "all",
    }
  );
  if (error) {
    console.log(error.message);
  }

  return loading ? (
    <p>loading</p>
  ) : (
    <div>
      <h4>transformations</h4>
      {data?.remapping_listTransformations?.map((t) => (
        <div key={t.id}>
          <pre>
            <b>id: </b>
            {t.id}
          </pre>
          <p>
            {t.name} | {t.description}
          </p>
        </div>
      ))}
    </div>
  );
};

export default Transformations;
