import { gql } from "@apollo/client";

export type DatabasesQuery = {
  databases_executeSelectQuery: ResultSet;
  databases_executeSelectQueryFromSnapshot: ResultSet;
  databases_explainSelectQuery: TableFieldDefinition[];
  databases_explainSelectEntityQuery: ExplainSelectTable[];
  databases_getRawDatabaseSchema: RawDatabaseSchema;
  databases_getSnapshotDatabaseSchema: DatabaseSchema[];
  databases_sampleRawEntity: ResultSet;
  databases_getValueOccurrences: ValueOccurrence[];
  databases_executeSelectQueryFromStucturedView: ResultSet;
};

export type EntityFieldRow = {
  fieldName: string;
  dataType?: string;
  recordA?: string;
  recordB?: string;
  recordC?: string;
  recordD?: string;
  recordE?: string;
  recordF?: string;
  recordG?: string;
  recordH?: string;
  recordI?: string;
  recordJ?: string;
  recordK?: string;
  recordL?: string;
  recordM?: string;
  recordN?: string;
  recordO?: string;
  recordP?: string;
  recordQ?: string;
  recordR?: string;
  recordS?: string;
  recordT?: string;
  hasSQLMapping: boolean;
};

export type RawDatabaseSchema = {
  migrationId: string;
  tables: RawDatabaseTable[];
};

export type RawDatabaseTable = {
  displayName: string;
  entityName: string;
  isSnapshotTable: boolean;
  rawTableName: string;
  fields: RawDatabaseField[];
};

export type RawDatabaseField = {
  name: string;
  isSnapshotField: boolean;
  snapshotDatabaseType: string;
};

export type DatabaseSchema = {
  name: string;
  tables: DatabaseTable[];
};

export type DatabaseTable = {
  name: string;
  fields: DatabaseField[];
};

export type DatabaseField = {
  foreignKeyField: string;
  foreignKeyReferences: ForeignKeyReference[];
  foreignKeyTable: string;
  isForeignKey: boolean;
  isNullable: boolean;
  isPrimaryKey: boolean;
  isUnique: boolean;
  name: string;
  type: string;
};

export type ForeignKeyReference = {
  field: string;
  table: string;
};

export type ResultSet = {
  records: Record[];
};

export type Record = {
  fields: RecordField[];
};

export type RecordField = {
  name: string;
  value: string;
};

export type TableFieldDefinition = {
  name: string;
  dataType: string;
  isNullable: boolean;
  isPrimaryKey: boolean;
};

export type ExplainSelectTable = {
  tableAlias: string;
  tableName: string;
  fields: ExplainSelectField[];
};

export type ExplainSelectField = {
  dataType: string;
  name: string;
  selectStatement: string;
};

export type ErrorExtensions = {
  code: string;
  codes: string[];
  data: ErrorException;
};

export type ErrorException = {
  exception: ErrorExceptionContent;
};

export type ErrorExceptionContent = {
  type: string;
  message: string;
  trace: string;
};

export type ValueOccurrence = {
  value: any;
  count: number;
};

export const GET_RAW_DATABASE_SCHEMA = gql`
  query databases_getRawDatabaseSchema($migrationId: Guid) {
    databases_getRawDatabaseSchema(migrationId: $migrationId) {
      migrationId
      tables {
        displayName
        entityName
        rawTableName
        isSnapshotTable
        fields {
          name
          snapshotDatabaseType
          isSnapshotField
        }
      }
    }
  }
`;

export const GET_SNAPSHOT_DATABASE_SCHEMA = gql`
  query databases_getSnapshotDatabaseSchema {
    databases_getSnapshotDatabaseSchema {
      name
      tables {
        name
        fields {
          name
          type
          isUnique
          isNullable
          isForeignKey
          isPrimaryKey
          foreignKeyField
          foreignKeyTable
          foreignKeyReferences {
            field
            table
          }
        }
      }
    }
  }
`;

export const GET_SAMPLE_RAW_ENTITY = gql`
  query databases_sampleRawEntity(
    $migrationId: Guid
    $entityName: String
    $limit: UInt
  ) {
    databases_sampleRawEntity(
      migrationId: $migrationId
      entityName: $entityName
      limit: $limit
    ) {
      records {
        fields {
          name
          value
        }
      }
    }
  }
`;

export const GET_EXPLAIN_SELECT_QUERY = gql`
  query databases_explainSelectQuery($query: String, $migrationId: Guid) {
    databases_explainSelectQuery(query: $query, migrationId: $migrationId) {
      name
      dataType
      isNullable
      isPrimaryKey
    }
  }
`;

export const GET_EXECUTE_SELECT_QUERY = gql`
  query databases_executeSelectQuery($query: String, $migrationId: Guid) {
    databases_executeSelectQuery(query: $query, migrationId: $migrationId) {
      records {
        fields {
          name
          value
        }
      }
    }
  }
`;

export const GET_EXPLAIN_SELECT_ENTITY_QUERY = gql`
  query databases_explainSelectEntityQuery($query: String, $migrationId: Guid) {
    databases_explainSelectEntityQuery(
      query: $query
      migrationId: $migrationId
    ) {
      tableAlias
      tableName
      fields {
        dataType
        name
        selectStatement
      }
    }
  }
`;

export const GET_VALUE_OCCURRENCES = gql`
  query databases_getValueOccurrences($migrationId: Guid, $fieldQueryId: Long) {
    databases_getValueOccurrences(
      migrationId: $migrationId
      fieldQueryId: $fieldQueryId
    ) {
      value
      count
    }
  }
`;

export const GET_EXECUTE_SELECT_QUERY_FROM_SNAPSHOT = gql`
  query databases_executeSelectQueryFromSnapshot(
    $migrationId: Guid
    $snapshotExecutionId: Long
    $query: String
    $offset: UInt
    $limit: UInt
  ) {
    databases_executeSelectQueryFromSnapshot(
      migrationId: $migrationId
      snapshotExecutionId: $snapshotExecutionId
      query: $query
      offset: $offset
      limit: $limit
    ) {
      records {
        fields {
          name
          value
        }
      }
    }
  }
`;

export const GET_EXECUTE_SELECT_QUERY_FROM_STRUCTURED_VIEW = gql`
  query databases_executeSelectQueryFromStucturedView(
    $migrationId: Guid
    $query: String
    $offset: UInt
    $limit: UInt
  ) {
    databases_executeSelectQueryFromStucturedView(
      migrationId: $migrationId
      query: $query
      offset: $offset
      limit: $limit
    ) {
      records {
        fields {
          name
          value
        }
      }
    }
  }
`;
