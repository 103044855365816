import React, { useState, useEffect } from "react";

import { apiClient } from "../index";

import {
  GET_RAW_DATABASE_SCHEMA,
  GET_EXPLAIN_SELECT_QUERY,
  GET_EXECUTE_SELECT_QUERY,
  DatabasesQuery,
  ErrorExtensions,
  TableFieldDefinition,
  RawDatabaseField,
  ResultSet,
} from "../API/databaseService";

import {
  CREATE_SQL_MAPPING,
  UPDATE_SQL_MAPPING,
  DELETE_MAPPING,
  SqlMapping,
  SqlUpdateField,
  RemappingMutation,
} from "../API/remappingService";

import Editor from "@monaco-editor/react";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Spinner from "react-bootstrap/Spinner";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";

import {
  Check2,
  ChevronUp,
  FileEarmarkMinus,
  FileEarmarkPlus,
  Play,
  Save,
  Trash,
} from "react-bootstrap-icons";

import SelectQuery from "../components/SelectQuery";

export type SQLMappingPaneProps = {
  onMappingChange?: any;
  migrationId: string;
  entityName: string;
  mappingId: number;
  mappedTableId: number;
  sqlMapping: SqlMapping;
};

const SQLMappingPane: React.FunctionComponent<SQLMappingPaneProps> = ({
  onMappingChange,
  migrationId,
  entityName,
  mappingId,
  mappedTableId,
  sqlMapping,
}) => {
  const [loading, setLoading] = useState<boolean>(true);

  const [loadingParse, setLoadingParse] = useState<boolean>(false);
  const [loadingQuery, setLoadingQuery] = useState<boolean>(false);

  const [editorDirty, setEditorDirty] = useState<boolean>(false);
  // eslint-disable-next-line
  const [formDirty, setFormDirty] = useState<boolean>(false);
  const [parseSuccess, setParseSuccess] = useState<boolean>(false);
  const [entityFields, setEntityFields] = useState<
    RawDatabaseField[] | undefined
  >(undefined);
  const [queryFields, setQueryFields] = useState<
    TableFieldDefinition[] | undefined
  >(undefined);
  const [queryErrorMessage, setQueryErrorMessage] = useState<string>("");
  const [queryErrorException, setQueryErrorException] = useState<string>("");
  const [newSqlUpdateField, setNewSqlUpdateField] = useState<SqlUpdateField>({
    queryField: "",
    rawUpdateField: "",
  });

  const [resultSet, setResultSet] = useState<ResultSet | undefined>(undefined);

  const [sqlMappingState, setSqlMappingState] =
    useState<SqlMapping>(sqlMapping);

  const resetInput = () => {
    setNewSqlUpdateField({ queryField: "", rawUpdateField: "" });
  };

  useEffect(() => {
    getDBSchema(migrationId, entityName);
    parseSQLQuery(migrationId, sqlMapping.query);
    setLoading(false);
  }, [migrationId, entityName, sqlMapping.query]);

  const parseSQLQuery = (migrationId: string, query: string) => {
    if (query !== "") {
      setLoadingParse(true);

      apiClient
        .query<DatabasesQuery>({
          query: GET_EXPLAIN_SELECT_QUERY,
          variables: {
            query: query,
            migrationId: migrationId,
          },
          fetchPolicy: "network-only",
          errorPolicy: "all",
        })
        .then((response) => {
          if (response.errors) {
            setEditorDirty(false);
            setParseSuccess(false);
            setResultSet(undefined);
            const errorMessage = response.errors[0].message;
            setQueryErrorMessage(errorMessage);
            if (response.errors[0].extensions) {
              const ext: ErrorExtensions = response.errors[0]
                .extensions as ErrorExtensions;
              setQueryErrorException(ext.data.exception.message);
            }

            setLoadingParse(false);
          } else {
            setEditorDirty(false);
            setParseSuccess(true);
            setQueryErrorMessage("");
            setQueryErrorException("");
            setQueryFields(response.data.databases_explainSelectQuery);
            setLoadingParse(false);
          }
        })
        .catch((err) => console.error(err));
    } else {
      setEditorDirty(false);
      setParseSuccess(false);
      setQueryErrorMessage("Type a select query");
      setQueryErrorException("");
      setResultSet(undefined);
      setLoadingParse(false);
    }
  };

  // eslint-disable-next-line
  const executeSelectQuery = (
    migrationId: string,
    query: string,
    limit: number
  ) => {
    if (query !== "") {
      setLoadingQuery(true);

      query = query + " LIMIT " + limit;

      console.log(query);

      apiClient
        .query<DatabasesQuery>({
          query: GET_EXECUTE_SELECT_QUERY,
          variables: {
            query: query,
            migrationId: migrationId,
          },
          fetchPolicy: "network-only",
          errorPolicy: "all",
        })
        .then((response) => {
          if (response.errors) {
            console.log(response.errors);
            setLoadingQuery(false);
          } else {
            console.log(response.data.databases_executeSelectQuery);
            setResultSet(response.data.databases_executeSelectQuery);
            setLoadingQuery(false);
          }
        })
        .catch((err) => {
          console.error(err);
          setLoadingQuery(false);
        });
    }
  };

  const getDBSchema = (migrationId: string, entityName: string) => {
    apiClient
      .query<DatabasesQuery>({
        query: GET_RAW_DATABASE_SCHEMA,
        variables: {
          migrationId: migrationId,
        },
        errorPolicy: "all",
      })
      .then((response) => {
        if (response.errors) {
          console.error(response.errors[0].message);
        } else {
          var dbSchema = response.data?.databases_getRawDatabaseSchema;

          var ef = dbSchema?.tables
            .filter((t) => t.entityName === entityName)[0]
            .fields.slice();

          ef?.forEach((item, index) => {
            if (item.name === "id") {
              ef?.splice(index, 1);
              ef?.unshift(item);
            }
          });

          setEntityFields(ef);
        }
      })
      .catch((err) => console.error(err));
  };

  const mutationUpdateSqlMapping = (
    mappingId: number,
    sqlMapping: SqlMapping
  ) => {
    var updateFieldsInput: SqlUpdateField[] = [];
    sqlMapping.updateFields.forEach((x) => {
      var y: SqlUpdateField = {
        queryField: x.queryField,
        rawUpdateField: x.rawUpdateField,
      };
      updateFieldsInput.push(y);
    });

    var sqlMappingInput: SqlMapping = {
      query: sqlMapping.query,
      rawKeyField: sqlMapping.rawKeyField,
      queryKeyField: sqlMapping.queryKeyField,
      updateFields: updateFieldsInput,
    };

    apiClient
      .mutate<RemappingMutation>({
        mutation: UPDATE_SQL_MAPPING,
        variables: {
          mappingId: mappingId,
          mapping: sqlMappingInput,
        },
        errorPolicy: "all",
      })
      .then((response) => {
        console.log(response);
        if (onMappingChange) {
          onMappingChange();
        }
      })
      .catch((err) => console.error(err));
  };

  const mutationDeleteMapping = (mappingId: number) => {
    apiClient
      .mutate<RemappingMutation>({
        mutation: DELETE_MAPPING,
        variables: {
          mappingId: mappingId,
        },
        errorPolicy: "all",
      })
      .then((response) => {
        console.log(response);
        if (onMappingChange) {
          onMappingChange();
        }
      })
      .catch((err) => console.error(err));
  };

  const mutationCreateSqlMapping = (
    mappedTableId: number,
    sqlMapping: SqlMapping
  ) => {
    apiClient
      .mutate<RemappingMutation>({
        mutation: CREATE_SQL_MAPPING,
        variables: {
          mappedTableId: mappedTableId,
          mapping: sqlMapping,
        },
        errorPolicy: "all",
      })
      .then((response) => {
        console.log(response);
        if (onMappingChange) {
          onMappingChange();
        }
      })
      .catch((err) => console.error(err));
  };

  function handleEditorChange(value: any, event: any) {
    setLoadingParse(false);
    setEditorDirty(true);
    setResultSet(undefined);
    setSqlMappingState({
      ...sqlMappingState,
      query: value,
    });
  }

  const addSqlUpdateField = () => {
    if (
      newSqlUpdateField.queryField !== "" &&
      newSqlUpdateField.rawUpdateField !== ""
    ) {
      if (
        sqlMappingState.updateFields.filter(
          (f) =>
            f.queryField === newSqlUpdateField.queryField &&
            f.rawUpdateField === newSqlUpdateField.rawUpdateField
        ).length === 0
      ) {
        setSqlMappingState({
          ...sqlMappingState,
          updateFields: sqlMappingState.updateFields?.concat(newSqlUpdateField),
        });
        resetInput();
      }
    }
  };

  const removeSqlUpdateField = (index: number) => {
    const fields = sqlMappingState.updateFields?.slice();
    fields?.splice(index, 1);
    setSqlMappingState({
      ...sqlMappingState,
      updateFields: fields,
    });
  };

  const updateQueryField = (updatedIndex: number, value: string) => {
    const newList = sqlMappingState.updateFields?.map((item, index) => {
      if (index === updatedIndex) {
        const updatedSqlUpdateField = {
          ...item,
          queryField: value,
        };
        return updatedSqlUpdateField;
      }
      return item;
    });

    if (newList) {
      setSqlMappingState({
        ...sqlMappingState,
        updateFields: newList,
      });
    }
  };

  const updateRawField = (updatedIndex: number, value: string) => {
    const newList = sqlMappingState.updateFields?.map((item, index) => {
      if (index === updatedIndex) {
        const updatedSqlUpdateField = {
          ...item,
          rawUpdateField: value,
        };
        return updatedSqlUpdateField;
      }
      return item;
    });

    if (newList) {
      setSqlMappingState({
        ...sqlMappingState,
        updateFields: newList,
      });
    }
  };

  return (
    <>
      {loading === true ? (
        <Spinner animation="border" role="status" variant="secondary">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      ) : (
        <Form>
          <Form.Group controlId="formSQLQuery">
            <Form.Label>Query</Form.Label>
            <Editor
              className="border border-secondary py-3"
              height="200px"
              language="sql"
              defaultValue={sqlMappingState.query}
              onChange={handleEditorChange}
            />

            <div className="float-end">
              <Button
                disabled={loadingParse}
                variant={
                  editorDirty === true
                    ? "warning"
                    : parseSuccess === true
                    ? "success"
                    : "danger"
                }
                className="m-1"
                onClick={() =>
                  !loadingParse
                    ? parseSQLQuery(migrationId, sqlMappingState.query)
                    : null
                }
              >
                <>
                  <Check2 width={20} height={20} /> Parse
                </>
              </Button>
              <Button
                disabled={editorDirty || !parseSuccess || loadingQuery}
                variant={
                  editorDirty === true
                    ? "warning"
                    : parseSuccess === true
                    ? "success"
                    : "danger"
                }
                className="m-1"
                onClick={() => {
                  if (!loadingQuery) {
                    if (!resultSet) {
                      executeSelectQuery(
                        migrationId,
                        sqlMappingState.query,
                        20
                      );
                    } else {
                      setResultSet(undefined);
                    }
                  }
                }}
              >
                {resultSet ? (
                  <>
                    <ChevronUp width={20} height={20} /> Hide
                  </>
                ) : (
                  <>
                    <Play width={20} height={20} /> Execute
                  </>
                )}
              </Button>
            </div>

            <code>
              This must be a select query only, it will not update the database
              in any way
            </code>

            <SelectQuery queryResults={resultSet} />
          </Form.Group>
          {queryErrorMessage && queryErrorMessage.length > 0 ? (
            <Alert variant="danger" className="my-4">
              <Alert.Heading>{queryErrorMessage}</Alert.Heading>
              <p>{queryErrorException}</p>
            </Alert>
          ) : (
            <>
              <Form.Label></Form.Label>
              <Row>
                <Form.Group as={Col}>
                  <Form.Label>Raw Key Field</Form.Label>
                  <Form.Select
                    onChange={(e) =>
                      setSqlMappingState({
                        ...sqlMappingState,
                        rawKeyField: e.currentTarget.value,
                      })
                    }
                  >
                    {mappingId === 0 ? (
                      <option value="">Select raw key field...</option>
                    ) : null}
                    {entityFields?.map((f) => (
                      <option
                        key={f.name}
                        value={f.name}
                        selected={sqlMapping.rawKeyField === f.name}
                      >
                        {entityName + " > " + f.name}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label>Query Key Field</Form.Label>
                  <Form.Select
                    onChange={(e) =>
                      setSqlMappingState({
                        ...sqlMappingState,
                        queryKeyField: e.currentTarget.value,
                      })
                    }
                  >
                    {mappingId === 0 ? (
                      <option value="">Select query key field...</option>
                    ) : null}
                    {queryFields?.map((tf) => (
                      <option
                        key={tf.name}
                        value={tf.name}
                        selected={sqlMapping.queryKeyField === tf.name}
                      >
                        {tf.name}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Row>
              <Form.Label>Update Fields (Overwrite left with right)</Form.Label>
              {sqlMappingState.updateFields?.map((suf, index) => (
                <Row key={index}>
                  <Form.Group as={Col}>
                    <Form.Control
                      onChange={(e) =>
                        updateRawField(index, e.currentTarget.value)
                      }
                    >
                      {entityFields?.map((f) => (
                        <option
                          key={f.name}
                          value={f.name}
                          selected={suf.rawUpdateField === f.name}
                        >
                          {entityName + " > " + f.name}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                  <Form.Group as={Col}>
                    <Form.Select
                      onChange={(e) =>
                        updateQueryField(index, e.currentTarget.value)
                      }
                    >
                      {queryFields?.map((tf) => (
                        <option
                          key={tf.name}
                          selected={suf.queryField === tf.name}
                        >
                          {tf.name}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                  <Button
                    variant="danger"
                    style={{ height: 37, width: 120 }}
                    onClick={() => removeSqlUpdateField(index)}
                  >
                    <>
                      <FileEarmarkMinus
                        width={20}
                        height={20}
                        className="mb-1"
                      />{" "}
                      Remove
                    </>
                  </Button>
                </Row>
              ))}
              <br />
              <Form.Label>New update field</Form.Label>
              <Row>
                <Form.Group as={Col}>
                  <Form.Select
                    value={newSqlUpdateField.rawUpdateField}
                    onChange={(e) =>
                      setNewSqlUpdateField({
                        ...newSqlUpdateField,
                        rawUpdateField: e.currentTarget.value,
                      })
                    }
                  >
                    <option>Select raw field...</option>
                    {entityFields?.map((f) => (
                      <option key={f.name} value={f.name}>
                        {entityName + " > " + f.name}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Select
                    value={newSqlUpdateField.queryField}
                    onChange={(e) =>
                      setNewSqlUpdateField({
                        ...newSqlUpdateField,
                        queryField: e.currentTarget.value,
                      })
                    }
                  >
                    <option>Select query field...</option>
                    {queryFields?.map((tf) => (
                      <option key={tf.name}>{tf.name}</option>
                    ))}
                  </Form.Select>
                </Form.Group>
                <Button
                  variant="success"
                  style={{ height: 37, width: 120 }}
                  onClick={addSqlUpdateField}
                >
                  <>
                    <FileEarmarkPlus width={20} height={20} className="mb-1" />{" "}
                    Add
                  </>
                </Button>
              </Row>
              <Form.Label></Form.Label>
              <Row>
                <Form.Group as={Col}>
                  {mappingId > 0 ? (
                    <Button
                      variant="danger"
                      style={{ height: 37, width: 130 }}
                      onClick={() => {
                        if (mappingId > 0) {
                          mutationDeleteMapping(mappingId);
                        }
                      }}
                    >
                      <Trash width={20} height={20} className="mb-1" /> Delete
                      Mapping
                    </Button>
                  ) : null}
                </Form.Group>
                <Form.Group as={Col}>
                  <Button
                    className="float-end"
                    variant="success"
                    style={{ height: 37, width: 130 }}
                    onClick={() => {
                      if (
                        sqlMappingState.query !== "" &&
                        sqlMappingState.rawKeyField !== "" &&
                        sqlMappingState.queryKeyField !== "" &&
                        sqlMappingState.updateFields.length > 0
                      ) {
                        if (mappingId > 0) {
                          mutationUpdateSqlMapping(mappingId, sqlMappingState);
                        } else {
                          mutationCreateSqlMapping(
                            mappedTableId,
                            sqlMappingState
                          );
                        }
                      }
                    }}
                  >
                    <Save width={20} height={20} className="mb-1" /> Save
                    Mapping
                  </Button>
                </Form.Group>
              </Row>
            </>
          )}
        </Form>
      )}
    </>
  );
};

export default SQLMappingPane;
