import React, { useState, useEffect } from "react";

import { apiClient } from "../index";

import {
  GET_EXECUTE_SELECT_QUERY,
  DatabasesQuery,
  RawDatabaseTable,
  ResultSet,
  RawDatabaseField,
  ErrorExtensions,
} from "../API/databaseService";

import Editor from "@monaco-editor/react";

import QueryGrid from "./Datagrids/QueryGrid";

import Spinner from "react-bootstrap/Spinner";
import Alert from "react-bootstrap/Alert";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";

import { Play } from "react-bootstrap-icons";

import { useChecklist } from "react-checklist";

export type RawEntityPreviewProps = {
  migrationId: string;
  rawDBTable: RawDatabaseTable | undefined;
  isOpen: boolean;
};

const RawEntityPreview: React.FunctionComponent<RawEntityPreviewProps> = ({
  migrationId,
  rawDBTable,
  isOpen,
}) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [showEditor, setShowEditor] = useState<boolean>(false);
  const [resultSet, setResultSet] = useState<ResultSet | undefined>(undefined);
  const [columnQueryString, setColumnQueryString] = useState<string>("");
  const [sqlQueryString, setSQLQueryString] = useState<string>("");
  const [queryErrorMessage, setQueryErrorMessage] = useState<string>("");
  const [queryErrorException, setQueryErrorException] = useState<string>("");

  var rawFields: RawDatabaseField[] = [];
  if (rawDBTable) {
    rawFields = rawDBTable.fields.slice();
  }

  const { handleCheck, isCheckedAll, checkedItems } = useChecklist(rawFields, {
    key: "name",
    keyType: "string",
  });

  useEffect(() => {
    if (isOpen && rawDBTable) {
      setResultSet(undefined);

      var fieldArray: string[] = [];
      rawDBTable.fields.map((f) => fieldArray.push(f.name));

      var qs =
        "select " + fieldArray.join(",") + " from " + rawDBTable.rawTableName;

      setColumnQueryString(qs);
      executeSelectQuery(migrationId, qs);
    }
  }, [migrationId, rawDBTable, isOpen]);

  const executeSelectQuery = (migrationId: string, query: string) => {
    if (query !== "") {
      if (
        !query.startsWith("select") ||
        query.includes("update ") ||
        query.includes("update\r\n") ||
        query.includes("delete ") ||
        query.includes("delete\r\n")
      ) {
        setQueryErrorMessage(
          "SELECT statements only please - DELETE and UPDATE prohibited"
        );
        setQueryErrorException("The following query is invalid:" + query);
      } else {
        setQueryErrorMessage("");

        apiClient
          .query<DatabasesQuery>({
            query: GET_EXECUTE_SELECT_QUERY,
            variables: {
              query: query,
              migrationId: migrationId,
            },
            fetchPolicy: "network-only",
            errorPolicy: "all",
          })
          .then((response) => {
            if (response.errors) {
              const errorMessage = response.errors[0].message;
              setQueryErrorMessage(errorMessage);
              if (response.errors[0].extensions) {
                const ext: ErrorExtensions = response.errors[0]
                  .extensions as ErrorExtensions;
                setQueryErrorException(ext.data.exception.message);
              }
            } else {
              setResultSet(response.data.databases_executeSelectQuery);
            }

            setLoading(false);
          })
          .catch((err) => {
            console.error(err);

            setLoading(false);
          });
      }
    }
  };

  const LoadSelectedData = () => {
    if (showEditor === true) {
      setLoading(true);
      executeSelectQuery(migrationId, sqlQueryString);
    } else if (rawDBTable) {
      setLoading(true);

      setResultSet(undefined);

      console.log(checkedItems);

      var fieldArray: string[] = [];

      if (checkedItems.size > 0) {
        checkedItems.forEach((value) => {
          console.log(value);
          fieldArray.push(value.toString());
        });
      } else {
        rawDBTable.fields.map((f) => fieldArray.push(f.name));
      }

      var qs =
        "select " + fieldArray.join(",") + " from " + rawDBTable.rawTableName;

      setColumnQueryString(qs);

      executeSelectQuery(migrationId, qs);
    }

    setLoading(false);
  };

  function handleEditorChange(value: any, event: any) {
    setSQLQueryString(value);
  }

  const ShowHideEditor = () => {
    if (sqlQueryString.length === 0) {
      var tempQS: string = columnQueryString;

      tempQS =
        columnQueryString.replace(" from ", "\r\nfrom ") +
        "\r\nwhere 1=1\r\nlimit 10";

      setSQLQueryString(tempQS);
    }

    setShowEditor(!showEditor);
  };

  return (
    <Container fluid className="p-0">
      <Row>
        <Col xs="12" sm="12" md="4" lg="3" xl="3" xxl="4">
          <Form>
            <Card className="mt-4">
              {showEditor ? (
                <Editor
                  className="border border-secondary py-3"
                  height="343px"
                  language="sql"
                  defaultValue={sqlQueryString}
                  onChange={handleEditorChange}
                  options={{
                    wordWrap: "on",
                  }}
                />
              ) : (
                <Card.Body
                  style={{
                    height: 343,
                    overflowY: "scroll",
                    clear: "both",
                  }}
                >
                  {rawFields.map((v, i) => (
                    <Form.Check
                      key={i}
                      data-key={v.name}
                      type="checkbox"
                      label={v.name}
                      checked={checkedItems.has(v.name)}
                      onChange={handleCheck}
                    />
                  ))}
                </Card.Body>
              )}

              <Card.Footer>
                <span className="float-end">
                  <Button
                    className="me-sm-1"
                    style={{ width: 70 }}
                    size="sm"
                    onClick={ShowHideEditor}
                  >
                    {showEditor ? "Fields" : "SQL"}
                  </Button>

                  <Button
                    style={{ width: 70 }}
                    size="sm"
                    variant="success"
                    onClick={LoadSelectedData}
                  >
                    <Play width={20} height={20} /> Run
                  </Button>
                </span>
                {!showEditor ? (
                  <Form.Check
                    inline
                    className="me-sm-1"
                    type="checkbox"
                    label="All"
                    checked={isCheckedAll}
                    onChange={handleCheck}
                  />
                ) : null}
              </Card.Footer>
            </Card>
          </Form>
        </Col>
        <Col xs="12" sm="12" md="8" lg="9" xl="9" xxl="8">
          {loading === true ? (
            <div className="m-4">
              <Spinner animation="border" role="status" variant="secondary">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          ) : queryErrorMessage && queryErrorMessage.length > 0 ? (
            <Alert variant="danger" className="my-4">
              <Alert.Heading>{queryErrorMessage}</Alert.Heading>
              <p>{queryErrorException}</p>
            </Alert>
          ) : (
            <>
              {/* <SelectQuery queryResults={resultSet} /> */}
              <QueryGrid queryResults={resultSet} />
            </>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default RawEntityPreview;
