import React from "react";

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

interface ConfirmDialogProps {
  title?: string;
  message?: string;
  show: boolean;
  onHideHandler?: any;
  onOKHandler?: any;
  icon?: JSX.Element;
}

const ConfirmDialog: React.FC<ConfirmDialogProps> = ({
  title,
  message,
  show,
  onHideHandler,
  onOKHandler,
  icon,
}) => {
  return (
    <>
      <Modal
        show={show}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        onHide={onHideHandler}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {icon}
            {title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {message?.split("\n").map((str, i) => (
            <p key={i}>{str}</p>
          ))}
        </Modal.Body>
        <Modal.Footer>
          <span className="float-end">
            <Button
              style={{ width: 120 }}
              onClick={onOKHandler}
              className="me-1"
            >
              OK
            </Button>
            <Button style={{ width: 120 }} onClick={onHideHandler}>
              Cancel
            </Button>
          </span>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ConfirmDialog;
