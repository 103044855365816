import React from "react";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import MappingScriptsList from "./MappingScriptsList";
import MappingScriptById from "./MappingScriptById";

const MappingScriptHome: React.FC = () => {
  return (
    <Container fluid>
      <Row>
        <Col>
          <h2>Mapping Script</h2>
        </Col>
      </Row>
      <Row className="my-5">
        <Col>
          <MappingScriptsList />
        </Col>
        <Col>
          <MappingScriptById mappingScriptId={1} />
        </Col>
      </Row>
    </Container>
  );
};

export default MappingScriptHome;
