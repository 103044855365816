import { gql } from "@apollo/client";

export type RemappingQuery = {
  remapping_listMappingScripts: MappingScript[];
  remapping_findMappingScriptById: MappingScript;
  remapping_findTemplateById: SnapshotTemplate;
  remapping_findTransformationById: Transformation;
  remapping_listTransformations: Transformation[];
};

export type RemappingMutation = {
  remapping_createMappedTable: MappedTable;
  remapping_deleteMappedTable: MappedTable;
  remapping_deleteMappedTablesForTransformation: MappedTable[];
  remapping_createLookupTableMapping: SnapshotMapping;
  remapping_updateLookupTableMapping: LookupTableMapping;
  remapping_createSqlMapping: SqlMapping;
  remapping_updateSqlMapping: SqlMapping;
  remapping_createDictionaryMapping: SnapshotMapping;
  remapping_updateDictionaryMapping: DictionaryMapping;
  remapping_createExpressionMapping: SnapshotMapping;
  remapping_updateExpressionMapping: ExpressionMapping;
  remapping_deleteMapping: SnapshotMapping;
  remapping_deleteMappingsForMappedTable: SnapshotMapping[];
  remapping_createMappingScript: MappingScript;
  remapping_updateMappingScript: MappingScript;
  remapping_deleteMappingScript: MappingScript;
  remapping_createTemplate: SnapshotTemplate;
  remapping_deleteTemplate: SnapshotTemplate;
  remapping_setTemplateToGenerateSnapshot: SnapshotTemplate;
  remapping_addSnapshotTemplateScript: SnapshotTemplate;
  remapping_removeSnapshotTemplateScript: SnapshotTemplate;
  remapping_createTransformation: Transformation;
  remapping_updateTransformation: Transformation;
  remapping_deleteTransformation: Transformation;
};

export type MappingScript = {
  id: number;
  name: string;
  content: string;
  description: string;
};

export type SnapshotTemplate = {
  id: number;
  snapshotTransformationId: number;
  generateSnapshotOnStable: boolean;
  postScripts: MappingScript[];
  preScripts: MappingScript[];
  transformation: Transformation;
};

export type Transformation = {
  id: number;
  name: string;
  description: string;
  tables: MappedTable[];
  templates: SnapshotTemplate[];
};

export type MappedTable = {
  id: number;
  tableName: string;
  transformationId: number;
  mappings: SnapshotMapping[];
};

export type SnapshotMapping = {
  id: number;
  snapshotMappedTableId: number;
  mapping: MappingUnion;
};

export type MappingUnion =
  | DictionaryMapping
  | ExpressionMapping
  | LookupTableMapping
  | SqlMapping;

export type DictionaryMapping = {
  defaultValue: string;
  rawUpdateField: string;
  values: DictionaryMappingValue[];
};

export type ExpressionMapping = {
  expression: string;
  rawUpdateField: string;
  childMappings: ChildExpressionMappingUnion[];
};

export type LookupTableMapping = {
  defaultValue: string;
  lookupDbTable: string;
  lookupKeyField: string;
  lookupValueField: string;
  rawUpdateField: string;
};

export type SqlMapping = {
  query: string;
  queryKeyField: string;
  rawKeyField: string;
  updateFields: SqlUpdateField[];
};

export type DictionaryMappingValue = {
  key: string;
  value: string;
};

export type ChildExpressionMappingUnion =
  | SqlExpressionMapping
  | StringExpressionMapping;

export type SqlExpressionMapping = {
  query: string;
  queryKeyField: string;
  rawKeyField: string;
  expressiongKeys: string[];
};

export type StringExpressionMapping = {
  expressiongKey: string;
  value: string;
};

export type SqlUpdateField = {
  queryField: string;
  rawUpdateField: string;
};

//Fetch a single mapping script.
export const FIND_MAPPING_SCRIPT_BY_ID = gql`
  query remapping_findMappingScriptById($mappingScriptId: Long) {
    remapping_findMappingScriptById(mappingScriptId: $mappingScriptId) {
      id
      name
      content
      description
    }
  }
`;

//Fetch all mapping scripts.
export const LIST_MAPPING_SCRIPTS = gql`
  query remapping_listMappingScripts {
    remapping_listMappingScripts {
      id
      name
      content
      description
    }
  }
`;

//Fetch a snapshot template by its ID.
export const FIND_TEMPLATE_BY_ID = gql`
  query remapping_findTemplateById($snapshotTemplateId: Long) {
    remapping_findTemplateById(snapshotTemplateId: $snapshotTemplateId) {
      id
      snapshotTransformationId
      generateSnapshotOnStable
      postScripts {
        id
        name
        content
        description
      }
      preScripts {
        id
        name
        content
        description
      }
    }
  }
`;

//Fetch a single transformation.
export const FIND_TRANSFORMATION_BY_ID = gql`
  query remapping_findTransformationById($transformationId: Long) {
    remapping_findTransformationById(transformationId: $transformationId) {
      id
      name
      description
      tables {
        id
        tableName
        transformationId
        mappings {
          id
          snapshotMappedTableId
          mapping {
            ... on DictionaryMapping {
              defaultValue
              rawUpdateField
              values {
                key
                value
              }
            }
            ... on ExpressionMapping {
              expression
              rawUpdateField
              childMappings {
                ... on SqlExpressionMapping {
                  expressionKeys
                  query
                  queryKeyField
                  rawKeyField
                }
                ... on StringExpressionMapping {
                  expressionKey
                  value
                }
              }
            }
            ... on LookupTableMapping {
              defaultValue
              lookupDbTable
              lookupKeyField
              lookupValueField
              rawUpdateField
            }
            ... on SqlMapping {
              query
              queryKeyField
              rawKeyField
              updateFields {
                queryField
                rawUpdateField
              }
            }
          }
        }
      }
      templates {
        id
        snapshotTransformationId
        generateSnapshotOnStable
        postScripts {
          id
          name
          content
          description
        }
        preScripts {
          id
          name
          content
          description
        }
      }
    }
  }
`;

//Fetch all transformations.
export const LIST_TRANSFORMATIONS = gql`
  query remapping_listTransformations {
    remapping_listTransformations {
      id
      name
      description
    }
  }
`;

//Define a new mapped table against a transformation.
export const CREATE_MAPPED_TABLE = gql`
  mutation remapping_createMappedTable(
    $snapshotTransformationId: Long
    $tableName: String
  ) {
    remapping_createMappedTable(
      snapshotTransformationId: $snapshotTransformationId
      tableName: $tableName
    ) {
      id
      tableName
      transformationId
    }
  }
`;

//Delete a mapped table and all its mappings from a transformation.
export const DELETE_MAPPED_TABLE = gql`
  mutation remapping_deleteMappedTable($mappedTableId: Long) {
    remapping_deleteMappedTable(mappedTableId: $mappedTableId) {
      id
    }
  }
`;

//Delete all mapped tables and their mappings from a transformation.
export const DELETE_MAPPED_TABLES_FOR_TRANFORMATION = gql`
  mutation remapping_deleteMappedTablesForTransformation(
    $snapshotTransformationId: Long
  ) {
    remapping_deleteMappedTablesForTransformation(
      snapshotTransformationId: $snapshotTransformationId
    ) {
      id
    }
  }
`;

//Create a new instance of LookupTableMapping against a mapped table.
export const CREATE_LOOKUP_TABLE_MAPPING = gql`
  mutation remapping_createLookupTableMapping(
    $mappedTableId: Long
    $mapping: LookupTableMappingInput
  ) {
    remapping_createLookupTableMapping(
      mappedTableId: $mappedTableId
      mapping: $mapping
    ) {
      id
      mappedTableId
      mapping {
        ... on LookupTableMapping {
          defaultValue
          lookupDbTable
          lookupKeyField
          lookupValueField
          rawUpdateField
        }
      }
    }
  }
`;

//Update an existing instance of LookupTableMapping.
export const UPDATE_LOOKUP_TABLE_MAPPING = gql`
  mutation remapping_updateLookupTableMapping(
    $mappingId: Long
    $mapping: LookupTableMapping
  ) {
    remapping_updateLookupTableMapping(
      mappingId: $mappingId
      mapping: $mapping
    ) {
      id
      snapshotMappedTableId
      mapping {
        ... on LookupTableMapping {
          defaultValue
          lookupDbTable
          lookupKeyField
          lookupValueField
          rawUpdateField
        }
      }
    }
  }
`;

//Create a new instance of SqlMapping against a mapped table.
export const CREATE_SQL_MAPPING = gql`
  mutation remapping_createSqlMapping(
    $mappedTableId: Long
    $mapping: SqlMappingInput
  ) {
    remapping_createSqlMapping(
      mappedTableId: $mappedTableId
      mapping: $mapping
    ) {
      id
      snapshotMappedTableId
      mapping {
        ... on SqlMapping {
          query
          queryKeyField
          rawKeyField
          updateFields {
            queryField
            rawUpdateField
          }
        }
      }
    }
  }
`;

//Update an existing instance of SqlMapping.
export const UPDATE_SQL_MAPPING = gql`
  mutation remapping_updateSqlMapping(
    $mappingId: Long
    $mapping: SqlMappingInput
  ) {
    remapping_updateSqlMapping(mappingId: $mappingId, mapping: $mapping) {
      id
      snapshotMappedTableId
      mapping {
        ... on SqlMapping {
          query
          queryKeyField
          rawKeyField
          updateFields {
            queryField
            rawUpdateField
          }
        }
      }
    }
  }
`;

//Create a new instance of DictionaryMapping against a mapped table.
export const CREATE_DICTIONARY_MAPPING = gql`
  mutation remapping_createDictionaryMapping(
    $mappedTableId: Long
    $mapping: DictionaryMappingInput
  ) {
    remapping_createDictionaryMapping(
      mappedTableId: $mappedTableId
      mapping: $mapping
    ) {
      id
      snapshotMappedTableId
      mapping {
        ... on DictionaryMapping {
          defaultValue
          rawUpdateField
          values {
            key
            value
          }
        }
      }
    }
  }
`;

//Update an existing instance of DictionaryMapping.
export const UPDATE_DICTIONARY_MAPPING = gql`
  mutation remapping_updateDictionaryMapping(
    $mappingId: Long
    $mapping: DictionaryMappingInput
  ) {
    remapping_updateDictionaryMapping(
      mappingId: $mappingId
      mapping: $mapping
    ) {
      id
      snapshotMappedTableId
      mapping {
        ... on DictionaryMapping {
          defaultValue
          rawUpdateField
          values {
            key
            value
          }
        }
      }
    }
  }
`;

//Create a new instance of ExpressionMapping against a mapped table.
export const CREATE_EXPRESSION_MAPPING = gql`
  mutation remapping_createExpressionMapping(
    $mappedTableId: Long
    $mapping: ExpressionMappingInput
  ) {
    remapping_createExpressionMapping(
      mappedTableId: $mappedTableId
      mapping: $mapping
    ) {
      id
      snapshotMappedTableId
      mapping {
        ... on ExpressionMapping {
          expression
          rawUpdateField
          childMappings {
            ... on SqlExpressionMapping {
              expressionKeys
              query
              queryKeyField
              rawKeyField
            }
            ... on StringExpressionMapping {
              expressionKey
              value
            }
          }
        }
      }
    }
  }
`;

//Update an existing instance of ExpressionMapping.
export const UPDATE_EXPRESSION_MAPPING = gql`
  mutation remapping_updateExpressionMapping(
    $mappingId: Long
    $mapping: ExpressionMappingInput
  ) {
    remapping_updateExpressionMapping(
      mappingId: $mappingId
      mapping: $mapping
    ) {
      id
      snapshotMappedTableId
      mapping {
        ... on ExpressionMapping {
          expression
          rawUpdateField
          childMappings {
            ... on SqlExpressionMapping {
              expressionKeys
              query
              queryKeyField
              rawKeyField
            }
            ... on StringExpressionMapping {
              expressionKey
              value
            }
          }
        }
      }
    }
  }
`;

//Delete a mapping from within a mapped table.
export const DELETE_MAPPING = gql`
  mutation remapping_deleteMapping($mappingId: Long) {
    remapping_deleteMapping(mappingId: $mappingId) {
      id
    }
  }
`;

//Delete all mappings from within a mapped table.
export const DELETE_MAPPING_FOR_MAPPED_TABLE = gql`
  mutation remapping_deleteMappingsForMappedTable($mappedTableId: Long) {
    remapping_deleteMappingsForMappedTable(mappedTableId: $mappedTableId) {
      id
    }
  }
`;

//Create a new named mapping script.
export const CREATE_MAPPING_SCRIPT = gql`
  mutation remapping_createMappingScript(
    $name: String
    $description: String
    $content: String
  ) {
    remapping_createMappingScript(
      name: $name
      description: $description
      content: $content
    ) {
      id
      name
      content
      description
    }
  }
`;

//Update an existing mapping script.
export const UPDATE_MAPPING_SCRIPT = gql`
  mutation remapping_updateMappingScript(
    $mappingScriptId: Long
    $name: String
    $description: String
    $content: String
  ) {
    remapping_updateMappingScript(
      mappingScriptId: $mappingScriptId
      name: $name
      description: $description
      content: $content
    ) {
      id
      name
      content
      description
    }
  }
`;

//Delete a mapping script.
export const DELETE_MAPPING_SCRIPT = gql`
  mutation remapping_deleteMappingScript($mappingScriptId: Long) {
    remapping_deleteMappingScript(mappingScriptId: $mappingScriptId) {
      id
    }
  }
`;

//Create a new snapshot template against a migration.
export const CREATE_TEMPLATE = gql`
  mutation remapping_createTemplate(
    $migrationId: Guid
    $transformationId: Long
  ) {
    remapping_createTemplate(
      migrationId: $migrationId
      transformationId: $transformationId
    ) {
      id
      snapshotTransformationId
      generateSnapshotOnStable
      postScripts {
        id
        name
        content
        description
      }
      preScripts {
        id
        name
        content
        description
      }
    }
  }
`;

//Deletes a snapshot template.
export const DELETE_TEMPLATE = gql`
  mutation remapping_deleteTemplate($transformationAssignmentId: Long) {
    remapping_deleteTemplate(
      transformationAssignmentId: $transformationAssignmentId
    ) {
      id
    }
  }
`;

//Set a migration to generate a snapshot on stable using this template.
export const SET_TEMPLATE_GENERATE_SNAPSHOT = gql`
  mutation remapping_setTemplateToGenerateSnapshot(
    $templateId: Long
    $generateOnStable: Boolean
  ) {
    remapping_setTemplateToGenerateSnapshot(
      templateId: $templateId
      generateOnStable: $generateOnStable
    ) {
      id
    }
  }
`;

//Adds a mapping script to a snapshot template as either a pre or post script.
export const ADD_SNAPSHOT_TEMPLATE_SCRIPT = gql`
  mutation remapping_addSnapshotTemplateScript(
    $templateId: Long
    $mappingScriptId: Long
    $isPreScript: Boolean
  ) {
    remapping_addSnapshotTemplateScript(
      templateId: $templateId
      mappingScriptId: $mappingScriptId
      isPreScript: $isPreScript
    ) {
      id
      snapshotTransformationId
      generateSnapshotOnStable
      postScripts {
        id
        name
        content
        description
      }
      preScripts {
        id
        name
        content
        description
      }
    }
  }
`;

//Removes a mapping script from a snapshot template. The script and template are not deleted.
export const REMOVE_SNAPSHOT_TEMPLATE_SCRIPT = gql`
  mutation remapping_removeSnapshotTemplateScript(
    $snapshotTemplateScriptId: Long
  ) {
    REMOVE_SNAPSHOT_TEMPLATE_SCRIPT(
      snapshotTemplateScriptId: $snapshotTemplateScriptId
    ) {
      id
    }
  }
`;

//Create a new named transformation with no tables mapped.
export const CREATE_TRANFORMATION = gql`
  mutation remapping_createTransformation($name: String, $description: String) {
    remapping_createTransformation(name: $name, description: $description) {
      id
      name
      description
    }
  }
`;

//Update the name and / or description for a transformation.
export const UPDATE_TRANFORMATION = gql`
  mutation remapping_updateTransformation(
    $transformationId: Long
    $name: String
    $description: String
  ) {
    remapping_updateTransformation(
      transformationId: $transformationId
      name: $name
      description: $description
    ) {
      id
      name
      description
    }
  }
`;

//Delete a transformation along with all mapped tables / mappings.
export const DELETE_TRANFORMATION = gql`
  mutation remapping_deleteTransformation($transformationId: Long) {
    remapping_deleteTransformation(transformationId: $transformationId) {
      id
    }
  }
`;
