import React from "react";
import { ICellRendererParams } from "ag-grid-community";

import SoftwareIcon from "../SoftwareIcon";

const SoftwareRenderer: React.FC<ICellRendererParams> = ({ value }) => (
  <>
    <SoftwareIcon softwareName={value} showName={true} />
  </>
);

export default SoftwareRenderer;
