import React, { useState } from "react";

import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { useMutation } from "@apollo/client";

import {
  CREATE_TRANFORMATION,
  DELETE_TRANFORMATION,
  RemappingMutation,
} from "../../../API/remappingService";

const CreateTransformation: React.FC = () => {
  // eslint-disable-next-line
  const [transformationId, setTransformationId] = useState<number>(0);
  const [transformationName, setTransformationName] = useState<string>("");
  const [transformationDescription, setTransformationDescription] =
    useState<string>("");

  const resetInput = () => {
    setTransformationName("");
    setTransformationDescription("");
  };

  const [createTransformationMutation] = useMutation<RemappingMutation>(
    CREATE_TRANFORMATION,
    {
      // update: (cache, { data: MappingsMutation }) => {
      //   const existingTransformations = cache.readQuery({
      //     query: GET_TRANSFORMATIONS,
      //   });
      //   const newTransformation = data.createTransformation;
      //   cache.writeQuery({
      //     query: GET_TRANSFORMATIONS,
      //     data: {
      //       transformations: [
      //         newTransformation,
      //         ...existingTransformations.transformations,
      //       ],
      //     },
      //   });
      // },
      onCompleted: resetInput,
      variables: {
        name: transformationName,
        description: transformationDescription,
      },
    }
  );

  // eslint-disable-next-line
  const [deleteTransformationMutation] = useMutation<RemappingMutation>(
    DELETE_TRANFORMATION,
    {
      onCompleted: resetInput,
      variables: {
        transformationId: transformationId,
      },
    }
  );

  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
        createTransformationMutation()
          .then((res) => {
            if (res.errors) {
            }
            var trans = res.data?.remapping_createTransformation;
            console.log("Created Transformation: " + trans?.id);
          })
          .catch((err) => console.error(err));
      }}
    >
      <Form.Group as={Row} controlId="formTransId">
        <Form.Label column sm="2">
          Id
        </Form.Label>
        <Col sm="10">
          <Form.Control plaintext readOnly defaultValue={transformationId} />
        </Col>
      </Form.Group>

      <Form.Group controlId="formTransName">
        <Form.Label>Transformation Name</Form.Label>
        <Form.Control
          size="lg"
          type="text"
          placeholder="Name"
          value={transformationName}
          onChange={(e) => setTransformationName(e.target.value)}
        />
        <Form.Text className="text-muted">
          User friendly name for this transformation.
        </Form.Text>
      </Form.Group>

      <Form.Group controlId="formTransDescription">
        <Form.Label>Description</Form.Label>
        <Form.Control
          size="lg"
          type="text"
          placeholder="Description"
          value={transformationDescription}
          onChange={(e) => setTransformationDescription(e.target.value)}
        />
        <Form.Text className="text-muted">
          Describes what this transformation does.
        </Form.Text>
      </Form.Group>

      <Button variant="primary" type="submit">
        Submit
      </Button>
      <Button variant="primary" type="button">
        Delete
      </Button>
    </Form>
  );
};

export default CreateTransformation;
