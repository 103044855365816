import React, { FC } from "react";
import { ColDef, ICellRendererParams } from "ag-grid-community";
import { MigrationRow } from "../../API/migrationService";

import { DateTime } from "luxon";

function DateFormatter(value: Date) {
  const dateString = new Date(value).toLocaleDateString("en-gb", {
    year: "2-digit",
    month: "2-digit",
    day: "2-digit",
  });

  const timeString = new Date(value).toLocaleTimeString("en-gb", {
    hour: "2-digit",
    minute: "2-digit",
  });

  return dateString + " " + timeString;
}

function WarningFormatter(
  value: DateTime,
  colDef: ColDef | undefined,
  data: MigrationRow
) {
  var color: string = "default";

  if (colDef && colDef.field === "lastUpdated") {
    var diffInHours = DateTime.now().diff(value, "hours");

    if (
      !data?.ingressCompletedPercentage ||
      (data?.ingressCompletedPercentage &&
        data?.ingressCompletedPercentage < 100)
    ) {
      if (diffInHours.hours >= 1) {
        color = "red";
      } else {
        color = "black";
      }
    }
  }

  return color;
}

const DateRenderer: FC<ICellRendererParams> = ({ value, colDef, data }) => (
  <>
    <span style={{ color: WarningFormatter(value, colDef, data) }}>
      {DateFormatter(value)}
    </span>
  </>
);

export default DateRenderer;
