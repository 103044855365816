import React from "react";

import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";

import Dropdown from "react-bootstrap/Dropdown";
import NavItem from "react-bootstrap/NavItem";
import NavLink from "react-bootstrap/NavLink";
import { LinkContainer } from "react-router-bootstrap";
import {
  // GearWideConnected,
  // BellFill,
  PersonCircle,
} from "react-bootstrap-icons";

export type NavigationProps = {
  onProfileLogout?: any;
};

const Navigation: React.FunctionComponent<NavigationProps> = ({
  onProfileLogout,
}) => {
  const Logout = () => {
    if (onProfileLogout) {
      onProfileLogout();
    }
  };

  return (
    <Navbar
      collapseOnSelect
      expand="md"
      bg="dark"
      variant="dark"
      sticky="top"
      className="mb-3 px-3"
    >
      <LinkContainer to={{ pathname: "/" }}>
        <Navbar.Brand href="home">
          <img
            alt=""
            src="/Data-Import.svg"
            width="24"
            height="24"
            className="d-inline-block align-top"
          />{" "}
          <span>New Tooling</span>
        </Navbar.Brand>
      </LinkContainer>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="me-auto">
          <LinkContainer to={{ pathname: "/migration" }}>
            <Nav.Link disabled href="/migration">
              Migration
            </Nav.Link>
          </LinkContainer>
          <LinkContainer to={{ pathname: "/refine" }}>
            <Nav.Link disabled href="/refine">
              Refine
            </Nav.Link>
          </LinkContainer>
          {/* <NavDropdown disabled title="Config" id="collasible-nav-dropdown">
            <NavDropdown.Item disabled href="#action/3.1">
              Action
            </NavDropdown.Item>
            <NavDropdown.Item disabled href="#action/3.2">
              Another action
            </NavDropdown.Item>
            <NavDropdown.Item disabled href="#action/3.3">
              Something
            </NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item disabled href="#action/3.4">
              Separated link
            </NavDropdown.Item>
          </NavDropdown> */}
        </Nav>
        <Nav>
          {/* <Nav.Link disabled href="#deets">
            <GearWideConnected width="22" height="22" className="me-1" />
            Settings
          </Nav.Link>
          <Nav.Link disabled eventKey={2} href="#memes">
            <BellFill width="22" height="22" className="me-1" />
            Notifications
          </Nav.Link> */}
          <Dropdown align="end" as={NavItem}>
            <Dropdown.Toggle as={NavLink}>
              <PersonCircle width="22" height="22" className="me-1" />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item disabled>Profile</Dropdown.Item>
              <Dropdown.Divider />
              <Dropdown.Item onClick={() => Logout()}>Logout</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default Navigation;
