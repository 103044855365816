import React from "react";

import ProgressBar from "react-bootstrap/ProgressBar";

import { DateTime } from "luxon";

function AnimationSetter(
  status: string,
  percentage: number,
  lastUpdated: DateTime
) {
  var animated: boolean = false;

  if (status === "PAUSED" && percentage < 100) {
    var diffInHours = DateTime.now().diff(lastUpdated, "hours");

    if (diffInHours.hours < 2) {
      animated = true;
    }
  }

  return animated;
}

function VariantSetter(
  status: string,
  percentage: number,
  lastUpdated: DateTime
) {
  var variant: string = "dark";

  if (status === "PAUSED") {
    if (percentage === 100) {
      variant = "success";
    } else {
      var diffInHours = DateTime.now().diff(lastUpdated, "hours");

      if (diffInHours.hours >= 1) {
        variant = "danger";
      } else {
        variant = "warning";
      }
    }
  }

  return variant;
}

export type DashboardProgressBarProps = {
  label: string;
  status: string;
  percentage: number;
  lastUpdated: DateTime;
  barHeight: number;
};

const DashboardProgressBar: React.FunctionComponent<
  DashboardProgressBarProps
> = ({ label, status, percentage, lastUpdated, barHeight }) => {
  return (
    <>
      {percentage > 0 ? (
        <ProgressBar
          style={{ height: barHeight }}
          striped
          animated={AnimationSetter(status, percentage, lastUpdated)}
          variant={VariantSetter(status, percentage, lastUpdated)}
          now={percentage}
          label={`${
            barHeight < 36 && percentage > 30 ? label + " " : ""
          }${percentage.toFixed(Number.isInteger(percentage) ? 0 : 2)}%`}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default DashboardProgressBar;
