import React from "react";

import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Popover from "react-bootstrap/Popover";
import Button from "react-bootstrap/Button";
import Badge from "react-bootstrap/Badge";

import Editor from "@monaco-editor/react";

import {
  InfoCircle,
  ArrowsAngleContract,
  ArrowsAngleExpand,
  ArrowCounterclockwise,
} from "react-bootstrap-icons";

import { ISnapshotFieldQuery } from "../API/entityQueryService";

import { ExplainSelectTable } from "../API/databaseService";

export type EntityMappingFieldRowProps = {
  fieldQuery: ISnapshotFieldQuery;
  queryFields: ExplainSelectTable[] | undefined;
  loading: boolean;
  onSelectedFieldQueryChanged?: any;
  onFieldUpdated?: any;
  onEditorExpandedToggle?: any;
};

const EntityMappingFieldRow: React.FunctionComponent<EntityMappingFieldRowProps> =
  ({
    fieldQuery,
    queryFields,
    loading,
    onSelectedFieldQueryChanged,
    onFieldUpdated,
    onEditorExpandedToggle,
  }) => {
    const LookupClicked = (id: number) => {
      if (onSelectedFieldQueryChanged) {
        onSelectedFieldQueryChanged(id);
      }
    };

    const SelectChanged = (value: string) => {
      console.log(value);

      if (onFieldUpdated) {
        onFieldUpdated(fieldQuery.id, value);
      }
    };

    function handleEditorChange(value: any, event: any) {
      if (onFieldUpdated) {
        onFieldUpdated(fieldQuery.id, value);
      }
    }

    const isDirectMapping = () => {
      let isDirect: Boolean = false;

      queryFields?.forEach((t) =>
        t.fields.forEach((f) => {
          if (f.selectStatement === fieldQuery.selectStatement.toLowerCase()) {
            isDirect = true;
          }
        })
      );

      return isDirect;
    };

    return (
      <>
        <Row className="mb-sm-4 mb-xl-2 pb-sm-4 pb-xl-2 border-bottom">
          <Col lg="12" xl="4">
            {fieldQuery.prefixIds.includes(1) ? (
              <Badge className="me-1 bg-primary">Prefix</Badge>
            ) : null}
            <b>{fieldQuery.displayName}</b> <i>{fieldQuery.dataType}</i>
            <OverlayTrigger
              trigger={["hover", "focus"]}
              placement="right"
              overlay={
                <Popover id={`popover-${fieldQuery.id}`}>
                  <Popover.Header as="h3">
                    {fieldQuery.displayName}
                  </Popover.Header>
                  <Popover.Body>
                    <strong>Field Name: </strong>
                    {`${fieldQuery.name}`}
                    <br />
                    <strong>Field Type: </strong>
                    {`${fieldQuery.dataType}`}
                    <br />
                    <strong>Description: </strong>
                    {fieldQuery.description}
                    <br />
                    {fieldQuery.allowedValues?.length > 0 ? (
                      <>
                        <strong>Allowed Values Count: </strong>
                        {fieldQuery.allowedValues?.length}
                        <br />
                        <strong>Mapped Values Count: </strong>
                        {fieldQuery.mappedValues?.length}
                        <br />
                      </>
                    ) : null}

                    <strong>Id: </strong>
                    {`${fieldQuery.id}`}
                    <br />
                  </Popover.Body>
                </Popover>
              }
            >
              <InfoCircle className="ms-1" />
            </OverlayTrigger>
            {fieldQuery.allowedValues?.length > 0 ? (
              <Button
                disabled={loading || fieldQuery.rowDirty === true}
                className="ms-1"
                variant="link"
                onClick={(e) => LookupClicked(fieldQuery.id)}
              >
                Lookup
              </Button>
            ) : null}
          </Col>
          <Col lg="4" xl="2" className="mt-sm-1 mt-lg-2 mt-xl-0">
            <Form>
              <Form.Select
                disabled={loading || !queryFields}
                value={fieldQuery.selectStatement.toLowerCase()}
                onChange={(e) => SelectChanged(e.currentTarget.value)}
                className={fieldQuery.rowDirty === true ? "bg-warning" : ""}
              >
                <option value="">Other...</option>
                {queryFields?.map((t) => (
                  <optgroup
                    key={t.tableName}
                    label={
                      t.tableName +
                      (t.tableName !== t.tableAlias ? ` [${t.tableAlias}]` : "")
                    }
                  >
                    {t.fields.map((f) => (
                      <option key={f.name} value={f.selectStatement}>
                        {f.name}
                      </option>
                    ))}
                  </optgroup>
                ))}
              </Form.Select>
            </Form>
          </Col>
          <Col lg="8" xl="6" className="mt-sm-2 mt-xl-0">
            <OverlayTrigger
              placement="left"
              overlay={
                fieldQuery.rowDirty === true ? (
                  <Tooltip id="tooltip-undo">Reset</Tooltip>
                ) : (
                  <></>
                )
              }
            >
              <Button
                disabled={loading || fieldQuery.rowDirty === false}
                variant="danger"
                className="ms-1 float-end"
                size="sm"
                onClick={() => {
                  if (onFieldUpdated) {
                    onFieldUpdated(
                      fieldQuery.id,
                      fieldQuery.originalSelectStatement
                    );
                  }
                }}
              >
                <ArrowCounterclockwise />
              </Button>
            </OverlayTrigger>

            {isDirectMapping() ? (
              <div
                // className="border border-secondary py-1"
                style={{
                  width: "85%",
                  height: "35px",
                  backgroundColor: "#EEEEEE",
                }}
              ></div>
            ) : (
              <>
                <OverlayTrigger
                  placement="left"
                  overlay={
                    <Tooltip id="tooltip-grid">
                      {fieldQuery.editorExpanded === true
                        ? "Contract Editor"
                        : "Expand Editor"}
                    </Tooltip>
                  }
                >
                  <Button
                    disabled={loading}
                    variant="light"
                    className="ms-1 float-end"
                    size="sm"
                    onClick={() =>
                      onEditorExpandedToggle
                        ? onEditorExpandedToggle(fieldQuery.id)
                        : null
                    }
                  >
                    {fieldQuery.editorExpanded === true ? (
                      <ArrowsAngleContract />
                    ) : (
                      <ArrowsAngleExpand />
                    )}
                  </Button>
                </OverlayTrigger>

                <Editor
                  className={
                    "border border-secondary py-1" +
                    (fieldQuery.rowDirty === true ? " bg-warning" : "")
                  }
                  height={fieldQuery.editorExpanded === true ? "200px" : "35px"}
                  width="85%"
                  language="sql"
                  defaultValue=""
                  value={fieldQuery.selectStatement}
                  onChange={handleEditorChange}
                  options={{
                    readOnly: loading === true,
                    wordWrap: fieldQuery.editorExpanded === true ? "on" : "off",
                    minimap: { enabled: false },
                    lineNumbers: "off",
                    scrollbar: {
                      horizontal: "hidden",
                      vertical: "hidden",
                    },
                  }}
                ></Editor>
              </>
            )}
          </Col>
        </Row>
      </>
    );
  };
export default EntityMappingFieldRow;
