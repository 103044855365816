import React, { useEffect, useState } from "react";

import { apiClient } from "../index";

import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import Alert from "react-bootstrap/Alert";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Popover from "react-bootstrap/Popover";

import Editor from "@monaco-editor/react";

import QueryGrid from "./Datagrids/QueryGrid";

import CollapsibleCard from "../containers/CollapsibleCard";

import {
  Check2,
  ChevronUp,
  Play,
  ArrowsAngleContract,
  ArrowsAngleExpand,
  ArrowReturnLeft,
} from "react-bootstrap-icons";

import {
  GET_EXECUTE_SELECT_QUERY,
  GET_EXPLAIN_SELECT_ENTITY_QUERY,
  ResultSet,
  DatabasesQuery,
  ErrorExtensions,
  ExplainSelectTable,
} from "../API/databaseService";

export type EntityMappingFieldsProps = {
  migrationId: string;
  baseQuery: string | undefined;
  loading: boolean;
  onParseSuccess?: any;
  onParseFailure?: any;
  onEditorChanged?: any;
};

const EntityMappingBaseQuery: React.FunctionComponent<EntityMappingFieldsProps> =
  ({
    migrationId,
    baseQuery,
    loading,
    onParseSuccess,
    onParseFailure,
    onEditorChanged,
  }) => {
    const [loadingParse, setLoadingParse] = useState<boolean>(false);
    const [loadingQuery, setLoadingQuery] = useState<boolean>(false);

    const [resultSet, setResultSet] = useState<ResultSet | undefined>(undefined);

    const [queryFields, setQueryFields] = useState<
      ExplainSelectTable[] | undefined
    >(undefined);

    const [editorDirty, setEditorDirty] = useState<boolean>(false);
    const [editorExpanded, setEditorExpanded] = useState<boolean>(false);
    const [editorWordWrap, setEditorWordWrap] = useState<boolean>(false);

    const [parseSuccess, setParseSuccess] = useState<boolean>(false);
    const [sqlQueryString, setSQLQueryString] = useState<string | undefined>(
      baseQuery
    );

    const [queryErrorMessage, setQueryErrorMessage] = useState<string>("");
    const [queryErrorException, setQueryErrorException] = useState<string>("");

    const parseSQLQuery = (migrationId: string, query: string) => {
      if (query !== "") {
        setLoadingParse(true);

        apiClient
          .query<DatabasesQuery>({
            query: GET_EXPLAIN_SELECT_ENTITY_QUERY,
            variables: {
              query: query.trim(),
              migrationId: migrationId,
            },
            fetchPolicy: "network-only",
            errorPolicy: "all",
          })
          .then((response) => {
            if (response.errors) {
              setEditorDirty(false);
              setParseSuccess(false);
              setResultSet(undefined);
              setQueryFields(undefined);
              const errorMessage = response.errors[0].message;
              setQueryErrorMessage(errorMessage);
              if (response.errors[0] && response.errors[0].extensions) {
                const ext: ErrorExtensions = response.errors[0]
                  .extensions as ErrorExtensions;
                if (ext && ext.data) {
                  setQueryErrorException(ext.data.exception.message);
                } else {
                  setQueryErrorException("");
                }
              }
              if (onParseFailure) {
                onParseFailure();
              }

              setLoadingParse(false);
            } else {
              setEditorDirty(false);
              setParseSuccess(true);
              setQueryErrorMessage("");
              setQueryErrorException("");

              setQueryFields(response.data.databases_explainSelectEntityQuery);

              if (onParseSuccess) {
                onParseSuccess(
                  query,
                  response.data.databases_explainSelectEntityQuery
                );
              }
              setLoadingParse(false);
            }
          })
          .catch((err) => {
            console.error(err);
            setLoadingParse(false);
          });
      } else {
        setEditorDirty(false);
        setQueryErrorMessage("Type a select query");
        setQueryErrorException("");
        setResultSet(undefined);
        setQueryFields(undefined);
        setLoadingParse(false);
      }
    };

    const executeSelectQuery = (migrationId: string, query: string) => {
      if (query !== "") {
        var fields: string = "";

        queryFields?.forEach((t) =>
          t.fields.forEach(
            (f) => (fields += (fields === "" ? "" : ", ") + f.selectStatement)
          )
        );

        query = "select " + fields + " from " + query;

        setQueryErrorMessage("");

        apiClient
          .query<DatabasesQuery>({
            query: GET_EXECUTE_SELECT_QUERY,
            variables: {
              query: query,
              migrationId: migrationId,
            },
            fetchPolicy: "network-only",
            errorPolicy: "all",
          })
          .then((response) => {
            if (response.errors) {
              const errorMessage = response.errors[0].message;
              setQueryErrorMessage(errorMessage);
              if (response.errors[0].extensions) {
                const ext: ErrorExtensions = response.errors[0]
                  .extensions as ErrorExtensions;
                setQueryErrorException(ext.data.exception.message);
              }
            } else {
              setResultSet(response.data.databases_executeSelectQuery);
            }

            setLoadingQuery(false);
          })
          .catch((err) => {
            console.error(err);

            setLoadingQuery(false);
          });
      }
    };

    const LoadSelectedData = () => {
      setLoadingQuery(true);
      if (sqlQueryString) {
        executeSelectQuery(migrationId, sqlQueryString);
      }
    };

    function handleEditorChange(value: any, event: any) {
      let qs: string = value;

      setEditorDirty(qs !== baseQuery);
      setParseSuccess(false);
      setSQLQueryString(qs);

      if (onEditorChanged) {
        onEditorChanged(qs);
      }
    }

    useEffect(() => {
      setResultSet(undefined);
      setQueryFields(undefined);
      setSQLQueryString("");
      setQueryErrorMessage("");
      setQueryErrorException("");
      setEditorDirty(true);
      setParseSuccess(false);
      setLoadingQuery(false);
      setSQLQueryString(baseQuery);
    }, [baseQuery]);

    return (
      <>
        <CollapsibleCard headerText="Base Query" showPoll={false}>
          <Container fluid>
            <Row>
              <Col>
                <OverlayTrigger
                  placement="left"
                  overlay={
                    <Tooltip id="tooltip-undo">
                      {editorExpanded === true
                        ? "Contract Editor"
                        : "Expand Editor"}
                    </Tooltip>
                  }
                >
                  <Button
                    disabled={loading}
                    variant="light"
                    className="ms-1 float-end"
                    size="sm"
                    onClick={() => setEditorExpanded(!editorExpanded)}
                  >
                    {editorExpanded === true ? (
                      <ArrowsAngleContract />
                    ) : (
                      <ArrowsAngleExpand />
                    )}
                  </Button>
                </OverlayTrigger>

                <OverlayTrigger
                  placement="left"
                  overlay={
                    <Tooltip id="tooltip-wordwrap">
                      {editorWordWrap === true
                        ? "Disable WordWrap"
                        : "Enable WordWrap"}
                    </Tooltip>
                  }
                >
                  <Button
                    disabled={loading}
                    variant={editorWordWrap === true ? "info" : "light"}
                    className="ms-1 float-end"
                    size="sm"
                    onClick={() => setEditorWordWrap(!editorWordWrap)}
                  >
                    <ArrowReturnLeft />
                  </Button>
                </OverlayTrigger>

                <pre>select * from</pre>

                <Editor
                  className="border border-secondary py-3"
                  height={editorExpanded ? "75vh" : "220px"}
                  language="sql"
                  defaultValue=""
                  value={sqlQueryString}
                  onChange={handleEditorChange}
                  options={{
                    wordWrap: editorWordWrap === true ? "on" : "off",
                  }}
                ></Editor>

                <div className="float-end mt-1 mb-2">
                  <OverlayTrigger
                    trigger={["hover", "focus"]}
                    placement="bottom"
                    overlay={
                      <Popover id="popover-parse">
                        <Popover.Header as="h3">Parse Fields</Popover.Header>
                        <Popover.Body>
                          Returns all table fields from base query for use in
                          selects to set mapped fields
                          <hr />
                          <b>databases_explainSelectEntityQuery</b>
                          <br />
                          <code>
                            Explains an entity query base query, i.e. the bit
                            after "FROM" e.g. "&lt;table name&gt; WHERE ..."
                          </code>
                        </Popover.Body>
                      </Popover>
                    }
                  >
                    <Button
                      size="sm"
                      // style={{ width: 150 }}
                      disabled={loading || loadingParse}
                      variant={
                        editorDirty === true
                          ? "warning"
                          : parseSuccess === true
                          ? "success"
                          : "danger"
                      }
                      className="ms-1"
                      onClick={() =>
                        !loadingParse && sqlQueryString
                          ? parseSQLQuery(migrationId, sqlQueryString)
                          : null
                      }
                    >
                      <>
                        <Check2 width={20} height={20} /> Parse Fields
                      </>
                    </Button>
                  </OverlayTrigger>

                  <OverlayTrigger
                    trigger={["hover", "focus"]}
                    placement="bottom"
                    overlay={
                      <Popover id="popover-select">
                        <Popover.Header as="h3">Run Query</Popover.Header>
                        <Popover.Body>
                          Returns a preview of the Base Query before mapping
                          <hr />
                          <b>databases_executeSelectQuery</b>
                          <br />
                          <code>
                            Execute a select query against a migration's raw
                            data.
                          </code>
                        </Popover.Body>
                      </Popover>
                    }
                  >
                    <Button
                      size="sm"
                      style={{ width: 110 }}
                      disabled={
                        loading || editorDirty || !parseSuccess || loadingQuery
                      }
                      variant={
                        editorDirty === true
                          ? "warning"
                          : parseSuccess === true
                          ? "success"
                          : "danger"
                      }
                      className="ms-1"
                      onClick={() => {
                        if (!loadingQuery) {
                          if (!resultSet) {
                            LoadSelectedData();
                          } else {
                            setResultSet(undefined);
                          }
                        }
                      }}
                    >
                      {resultSet ? (
                        <>
                          <ChevronUp width={20} height={20} /> Hide
                        </>
                      ) : (
                        <>
                          <Play width={20} height={20} /> Run Query
                        </>
                      )}
                    </Button>
                  </OverlayTrigger>
                </div>
              </Col>
            </Row>
            <Row>
              <Col className="ms-1">
                {loadingQuery === true ? (
                  <div className="m-4">
                    <Spinner
                      animation="border"
                      role="status"
                      variant="secondary"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  </div>
                ) : queryErrorMessage && queryErrorMessage.length > 0 ? (
                  <Alert variant="danger" className="my-4">
                    <Alert.Heading>{queryErrorMessage}</Alert.Heading>
                    <p>{queryErrorException}</p>
                  </Alert>
                ) : (
                  <QueryGrid queryResults={resultSet} />
                )}
              </Col>
            </Row>
          </Container>
        </CollapsibleCard>
      </>
    );
  };
export default EntityMappingBaseQuery;
