// eslint-disable-next-line
import React, { useEffect, useState } from "react";

import { useHistory } from "react-router-dom";

import { RouteComponentProps, withRouter } from "react-router";

// import Image from "react-bootstrap/Image";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

import {
  Camera,
  Upload,
  ListCheck,
  FileBinary,
  Sliders,
} from "react-bootstrap-icons";

import MigrationProgress from "../components/MigrationProgress";
import EntityProgress from "../components/EntityProgress";
import EntityMappingList from "../components/EntityMappingList";
import EntityMappingFields from "../components/EntityMappingFields";
import EntityRawPreview from "../components/EntityRawPreview";
import EntitySnapshotViewer from "../components/EntitySnapshotViewer";

//import GroupBar from "../containers/GroupBar";

type MigrationDetailParams = {
  migrationId: string;
  entityTab: string;
  entityName: string;
};
type MigrationDetailProps = RouteComponentProps<MigrationDetailParams>;

const MigrationDetail: React.FC<MigrationDetailProps> = ({ match }) => {
  let history = useHistory();

  const [tabKey, setTabKey] = useState<string>("entities");
  const [cachedEntityName, setCachedEntityName] = useState<string>("");

  useEffect(() => {
    if (match.params.entityName && match.params.entityName.length > 0) {
      setCachedEntityName(match.params.entityName);

      switch (match.params.entityTab) {
        case "entities":
          setTabKey("fields");
          break;
        case "preview":
          setTabKey("preview");
          break;
        case "snapshot":
          setTabKey("snapshot");
          break;
      }
    } else {
      switch (match.params.entityTab) {
        case "progress":
          setTabKey("progress");
          break;
        case "entities":
          setTabKey("entities");
          break;
        default:
          setTabKey("progress");
          break;
      }
    }
  }, [match.params.entityTab, match.params.entityName]);

  return (
    <>
      <Container fluid>
        <Row>
          <Col>
            <Breadcrumb>
              <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
              <Breadcrumb.Item active>Migration Progress</Breadcrumb.Item>
            </Breadcrumb>

            <MigrationProgress migrationId={match.params.migrationId} />

            <Tabs
              activeKey={tabKey}
              onSelect={(k) => {
                let dest: string = k ? k : "";
                if (
                  (dest === "fields" ||
                    dest === "snapshot" ||
                    dest === "preview") &&
                  cachedEntityName !== ""
                ) {
                  history.push(
                    "/migration/" +
                      match.params.migrationId +
                      "/" +
                      (dest === "fields" ? "entities" : dest) +
                      "/" +
                      cachedEntityName
                  );
                } else {
                  history.push(
                    "/migration/" + match.params.migrationId + "/" + dest
                  );
                }
              }}
              mountOnEnter={true}
            >
              <Tab
                eventKey="progress"
                title={
                  <>
                    <Upload width="16" height="16" className="me-2" />
                    Ingress Progress
                  </>
                }
              >
                <EntityProgress migrationId={match.params.migrationId} />
              </Tab>
              <Tab
                eventKey="entities"
                title={
                  <>
                    <ListCheck width="16" height="16" className="me-2" />
                    Entity List
                  </>
                }
              >
                <EntityMappingList migrationId={match.params.migrationId} />
              </Tab>
              <Tab
                eventKey="fields"
                title={
                  <>
                    <Sliders width="16" height="16" className="me-2" />
                    Field Mapping
                  </>
                }
                disabled={cachedEntityName === ""}
              >
                <EntityMappingFields
                  migrationId={match.params.migrationId}
                  entityName={match.params.entityName}
                />
              </Tab>
              <Tab
                eventKey="preview"
                title={
                  <>
                    <FileBinary width="16" height="16" className="me-2" />
                    Raw Preview
                  </>
                }
                disabled={cachedEntityName === ""}
              >
                <EntityRawPreview
                  migrationId={match.params.migrationId}
                  entityName={match.params.entityName}
                />
              </Tab>
              <Tab
                eventKey="snapshot"
                title={
                  <>
                    <Camera width="16" height="16" className="me-2" />
                    Snapshot Preview
                  </>
                }
                disabled={cachedEntityName === ""}
              >
                <EntitySnapshotViewer
                  migrationId={match.params.migrationId}
                  entityName={match.params.entityName}
                />
              </Tab>
            </Tabs>

            <div style={{ height: 400 }}></div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default withRouter(MigrationDetail);
