import React, { useState } from "react";

import { useHistory } from "react-router-dom";

import { useQuery } from "@apollo/client";

import { DateTime } from "luxon";

import Alert from "react-bootstrap/Alert";

import {
  MigrationMutation,
  SET_SNAPSHOT_GENERATION,
  GET_MIGRATION_PROGRESS,
  MigrationQuery,
  DELETE_MIGRATION_BY_ID,
  ErrorExtensions,
} from "../API/migrationService";

import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Spinner from "react-bootstrap/Spinner";
import Image from "react-bootstrap/Image";
import Badge from "react-bootstrap/Badge";

import {
  Camera,
  CloudArrowUp,
  FileBinary,
  Images,
  Trash,
} from "react-bootstrap-icons";

import StageCountProgressBar from "./ProgressBars/StageCountProgressBar";
import CollapsibleCard from "../containers/CollapsibleCard";
import SoftwareIcon from "./SoftwareIcon";

import ConfirmDialog from "./Dialogs/ConfirmDialog";
import PushDialog from "./Dialogs/PushDialog";

import { apiClient } from "../index";

function RenderDateTime(value: string | undefined) {
  if (value) {
    return DateTime.fromISO(value + "Z").toLocaleString(
      DateTime.DATETIME_SHORT_WITH_SECONDS
    );
  } else {
    return null;
  }
}

type MigrationSummaryProps = {
  migrationId: string;
};

const MigrationProgress: React.FunctionComponent<MigrationSummaryProps> = ({
  migrationId,
}) => {

  const [snapshotDialogShow, setSnapshotDialogShow] = useState<boolean>(false);
  const [pushDialogShow, setPushDialogShow] = useState<boolean>(false);

  const [deleteMigrationDialogShow, setDeleteMigrationDialogShow] = useState<boolean>(false);
  const [deleteMigrationErrorMessage, setDeleteMigrationErrorMessage] = useState<string>("");
  const [deleteMigrationErrorException, setDeleteMigrationErrorException] = useState<string>("");
  
  let history = useHistory();

  const { data, error, loading, refetch} = useQuery<MigrationQuery>(
    GET_MIGRATION_PROGRESS,
    {
      variables: { migrationId },
      pollInterval: 10000,
      errorPolicy: "all",
    }
  );

  if (error) {
    const timeElapsed = Date.now();
    const today = new Date(timeElapsed);
    console.log(today.toISOString() + ":" + error.message);
  }

  const currentMigration = data?.migrations_findMigrationById;
  const migrationDeleted = (currentMigration?.status.ingress === "DELETED" && currentMigration?.status.media === "DELETED" && currentMigration?.status.snapshot === "DELETED");

  const mutationSetSnapshotGeneration = (
    migrationId: string,
    generateSnapshotOnStable: boolean
  ) => {
    apiClient
      .mutate<MigrationMutation>({
        mutation: SET_SNAPSHOT_GENERATION,
        variables: {
          migrationId: migrationId,
          generateSnapshotOnStable: generateSnapshotOnStable,
        },
        errorPolicy: "all",
      })
      .then((response) => {
        console.log(response);
        refetch();
      })
      .catch((err) => {console.error(err)});
  };

  const mutationDeleteMigrationById = (
    migrationId: string
  ) => {
    apiClient
      .mutate<MigrationMutation>({
        mutation: DELETE_MIGRATION_BY_ID,
        variables: {
          migrationId: migrationId
        },
        errorPolicy: "all",
      })
      .then((response) => {

        if (response.errors) {
          
          const errorMessage = response.errors[0].message;
          console.error(errorMessage);

          setDeleteMigrationErrorMessage(errorMessage);

          if (response.errors[0].extensions) {
            const ext: ErrorExtensions = response.errors[0]
              .extensions as ErrorExtensions;
            if (ext.data) {
              setDeleteMigrationErrorException(ext.data.exception.message);
            }
          }

        }else{
          history.replace("/",true);
        }

      })
      .catch((err) => {console.error(err)});
  };

  const handleDialogHide = () => {
    setSnapshotDialogShow(false);
  };

  const handleDialogOK = () => {
    setSnapshotDialogShow(false);
    mutationSetSnapshotGeneration(migrationId, true);
  };

  const handlePushDialogHide = () => {
    setPushDialogShow(false);
  };

  const handlePushDialogOK = () => {
    setPushDialogShow(false);
    //mutationSetSnapshotGeneration(migrationId, true);
  };

  const handleDeleteMigrationDialogHide = () => {
    setDeleteMigrationDialogShow(false);
  };

  const handleDeleteMigrationDialogOK = () => {
    setDeleteMigrationDialogShow(false);
    mutationDeleteMigrationById(migrationId);
  };

  return (
    <>

      {deleteMigrationErrorMessage && deleteMigrationErrorMessage.length > 0 ? (
              <Alert variant="danger" className="my-4">
                <Alert.Heading>{deleteMigrationErrorMessage}</Alert.Heading>
                <p>{deleteMigrationErrorException}</p>
              </Alert>
            ) : null} 


      {migrationDeleted ? (
              <Alert variant="danger" className="my-4">
                <Alert.Heading>Migration deleted</Alert.Heading>
                <p>This migration has been deleted, snapshot and push have been disabled</p>
              </Alert>
            ) : null} 

      <CollapsibleCard
        headerText={loading === true ? "Loading..." : "Migration Progress"}
        collapsedContent={
          loading === false ? (
            <>
              <SoftwareIcon
                softwareName={
                  currentMigration?.software?.name
                    ? currentMigration?.software?.name
                    : "Unknown Software"
                }
                showName={false}
              />
              {currentMigration?.companyName}
              {/* <ProgressBar
                className="ms-1"
                style={{ height: "30px", width: "100px" }}
                variant="success"
                now={
                  currentMigration?.entityStats.ingress
                    .topLevelCompletedPercentage
                }
                label={`${currentMigration?.entityStats.ingress.topLevelCompletedPercentage}%`}
              /> */}
            </>
          ) : (
            <></>
          )
        }
        options={
          <>
            {loading === true ? (
              <Spinner animation="border" role="status" variant="secondary">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            ) : (
              <>

                <Button
                  style={{ width: 110 }}
                  size="sm"
                  className="me-1"
                  onClick={() => setSnapshotDialogShow(true)}
                  disabled={currentMigration?.generateSnapshotOnStable || migrationDeleted}
                  variant={
                    currentMigration?.generateSnapshotOnStable
                      ? "success"
                      : "warning"
                  }
                >
                  <Camera width="20" height="20" className="me-2" />
                  Snapshot
                </Button>

                <Button
                  style={{ width: 110 }}
                  size="sm"
                  className="me-1"
                  onClick={() => setPushDialogShow(true)}
                  disabled={migrationDeleted}
                  variant={"success"}
                >
                  <CloudArrowUp width="20" height="20" className="me-2" />
                  Push
                </Button>

                <Button
                  style={{ width: 110 }}
                  size="sm"
                  className="me-1"
                  onClick={() => setDeleteMigrationDialogShow(true)}
                  variant={"danger"}
                >
                  <Trash width="20" height="20" className="me-2" />
                  Delete
                </Button>

              </>
            )}
          </>
        }
      >
        {loading === true ? (
          <Spinner animation="border" role="status" variant="secondary">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        ) : (
          <>
            <Container fluid>
              <Row>
                <Col sm="6" lg="4">
                  <Container fluid className="m-0 p-0">
                    <Row>
                      <Col>
                        <h4>
                          <Image
                            className="me-2"
                            src={`../../images/${currentMigration?.software.name
                              ?.toLowerCase()
                              .replace("_media", "")}logo.png`}
                            width="40"
                            height="40"
                          />
                          <b>{currentMigration?.companyName}</b>
                          {currentMigration?.software.name
                            ?.toLowerCase()
                            .endsWith("_media") ? (
                            <Badge className="ms-2">Media</Badge>
                          ) : null}
                        </h4>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm lg="4" className="text-truncate">
                        <b>Started: </b>
                      </Col>
                      <Col>{RenderDateTime(currentMigration?.started)}</Col>
                    </Row>
                    <Row>
                      <Col sm lg="4" className="text-truncate">
                        <b>Last Updated: </b>
                      </Col>
                      <Col>{RenderDateTime(currentMigration?.lastUpdated)}</Col>
                    </Row>
                  </Container>
                </Col>
                <Col sm="6" lg="8">
                  <Container fluid>
                    <Row className="mb-1">
                      <Col
                        sm="12"
                        lg="2"
                        className="mb-sm-1 mb-lg-0 text-truncate"
                      >
                        <FileBinary className="me-2" height="20" width="20" />
                        Data:
                      </Col>
                      <Col sm="12" lg="10">
                        <StageCountProgressBar
                          stageCount={currentMigration?.entityStats.ingress}
                        />
                      </Col>
                    </Row>
                    <Row className="mb-1">
                      <Col
                        sm="12"
                        lg="2"
                        className="mb-sm-1 mb-lg-0 text-truncate"
                      >
                        <Images className="me-2" height="20" width="20" />
                        Media:
                      </Col>
                      <Col sm="12" lg="10">
                        <StageCountProgressBar
                          stageCount={currentMigration?.entityStats.media}
                        />
                      </Col>
                    </Row>
                    <Row className="mb-1">
                      <Col
                        sm="12"
                        lg="2"
                        className="mb-sm-1 mb-lg-0 text-truncate"
                      >
                        <Camera className="me-2" height="20" width="20" />
                        Snapshot:
                      </Col>
                      <Col sm="12" lg="10">
                        <StageCountProgressBar
                          stageCount={currentMigration?.entityStats.snapshot}
                        />
                      </Col>
                    </Row>
                    <Row className="mb-1">
                      <Col
                        sm="12"
                        lg="2"
                        className="mb-sm-1 mb-lg-0 text-truncate"
                      >
                        <CloudArrowUp className="me-2" height="20" width="20" />
                        Push:
                      </Col>
                      <Col sm="12" lg="10">
                        <StageCountProgressBar
                          stageCount={currentMigration?.entityStats.push}
                        />
                      </Col>
                    </Row>
                  </Container>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col md xl="3">
                  <b>Status: </b>
                  {currentMigration?.status.ingress}
                </Col>
                <Col md xl="3"></Col>
              </Row>
            </Container>
          </>
        )}
      </CollapsibleCard>
      <ConfirmDialog
        title={`Queue Snapshot for ${currentMigration?.companyName}?`}
        message={
          "Set the migration to generate a snapshot on reaching stable. \n Take snapshot with existing stable data? / Take snapshot when data supply is stable?"
        }
        show={snapshotDialogShow}
        onHideHandler={handleDialogHide}
        onOKHandler={handleDialogOK}
        icon={<Camera width={30} height={30} className="me-2" />}
      />
      <PushDialog
        title={`Trigger Push for ${currentMigration?.companyName}?`}
        message={"Push data to Alto? \n Select Destination and options"}
        show={pushDialogShow}
        onHideHandler={handlePushDialogHide}
        onOKHandler={handlePushDialogOK}
      />
      <ConfirmDialog
        title={`Delete "${currentMigration?.companyName}" migration?`}
        message={
          `Are you sure? This will set its ingress, media and snapshot statuses to "deleted" and it will no longer be visible.`
        }
        show={deleteMigrationDialogShow}
        onHideHandler={handleDeleteMigrationDialogHide}
        onOKHandler={handleDeleteMigrationDialogOK}
        icon={<Trash width={30} height={30} className="me-2" />}
      />

    </>
  );
};

export default MigrationProgress;
