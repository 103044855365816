import React, { FC } from "react";
import { ICellRendererParams } from "ag-grid-community";
import { MigrationRow } from "../../API/migrationService";

import { Link } from "react-router-dom";

interface IMigrationCellRendererParams
  extends Omit<ICellRendererParams, "data"> {
  data: MigrationRow;
}

const AgentNameRenderer: FC<IMigrationCellRendererParams> = ({
  value,
  data,
}) => (
  <>
    <Link
      to={{
        pathname:
          "/migration/" +
          data.id +
          (!data.softwareName.toLowerCase().endsWith("_media") &&
          data.ingressCompletedPercentage === 100
            ? "/entities"
            : "/progress"),
      }}
    >
      {value}
    </Link>
  </>
);

export default AgentNameRenderer;
