export class NotAuthenticatedError extends Error {
  constructor() {
    super("No user is currently authenticated.");
  }
}

export class AuthService {
  private readonly _baseUrl: string;
  private readonly _localStorageKey: string = "migrations_access_token";

  constructor(baseUrl: string) {
    this._baseUrl = baseUrl;
  }

  public clearLogin(): void {
    localStorage.removeItem(this._localStorageKey);
  }

  public getBearerToken(): string {
    return this.bearerToken;
  }

  public async getProfileAsync(): Promise<IAuthProfileResponse> {
    if (!this.hasLogin()) {
      throw Error("Cannot get profile if you are not logged in.");
    }

    let response = await fetch(`${this._baseUrl}/auth/profile`, {
      method: "get",
      headers: new Headers({
        Authorization: `Bearer ${this.bearerToken}`,
      }),
    });

    if (response.status === 200) {
      return (await response.json()) as IAuthProfileResponse;
    } else if (response.status === 401) {
      throw new NotAuthenticatedError();
    }

    throw new Error("Failed!");
  }

  public hasLogin(): boolean {
    return this.bearerToken.length > 0;
  }

  public loginViaUrlToken(): boolean {
    let searchParams = new URLSearchParams(window.location.search);

    if (searchParams.has("jwt")) {
      var newToken = searchParams.get("jwt");

      if (newToken != null && newToken.length > 0) {
        this.bearerToken = newToken;

        return true;
      }
    }

    return false;
  }

  public redirectToLogin(): void {
    const authUrl = window.location.host.startsWith("localhost")
      ? "/auth/login/local"
      : "/auth/login";
    window.location.replace(`${this._baseUrl}${authUrl}`);
  }

  private get bearerToken(): string {
    return localStorage.getItem(this._localStorageKey) ?? "";
  }

  private set bearerToken(value: string) {
    localStorage.setItem(this._localStorageKey, value);
  }
}

export interface IAuthProfileResponse {
  name: string;
  roles: string[];
}
