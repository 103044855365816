import React, { FC } from "react";
import { ICellRendererParams } from "ag-grid-community";

import DashboardProgressBar from "../ProgressBars/DashboardProgressBar";

import { MigrationRow } from "../../API/migrationService";

interface IMigrationCellRendererParams
  extends Omit<ICellRendererParams, "data"> {
  data: MigrationRow;
}

function ProgressBarHeightSetter(data: MigrationRow) {
  const height: number = 36;
  var count: number = 0;

  if (data.ingressCompletedPercentage > 0) {
    count++;
  }
  if (data.mediaCompletedPercentage > 0) {
    count++;
  }
  if (data.pushCompletedPercentage > 0) {
    count++;
  }
  if (data.snapshotCompletedPercentage > 0) {
    count++;
  }

  return count > 0 ? height / count : 0;
}

const ProgressRenderer: FC<IMigrationCellRendererParams> = ({ data }) => (
  <>
    <DashboardProgressBar
      label="Data"
      status={data.ingressStatus}
      percentage={data.ingressCompletedPercentage}
      lastUpdated={data.lastUpdated}
      barHeight={ProgressBarHeightSetter(data)}
    />
    <DashboardProgressBar
      label="Media"
      status={data.mediaStatus}
      percentage={data.mediaCompletedPercentage}
      lastUpdated={data.lastUpdated}
      barHeight={ProgressBarHeightSetter(data)}
    />
    <DashboardProgressBar
      label="Push"
      status={data.pushStatus}
      percentage={data.pushCompletedPercentage}
      lastUpdated={data.lastUpdated}
      barHeight={ProgressBarHeightSetter(data)}
    />
    <DashboardProgressBar
      label="Snapshot"
      status={data.snapshotStatus}
      percentage={data.snapshotCompletedPercentage}
      lastUpdated={data.lastUpdated}
      barHeight={ProgressBarHeightSetter(data)}
    />
  </>
);

export default ProgressRenderer;
