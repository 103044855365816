import React from "react";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import MigrationGrid from "../components/Datagrids/MigrationGrid";

import AgentCard from "../containers/AgentCard";

// import StatisticsLayout from "../containers/StatisticsLayout";

const Dashboard: React.FunctionComponent = () => {
  const [migrationId, setMigrationId] = React.useState("");

  function handleMigrationChange(newValue: string) {
    setMigrationId(newValue);
  }

  return (
    <React.Fragment>
      <Container fluid>
        <Row className="my-3">
          <Col xl="9">
            <MigrationGrid onMigrationChange={handleMigrationChange} />
          </Col>
          <Col xl="3">
            <AgentCard migrationId={migrationId} />
          </Col>
        </Row>
        <Row>
          <Col>
            <small className="text-black-50 ms-2">
              This application in is <b>{process.env.NODE_ENV}</b> mode.
            </small>
          </Col>
        </Row>
      </Container>
      {/* <StatisticsLayout /> */}
    </React.Fragment>
  );
};

export default Dashboard;
