import React, { useState, useEffect } from "react";

import { apiClient } from "../../index";

import {
  SnapshotMapping,
  MappingUnion,
  SqlMapping,
} from "../../API/remappingService";

import { ColDef, GridApi, TextFilter } from "ag-grid-community";

import { AgGridReact } from "ag-grid-react";

import {
  GET_SAMPLE_RAW_ENTITY,
  DatabasesQuery,
  EntityFieldRow,
  RecordField,
} from "../../API/databaseService";

import CollapsibleCard from "../../containers/CollapsibleCard";

import EntityNameRenderer from "../CellRenderers/EntityNameRenderer";

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";

const fieldName = (name: keyof EntityFieldRow) => name;

const columnDefs: ColDef[] = [
  {
    headerName: "Field Name",
    field: fieldName("fieldName"),
    cellRenderer: "entityNameRenderer",
    width: 250,
    pinned: "left",
    filter: TextFilter,
  },
  // {
  //   headerName: "type",
  //   field: fieldName("dataType"),
  //   width: 100,
  //   pinned: "left",
  //   filter: TextFilter,
  // },
  {
    headerName: "Record 1",
    field: fieldName("recordA"),
    filter: TextFilter,
  },
  {
    headerName: "Record 2",
    field: fieldName("recordB"),
    filter: TextFilter,
  },
  {
    headerName: "Record 3",
    field: fieldName("recordC"),
    filter: TextFilter,
  },
  {
    headerName: "Record 4",
    field: fieldName("recordD"),
    filter: TextFilter,
  },
  {
    headerName: "Record 5",
    field: fieldName("recordE"),
    filter: TextFilter,
  },
  {
    headerName: "Record 6",
    field: fieldName("recordF"),
    filter: TextFilter,
  },
  {
    headerName: "Record 7",
    field: fieldName("recordG"),
    filter: TextFilter,
  },
  {
    headerName: "Record 8",
    field: fieldName("recordH"),
    filter: TextFilter,
  },
  {
    headerName: "Record 9",
    field: fieldName("recordI"),
    filter: TextFilter,
  },
  {
    headerName: "Record 10",
    field: fieldName("recordJ"),
    filter: TextFilter,
  },
  {
    headerName: "Record 11",
    field: fieldName("recordK"),
    filter: TextFilter,
  },
  {
    headerName: "Record 12",
    field: fieldName("recordL"),
    filter: TextFilter,
  },
  {
    headerName: "Record 13",
    field: fieldName("recordM"),
    filter: TextFilter,
  },
  {
    headerName: "Record 14",
    field: fieldName("recordN"),
    filter: TextFilter,
  },
  {
    headerName: "Record 15",
    field: fieldName("recordO"),
    filter: TextFilter,
  },
  {
    headerName: "Record 16",
    field: fieldName("recordP"),
    filter: TextFilter,
  },
  {
    headerName: "Record 17",
    field: fieldName("recordQ"),
    filter: TextFilter,
  },
  {
    headerName: "Record 18",
    field: fieldName("recordR"),
    filter: TextFilter,
  },
  {
    headerName: "Record 19",
    field: fieldName("recordS"),
    filter: TextFilter,
  },
  {
    headerName: "Record 20",
    field: fieldName("recordT"),
    filter: TextFilter,
  },
];

export type EntityFieldGridProps = {
  migrationId: string;
  entityName: string;
  mappingList: SnapshotMapping[] | undefined;
};

const EntityFieldGrid: React.FC<EntityFieldGridProps> = ({
  migrationId,
  entityName,
  mappingList,
}) => {
  // eslint-disable-next-line
  const [gridApi, setGridApi] = useState<GridApi | undefined>(undefined);

  const [rowData, setRowData] =
    useState<EntityFieldRow[] | undefined>(undefined);

  // eslint-disable-next-line
  function isSqlMapping(union: MappingUnion): union is SqlMapping {
    return (union as any).query !== undefined;
  }

  useEffect(() => {
    LoadData(migrationId, entityName, mappingList);
  }, [migrationId, entityName, mappingList]);

  const onGridReady = (params: {
    api: React.SetStateAction<GridApi | undefined>;
  }) => {
    setGridApi(params.api);
  };

  const LoadData = (
    migId: string,
    entNme: string,
    mappingList: SnapshotMapping[] | undefined
  ) => {
    var listSQLRawUpdateFields: string[] = [];
    mappingList?.forEach((m) => {
      // if (isSqlMapping(m.mapping)) {
      var sqm = m.mapping as SqlMapping;
      sqm.updateFields.forEach((uf) => {
        if (!listSQLRawUpdateFields.includes(uf.rawUpdateField)) {
          listSQLRawUpdateFields.push(uf.rawUpdateField);
        }
      });
      // }
    });

    apiClient
      .query<DatabasesQuery>({
        query: GET_SAMPLE_RAW_ENTITY,
        variables: {
          migrationId: migId,
          entityName: entNme,
          limit: 20,
        },
      })
      .then((response) => {
        if (response.error) {
          console.error(response.error.message);
        }

        var fieldRows: EntityFieldRow[] = [];

        response.data.databases_sampleRawEntity.records.forEach((r, i) => {
          var data: RecordField[] = r.fields.slice();

          data.forEach((item, index) => {
            if (item.name === "id") {
              data.splice(index, 1);
              data.unshift(item);
            }
          });

          data.forEach((g, j) => {
            if (i === 0) {
              var efr: EntityFieldRow = {
                fieldName: g.name,
                hasSQLMapping: listSQLRawUpdateFields.includes(g.name),
              };
              fieldRows.push(efr);
            }

            const val = g.value.trim();

            switch (i) {
              case 0:
                fieldRows[j].recordA = val;
                break;
              case 1:
                fieldRows[j].recordB = val;
                break;
              case 2:
                fieldRows[j].recordC = val;
                break;
              case 3:
                fieldRows[j].recordD = val;
                break;
              case 4:
                fieldRows[j].recordE = val;
                break;
              case 5:
                fieldRows[j].recordF = val;
                break;
              case 6:
                fieldRows[j].recordG = val;
                break;
              case 7:
                fieldRows[j].recordH = val;
                break;
              case 8:
                fieldRows[j].recordI = val;
                break;
              case 9:
                fieldRows[j].recordJ = val;
                break;
              case 10:
                fieldRows[j].recordK = val;
                break;
              case 11:
                fieldRows[j].recordL = val;
                break;
              case 12:
                fieldRows[j].recordM = val;
                break;
              case 13:
                fieldRows[j].recordN = val;
                break;
              case 14:
                fieldRows[j].recordO = val;
                break;
              case 15:
                fieldRows[j].recordP = val;
                break;
              case 16:
                fieldRows[j].recordQ = val;
                break;
              case 17:
                fieldRows[j].recordR = val;
                break;
              case 18:
                fieldRows[j].recordS = val;
                break;
              case 19:
                fieldRows[j].recordT = val;
                break;
              default:
                break;
            }
          });
        });

        setRowData(fieldRows);
      })
      .catch((err) => console.error(err));
  };

  const gridOptions = {
    getRowNodeId: (data: { fieldName: any }) => {
      return data.fieldName;
    },
  };

  return (
    <>
      <CollapsibleCard headerText="Entity Fields" showPoll={false}>
        <div className="ag-theme-alpine" style={{ height: 600 }}>
          <AgGridReact
            gridOptions={gridOptions}
            defaultColDef={{
              sortable: false,
              resizable: true,
              width: 200,
              autoHeight: false,
              wrapText: false,
              editable: false,
              flex: 0,
            }}
            columnDefs={columnDefs}
            frameworkComponents={{ entityNameRenderer: EntityNameRenderer }}
            rowData={rowData}
            rowSelection="single"
            animateRows={true}
            rowDragManaged={true}
            suppressRowClickSelection={false}
            onGridReady={onGridReady}
          />
        </div>
      </CollapsibleCard>
    </>
  );
};

export default EntityFieldGrid;
