import React from "react";

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { CloudArrowUp } from "react-bootstrap-icons";

interface PushDialogProps {
  title?: string;
  message?: string;
  show: boolean;
  onHideHandler?: any;
  onOKHandler?: any;
}

const PushDialog: React.FC<PushDialogProps> = ({
  title,
  message,
  show,
  onHideHandler,
  onOKHandler,
}) => {
  return (
    <>
      <Modal
        show={show}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        size="lg"
        backdrop="static"
        keyboard={false}
        onHide={onHideHandler}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <CloudArrowUp width="32" height="32" className="me-3" />
            {title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {message?.split("\n").map((str, i) => (
            <p key={i}>{str}</p>
          ))}
          <Form>
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Destination</Form.Label>
                  <Form.Check
                    type="radio"
                    id="radAlto1"
                    label="Alto 1"
                    name="group1"
                  />
                  <Form.Check
                    type="radio"
                    id="radAlto2"
                    label="Alto 2"
                    name="group1"
                  />
                  <Form.Check
                    type="radio"
                    id="radAltoTrial1"
                    label="Alto Trial 1"
                    name="group1"
                  />
                  <Form.Check
                    type="radio"
                    id="radAltoTrial2"
                    label="Alto Trial 2"
                    name="group1"
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Options</Form.Label>
                  <Form.Check
                    type="checkbox"
                    id="chkIncludeData"
                    label="Include Data"
                  />
                  <Form.Check
                    type="checkbox"
                    id="chkIncludeMedia"
                    label="Include Media"
                  />
                  <Form.Check
                    type="checkbox"
                    id="chkIncludeConfig"
                    label="Include Config"
                  />
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <span className="float-end">
            <Button
              style={{ width: 120 }}
              onClick={onOKHandler}
              className="me-1"
            >
              OK
            </Button>
            <Button style={{ width: 120 }} onClick={onHideHandler}>
              Cancel
            </Button>
          </span>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default PushDialog;
