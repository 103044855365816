import React, { useState } from "react";

import { useQuery } from "@apollo/client";

import {
  LIST_ENTITY_TEMPLATE_GROUPS_LITE,
  EntityTemplatesQuery,
} from "../API/entityTemplateService";

import ConfirmDialog from "./Dialogs/ConfirmDialog";

import Spinner from "react-bootstrap/Spinner";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

import { Save2 } from "react-bootstrap-icons";

export type EntitySelectProps = {
  onEntityTemplateGroupSelected?: any;
};

const EntityTemplateGroupSelect: React.FunctionComponent<EntitySelectProps> = ({
  onEntityTemplateGroupSelected,
}) => {
  const [entityTemplateGroupId, setEntityTemplateGroupId] = useState<number>(0);
  const [entityTemplateGroupName, setEntityTemplateGroupName] =
    useState<string>("");

  const [applyDialogShow, setApplyDialogShow] = useState<boolean>(false);

  const handleApplyDialogHide = () => {
    setApplyDialogShow(false);
  };

  const handleApplyDialogOK = () => {
    setApplyDialogShow(false);
    if (onEntityTemplateGroupSelected) {
      onEntityTemplateGroupSelected(entityTemplateGroupId);
    }
  };

  const { data, error, loading } = useQuery<EntityTemplatesQuery>(
    LIST_ENTITY_TEMPLATE_GROUPS_LITE,
    {
      errorPolicy: "all",
    }
  );

  if (error) {
    console.error(error);
  }

  const templates = data?.entityTemplates_listEntityTemplateGroups;

  return (
    <>
      {loading === true ? (
        <Spinner animation="border" role="status" variant="secondary">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      ) : (
        <Form>
          <Form.Group>
            <Form.Label>Entity Template Group Name</Form.Label>
            <Form.Select
              size="lg"
              onChange={(e) => {
                let id = parseInt(e.currentTarget.value);
                setEntityTemplateGroupId(id);

                let filtered = templates?.filter((t) => t.id === id);

                if (filtered && filtered.length > 0) {
                  setEntityTemplateGroupName(filtered[0].name);
                }
              }}
            >
              <option value={0}>Select an Entity Template Group...</option>
              {templates?.map((t) => (
                <option key={t.id} value={t.id}>
                  {`${t.name}`}
                </option>
              ))}
            </Form.Select>
          </Form.Group>

          <Form.Group>
            <Button
              disabled={entityTemplateGroupId === 0}
              className="mt-1"
              onClick={() => {
                if (entityTemplateGroupId > 0) {
                  setApplyDialogShow(true);
                }
              }}
            >
              Apply Entity Template Group
            </Button>
          </Form.Group>
        </Form>
      )}
      <ConfirmDialog
        title={`Apply Entity Template Group?`}
        message={`Apply '${entityTemplateGroupName}'? \n [entityTemplateGroupId: ${entityTemplateGroupId}]`}
        show={applyDialogShow}
        onHideHandler={handleApplyDialogHide}
        onOKHandler={handleApplyDialogOK}
        icon={<Save2 width={30} height={30} className="me-2" />}
      />
    </>
  );
};

export default EntityTemplateGroupSelect;
