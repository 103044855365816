import React from "react";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import SnapshotTemplateById from "./SnapshotTemplateById";

const SnapshotTemplateHome: React.FC = () => {
  return (
    <Container fluid>
      <Row>
        <Col>
          <h2>Snapshot Template</h2>
        </Col>
      </Row>
      <Row className="my-5">
        <Col>
          <SnapshotTemplateById snapshotTemplateId={1} />
        </Col>
      </Row>
    </Container>
  );
};

export default SnapshotTemplateHome;
