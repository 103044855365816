import React from "react";

import { RouteComponentProps, withRouter } from "react-router";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import MappingWrapper from "../containers/MappingWrapper";
import MigrationProgress from "../components/MigrationProgress";
import Breadcrumb from "react-bootstrap/Breadcrumb";

type RefineMigrationParams = {
  migrationId: string;
  entityName: string;
};
type RefineMigrationProps = RouteComponentProps<RefineMigrationParams>;

const RefineMigration: React.FC<RefineMigrationProps> = ({ match }) => {
  return (
    <>
      <Container fluid>
        <Row>
          <Col>
            <Breadcrumb>
              <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
              <Breadcrumb.Item active>Mapping</Breadcrumb.Item>
            </Breadcrumb>
            <MigrationProgress migrationId={match.params.migrationId} />

            <MappingWrapper
              migrationId={match.params.migrationId}
              entityName={match.params.entityName}
            />

            <div style={{ height: 300 }}></div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default withRouter(RefineMigration);
