import React from "react";

import { useQuery } from "@apollo/client";

import {
  LIST_MAPPING_SCRIPTS,
  RemappingQuery,
} from "../../../API/remappingService";

const MappingScriptsList: React.FC = () => {
  const { data, error, loading } = useQuery<RemappingQuery>(
    LIST_MAPPING_SCRIPTS,
    {
      pollInterval: 1000,
      errorPolicy: "all",
    }
  );
  if (error) {
    console.log(error.message);
  }

  return loading ? (
    <p>loading</p>
  ) : (
    <div>
      <h4>mappingScripts</h4>
      {data?.remapping_listMappingScripts.map((m) => (
        <div key={m.id}>
          <pre>
            <b>id: </b>
            {m.id}
          </pre>
          <p>
            {m.name} | {m.description} | {m.content}
          </p>
        </div>
      ))}
    </div>
  );
};

export default MappingScriptsList;
