// eslint-disable-next-line
import React, { useState } from "react";

import Editor from "@monaco-editor/react";

import CollapsibleCard from "../containers/CollapsibleCard";

export type EntityTemplateQueryProps = {
  migrationId: string;
  entityName: string;
};

const EntityTemplateQuery: React.FunctionComponent<EntityTemplateQueryProps> = ({
  migrationId,
  entityName,
}) => {
  if (entityName === "property_entity") {
    return (
      <CollapsibleCard headerText="Entity Template Query">
        <Editor
          className="border border-secondary py-3"
          height="400px"
          language="sql"
          defaultValue="select 'property_entity' [$type]
              , concat('PROP',p.propertyid) [Id]
              , p.propertyid [propertyid]
              , concat('OFFC',p.officeid) [branch_external_key]
              , case when p.userid>0 THEN concat('USER',p.userid) end [owner_security_user_external_key]
              , case when p.userid>0 THEN concat('USER',p.userid) end  [creator_security_user_external_key]
              , p.dateentered [import_create_date]
              , p.dateamended [import_last_edit_date]
, p.recordtype [property_record_type]
              , case when p.recordtype = 117 then 1 else 0 end [archived]
              , p.floors [floors]
              , p.floornumber [floor_number]
              , p.beds [bedrooms]
              , p.receptions [receptions]
              , p.bathrooms [bathrooms]
              , p.land [land]
              , p.propertytype [property_sub_type_code]
              , p.age [property_age_code]
              , p.[description] [summary]
              , p.yearbuilt [year_built]
              , p.floorarea [floor_area]
              , case when p.recordtype = 301 then 1 else 0 end [new_build]
              , p.allowmatch [allow_match]
              , pi.sys_propertylat [latitude]
              , pi.sys_propertylong [longitude]
              , case when p.matcharea>0 then concat('AREA',p.matcharea) end [match_area_external_key]
              , case when p.customarea>0 then concat('CMAT',p.customarea) end [custom_location_external_key]
              , p.po [parking_offroad]
, p.pg [parking_garage]
              , p.pp [parking_permit]
              , p.pu [parking_underground]
              , p.category [property_category_code]
              , pi.agencytype [property_agency_type_code]
              , case when pi.jointagent>0 then concat('CONT',pi.jointagent) end [joint_agent_contact_external_key]
              , pi.saleboardreq [board_required]
              , pi.saleboard [board_type]
              , '' [reference_code]
              , pi.ratingauthority [rating_authority]
              , pi.taxband [tax_band]
              , pi.viewing [public_viewing_notes]
              , pi.directions [directions]
              , pi.vacant [vacant]
              , pi.lowprofile [low_profile]
              , pi.pviewing [private_viewing_notes]
              , pi.weblink1 [web_address_url]
              , pi.eerc [e_erating_current]
              , pi.eerp [e_erating_potential]
              , pi.eirc [e_irating_current]
              , pi.eirp [e_irating_potential]
              , pi.uf1 [user_defined1]
              , pi.uf2 [user_defined2]
              , pi.sys_streetyaw [street_view_povheading]
              , pi.sys_streetpitch [street_view_povpitch]
              , pi.sys_streetzoom [street_view_povzoom]
              , pi.sys_streetlat [street_view_povlatitude]
              , pi.sys_streetlong [street_view_povlongitude]
              , pi.weblocality [display_address]
              , a1.adtext [advert_details]
              , a4.adtext [lead_in]
              , a5.adtext [lead_out]
              , p.housename [address_sub_dwelling]
              , p.housenumber [address_name_no]
              , p.street [address_street]
              , p.locality [address_locality]
              , p.town [address_town]
              , p.county [address_county]
              , p.postcode [address_postcode]
              , p.country [address_country_code]
              , l.startdatetext [lease_start_date_text]
              , l.enddatetext [lease_end_date_text]
              , l.term [lease_term]
              , l.remaining [lease_remaining_term]
              , l.notes [lease_notes]
              , l.groundrent [lease_ground_rent]
              , l.fixedforterm [lease_fixed_for_term]
              , l.reviews [lease_reviews]
              , l.servicecharge [lease_service_charge]
              , l.details [lease_details]
              , l.scstart [lease_service_charge_start]
              , l.scend [lease_service_charge_end]
              , l.rent [lease_rent]
              , l.insurancecontrib [lease_insurance_contribution]
              , l.tenureverified [lease_tenure_verified]
              , l.solicitorinformed [lease_solicitor_informed]
              , l.enddatefull [lease_end_date]
              , case when l.managingagent>0 then concat('CONT',l.managingagent) end [lease_managing_agent_contact_external_key]
              , 'GBP' [currencycode]
              , '£' [currencysymbol]
              , pi.dateon [onmarketdate]
              , pi.dateoff [offmarketdate]
              , left(cast(p.propertyid as nvarchar) + ' ' + coalesce(pi.customcode, ''), 50) [referencecode]
              , newid() [tempguid]
              , case when (p.pg = 1 or p.po = 1 or p.pp = 1 or p.pu = 1) then 1 else 0 end [hasparking]
              , p.dateentered [importcreatedate]
              , p.dateamended [importlasteditdate]
              , p.price [saleprice]
              , p.rentalprice [rentprice]
              , p.sys_status [statusid]
              , '{property_bullet_entity:propertyid}' [propertybullets]
              , '{property_feature_code_entity:propertyid}' [propertyfeatures]
              , '{property_key_set_entity:propertyid}' [propertykeyset]
              , '{property_sale_entity:propertyid}' [propertysale]
              , '{property_rental_entity:propertyid}' [propertyrental]
              , '{property_room_entity:propertyid}' [propertyrooms]
              , '{property_contact_entity:propertyid}' [propertycontacts]
    from dbo.en_property p
    left join dbo.en_advert a1 on p.propertyid = a1.propertyid and a1.adnumber = 1
    left join dbo.en_advert a4 on p.propertyid = a4.propertyid and a4.adnumber = 4
    left join dbo.en_advert a5 on p.propertyid = a5.propertyid and a5.adnumber = 5
    left join en_propertyinfo_oo pi on p.propertyid = pi.propertyid
    left join en_lease_oo l on p.propertyid = l.propertyid
    where p.propertyid = substring(@primarykey,5,99)"
        />
      </CollapsibleCard>
    );
  } else if (entityName === "property_feature_code_entity") {
    return (
      <CollapsibleCard headerText="Entity Template Query">
        <Editor
          className="border border-secondary py-3"
          height="400px"
          language="sql"
          defaultValue="select 'property_feature_code_entity' [$type]
            , concat('PFEA',p.propertyid,'-',p.feature1) [Id]
            , concat('PROP',p.propertyid) [property_external_key]
            , p.feature1 [feature_code]
                     from en_property p
         where p.feature1>0 and propertyid = @primarykey
         union
         select 'property_feature_code_entity' [$type]
            , concat('PFEA',p.propertyid,'-',p.feature2) [Id]
            , concat('PROP',p.propertyid) [property_external_key]
            , p.feature2 [feature_code]
         from en_property p
         where p.feature2>0 and propertyid = @primarykey
         union
         select 'property_feature_code_entity' [$type]
            , concat('PFEA',p.propertyid,'-',p.feature3) [Id]
            , concat('PROP',p.propertyid) [property_external_key]
            , p.feature3 [feature_code]
         from en_property p
         where p.feature3>0 and propertyid = @primarykey
         union
         select 'property_feature_code_entity' [$type]
            , concat('PFEA',p.propertyid,'-',p.feature4) [Id]
            , concat('PROP',p.propertyid) [property_external_key]
            , p.feature4 [feature_code]
         from en_property p
         where p.feature4>0 and propertyid = @primarykey"
        />
      </CollapsibleCard>
    );
  } else {
    return null;
  }
};

export default EntityTemplateQuery;
