import React from "react";

import ProgressBar from "react-bootstrap/ProgressBar";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";

import { MigrationStageCount } from "../../API/migrationService";

export type StageCountProgressBarProps = {
  stageCount: MigrationStageCount | undefined;
};

const StageCountProgressBar: React.FunctionComponent<
  StageCountProgressBarProps
> = ({ stageCount }) => {
  function completePercent() {
    if (
      stageCount &&
      stageCount.topLevelCompleted &&
      stageCount.topLevelTotal
    ) {
      return (stageCount.topLevelCompleted / stageCount.topLevelTotal) * 100;
    } else {
      return 0;
    }
  }

  function failedPercent() {
    if (stageCount && stageCount.topLevelFailed && stageCount.topLevelTotal) {
      return (stageCount.topLevelFailed / stageCount.topLevelTotal) * 100;
    } else {
      return 0;
    }
  }

  function pendingPercent() {
    if (stageCount && stageCount.topLevelPending && stageCount.topLevelTotal) {
      return (stageCount.topLevelPending / stageCount.topLevelTotal) * 100;
    } else {
      return 0;
    }
  }

  function totalPercent() {
    if (stageCount && stageCount.topLevelTotal) {
      return (
        ((stageCount.topLevelCompleted + stageCount.topLevelFailed) /
          stageCount.topLevelTotal) *
        100
      );
    } else {
      return 0;
    }
  }

  const complete: number = completePercent();
  const failed: number = failedPercent();
  const pending: number = pendingPercent();
  const total: number = totalPercent();

  function completeNow() {
    if (complete > 50 && failed > 0 && failed < 3) {
      return complete - 3;
    } else {
      return complete;
    }
  }

  function failedNow() {
    if (failed > 0 && failed < 3) {
      return failed + 3;
    } else {
      return failed;
    }
  }

  function pendingNow() {
    if (pending > 50 && failed > 0 && failed < 3) {
      return pending - 3;
    } else if (pending > 0) {
      return pending;
    } else {
      return 0;
    }
  }

  return (
    <>
      <OverlayTrigger
        trigger={["hover", "focus"]}
        placement="bottom"
        overlay={
          (stageCount && stageCount?.topLevelTotal > 0) ||
          complete !== 0 ||
          failed !== 0 ||
          pending !== 0 ? (
            <Popover id="popover-progress">
              <Popover.Header as="h3">Progress Figures</Popover.Header>
              <Popover.Body>
                <code>
                  "Total": {stageCount?.topLevelTotal}
                  <br />
                  "Completed": {stageCount?.topLevelCompleted}
                  <br /> "CompletedPercentage": {complete.toFixed(2)}
                  <br />
                  "Failed": {stageCount?.topLevelFailed}
                  <br /> "FailedPercentage": {failed.toFixed(2)}
                  <br />
                  "Pending": {stageCount?.topLevelPending}
                  <br /> "PendingPercentage": {pending.toFixed(2)}
                  <br />
                </code>
              </Popover.Body>
            </Popover>
          ) : (
            <></>
          )
        }
      >
        <ProgressBar style={{ height: "30px" }}>
          <ProgressBar
            animated={complete < 100 && total !== 100}
            variant="success"
            now={completeNow()}
            label={
              `${
                complete < 5
                  ? Math.round(complete)
                  : complete.toFixed(Number.isInteger(complete) ? 0 : 2)
              }%` +
              `${
                complete > 20
                  ? " (" +
                    stageCount?.topLevelCompleted +
                    "/" +
                    stageCount?.topLevelTotal +
                    ")"
                  : ""
              }`
            }
          />
          <ProgressBar
            variant="danger"
            now={failedNow()}
            label={stageCount?.topLevelFailed}
          />
          <ProgressBar
            variant="info"
            now={pendingNow()}
            label={
              `${pending > 40 ? "Pending " : ""}${
                pending < 5
                  ? Math.round(pending)
                  : pending.toFixed(Number.isInteger(pending) ? 0 : 2)
              }%` +
              `${
                complete < 20
                  ? " (" +
                    stageCount?.topLevelCompleted +
                    "/" +
                    stageCount?.topLevelTotal +
                    ")"
                  : ""
              }`
            }
          />
        </ProgressBar>
      </OverlayTrigger>
    </>
  );
};

export default StageCountProgressBar;
